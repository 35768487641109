import React, { useState, useEffect, useContext } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import { Loading, titleCaseText } from '../../components/utility/Utility';
const jwt = require('jsonwebtoken');

export default function SignUp() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { platform } = useParams();
  const { authed, signup, getTkn } = useContext(AuthContext);
  const [csrf, setCsrf] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authed) navigate('/app/dashboard');
    else getTkn('/app/auth/signup', setCsrf);
  }, [])

  // **how do we implement a captcha on this form?
  // handle if email is in use
  return (
    <div className="signup">

      <h1 className='signup__header'>Sign Up</h1>
      <hr className="signup__horizontal-rule" />
      <Formik 
      initialValues={{email: '', password: '', storeName: '', storeUrl: '', csrf}}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Invalid email address').required('Field is required.'),
        password: Yup.string()
          .required('Field is required.'),
        storeName: Yup.string()
          .required('Please enter the name of your company.'),
        storeUrl: Yup.string()
          .matches(
            /^(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
            'Enter a valid URL'
          )
          .required('Please enter website URL.'),
      })}
      onSubmit={async (values) => {
        // setSubmitting(true);
        if (values.csrf) {
          setSubmitting(true);
          try {
            signup(values).then(res => {
              navigate('/app/dashboard');
            }).catch(err => {
              if (err.response.status === 401) {
                setFormErrors('New token needed, refresh the page.');
              } else {
                setFormErrors(err.response.data);
              }
            }).finally(() => {
              setSubmitting(false)
            })
          } catch (err) {
            console.log(err);
          }
        } else setSubmitting(false);
        // Set a contingency if account exists
      }}
      enableReinitialize={true}
    >
      <Form id="sign-up-form" className="auth-form signup__form">
        <Field name="csrf" type="hidden" />
        <label className="signup__form-label" htmlFor="email">Email</label>
        <Field name="email" type="email" placeholder="customer@gmail.com" />
        <ErrorMessage component="div" className="form-error" name="email" />

        <label className="signup__form-label" htmlFor="password">Password</label>
        <Field name="password" type="password" />
        <ErrorMessage component="div" className="form-error" name="password" />

        <label className="signup__form-label" htmlFor="storeName">Company name</label>
        <Field name="storeName" />
        <ErrorMessage component="div" className="form-error" name="storeName" /> 

        <label className="signup__form-label" htmlFor="storeUrl">Company website</label>
        <Field name="storeUrl" />
        <ErrorMessage component="div" className="form-error" name="storeUrl" /> 
        
        {formErrors && <div className="form-errors">{formErrors}</div>}
        {submitting && <Loading />}
        {!submitting && 
          <button type="submit" className="gradient">Create account</button>
        }
      </Form>
    </Formik>
    </div>
  )
}