import { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import '../../assets/css/components/program.css';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PromotionsInfo, PromotionsAmplifiers, PromotionsHeader, PromotionsPreview, SubmissionError, NewPromotionsInfo } from '../../components/PromotionsComponents';
import { Formik, Form, useFormikContext } from 'formik';
import { responseHandler, Loading } from '../../components/utility/Utility';

export default function UpdatePromotion() {
  const {logout} = useContext(AuthContext);
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [promotion, setPromotion] = useState(false);
  const [conflictingPromotions, setConflictingPromotions] = useState(false);
  const [dynamicName, setDynamicName] = useState('');
  const [previewValues, setPreviewValues] = useState(false);
  const [activeAmplifier, setActiveAmplifier] = useState('announcementBar');
  const [announcementStatus, setAnnouncementStatus] = useState(false);
  const [productStatus, setProductStatus] = useState(false);
  const [collectionStatus, setCollectionStatus] = useState(false);
  const [cartStatus, setCartStatus] = useState(false);
  const [currentValues, setCurrentValues] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const prevValues = useRef(false);
  const start = useRef(false);
  const end = useRef(false);

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/promotions',
    {
      params: {
        jwt: localStorage.getItem('_id'),
        id: id
      }
    }).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else if (res.data[0].status !== 'draft') {
        // navigate(`/app/promotions/${id}/reporting`);
      } else {
        let result = res.data[0];
        setConflictingPromotions(res.data.slice(1));
        start.current = new Date(result.start_time);
        end.current = new Date(result.end_time);
        setPromotion(result);
        // Validate 
        if (end.current < new Date()) {
          setSubmissionError({section: 'schedule', message: 'End date must be in the future.', level: 'critical'});
        } else if (start.current < new Date()) {
          setSubmissionError({section: 'schedule', message: 'Start dates in the past will enable the promotion immediately.', level: 'warning'});
        }
        if (location.pathname.includes('amplifiers') || location.pathname.includes('review')) {
          setDynamicName(result.name);
        }
        // setAnnouncementStatus(promotion.amplifiers.announcement.status);
        // setProductStatus(promotion.amplifiers.product.status);
        // setCollectionStatus(promotion.amplifiers.collection.status);
        prevValues.current = {
          announcement_status: result.amplifiers.announcement.status,
          announcement_sticky: result.amplifiers.announcement.sticky,
          announcement_text: result.amplifiers.announcement.text,
          announcement_font_size: result.amplifiers.announcement.font_size,
          announcement_font_color: result.amplifiers.announcement.background_color,
          announcement_url: result.amplifiers.announcement.url,
          collection_status: result.amplifiers.collection.status,
          collection_text: result.amplifiers.collection.text,
          collection_font_size: result.amplifiers.collection.font_size,
          collection_font_color: result.amplifiers.collection.font_color,
          collection_points_color: result.amplifiers.collection.points_color,
          product_status: result.amplifiers.product.status,
          product_text: result.amplifiers.product.text,
          product_font_size: result.amplifiers.product.font_size,
          product_font_color: result.amplifiers.product.font_color,
          product_points_color: result.amplifiers.product.points_color,
          cartpoints_point_status: result.amplifiers.cart.points_display.point_status,
          cartpoints_text: result.amplifiers.cart.points_display.text,
          cartpoints_font_size: result.amplifiers.cart.points_display.font_size,
          cartpoints_font_color: result.amplifiers.cart.points_display.font_color,
          cartpoints_points_color: result.amplifiers.cart.points_display.points_color,
          cartpoints_background_color: result.amplifiers.cart.points_display.background_color,
          cartpoints_border_color: result.amplifiers.cart.points_display.border_color,
          cartredemptions_redemption_status: result.amplifiers.cart.redemptions.redemption_status,
          // cartredemptions_login_message: result.amplifiers.cart.redemptions.login_message,
          cartredemptions_redemption_font_size: result.amplifiers.cart.redemptions.redemption_font_size,
          cartredemptions_show_coupons: result.amplifiers.cart.redemptions.show_coupons,
          cartredemptions_redemption_font_color: result.amplifiers.cart.redemptions.redemption_font_color,
          cartredemptions_redemption_background_color: result.amplifiers.cart.redemptions.redemption_background_color
        }
        setCurrentValues(prevValues.current);
      }
    }).catch(err => {
      console.log(err);
    });

    // On any change of any form input, update the preview values
    // Set interval for every 15 seconds to check current values against initial and then
    // last saved values to see if there's an update and then send new values to save
    // setInterval(() => {
    //   let prev = Object.keys(prevValues.current).map(k => {
    //     if (prevValues.current[k] === currentValues[k]) {
    //       return true
    //     }
    //   });
    //   if (prev.length !== Object.keys(prevValues.current).length) {
    //     console.log('Mismatch');
    //   } else {
    //     console.log("passed");
    //   }
    // }, 15000);
  }, [location.pathname])

  const HandleChange = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      setCurrentValues(values);
      setPreviewValues(values);
      setAnnouncementStatus(values.announcement_status);
      setProductStatus(values.product_status);
      setCollectionStatus(values.collection_status);
      setCartStatus(values.cartpoints_point_status || values.cartredemptions_redemption_status ? true : false);
    }, [values]);
    return null;
  }

  const disableReviewInputs = (dsbl) => {
    const btns = [
      ...document.querySelectorAll('.update-promotion-review__schedule-button'), 
      ...document.querySelectorAll('.update-promotion-review__submit-button')
    ];
    btns.forEach(btn => btn.disabled = dsbl);
  }

  const submitPromotion = () => {
    // validations
    // end time is not in the past
    if (submissionError.level !== 'critical') {
      disableReviewInputs(true);
      axios.put(
        'https://api.amplifyloyalty.com/app/stores/promotions',
        {
          _id: promotion._id,
          status: "scheduled" // determine on back end
        },
        {
          params: {
            jwt: localStorage.getItem('_id'),
            section: 'review'
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          navigate('/app/promotions');
        }
      }).catch (err => {
        // responseHandler(err)
        disableReviewInputs(false);
        console.log(err);
      })
      
    } else {
      console.log(submissionError);
    }
  }

  return (
    <div className="main">
      <PromotionsHeader dynamicName={dynamicName} promotion={promotion} />
      {(promotion && !location.pathname.includes('amplifiers') && !location.pathname.includes('review')) && 
        <NewPromotionsInfo promotion={promotion} setPromotion={setPromotion} setDynamicName={setDynamicName} conflictingPromotions={conflictingPromotions} />
      }
      {location.pathname.includes('amplifiers') && 
        <section className="update-promotion-amplifiers-settings centralize">
          {promotion && 
            <Formik
              initialValues={{
                announcement_status: promotion.amplifiers.announcement.status,
                announcement_sticky: promotion.amplifiers.announcement.sticky,
                announcement_text: promotion.amplifiers.announcement.text,
                announcement_font_size: promotion.amplifiers.announcement.font_size,
                announcement_font_color: promotion.amplifiers.announcement.font_color,
                announcement_background_color: promotion.amplifiers.announcement.background_color,
                announcement_url: promotion.amplifiers.announcement.url,
                collection_status: promotion.amplifiers.collection.status,
                collection_text: promotion.amplifiers.collection.text,
                collection_font_size: promotion.amplifiers.collection.font_size,
                collection_font_color: promotion.amplifiers.collection.font_color,
                collection_points_color: promotion.amplifiers.collection.points_color,
                product_status: promotion.amplifiers.product.status,
                product_text: promotion.amplifiers.product.text,
                product_font_size: promotion.amplifiers.product.font_size,
                product_font_color: promotion.amplifiers.product.font_color,
                product_points_color: promotion.amplifiers.product.points_color,
                cartpoints_point_status: promotion.amplifiers.cart.points_display.point_status,
                cartpoints_text: promotion.amplifiers.cart.points_display.text,
                cartpoints_font_size: promotion.amplifiers.cart.points_display.font_size,
                cartpoints_font_color: promotion.amplifiers.cart.points_display.font_color,
                cartpoints_points_color: promotion.amplifiers.cart.points_display.points_color,
                cartpoints_background_color: promotion.amplifiers.cart.points_display.background_color,
                cartpoints_border_color: promotion.amplifiers.cart.points_display.border_color,
                cartredemptions_redemption_status: promotion.amplifiers.cart.redemptions.redemption_status,
                // cartredemptions_login_message: promotion.amplifiers.cart.redemptions.login_message,
                cartredemptions_redemption_font_size: promotion.amplifiers.cart.redemptions.redemption_font_size,
                cartredemptions_show_coupons: promotion.amplifiers.cart.redemptions.show_coupons,
                cartredemptions_redemption_font_color: promotion.amplifiers.cart.redemptions.redemption_font_color,
                cartredemptions_redemption_background_color: promotion.amplifiers.cart.redemptions.redemption_background_color
              }}
              onSubmit={async (values, {resetForm}) => {
                values._id = id;
                let inputs = [...document.querySelectorAll('input'), ...document.querySelectorAll('.input'), document.querySelector('.amplifier-preview__continue-button')];
                inputs.forEach(input => input.setAttribute("disabled", true));
                setSubmitting(true);
                axios.put(
                  'https://api.amplifyloyalty.com/app/stores/promotions',
                  values,
                  {
                    params: {
                      jwt: localStorage.getItem('_id'),
                      section: 'amplifiers'
                    }
                  }
                ).then(res => {
                  if (res.data.name === 'BadRequestError') {
                    logout();
                  } else {
                    navigate(`/app/promotions/${id}/review`);
                    // show last time updated
                  }
                }).catch (err => {
                  // responseHandler(err)
                  setSubmitting(false);
                  inputs.forEach(input => input.setAttribute("disabled", false));
                  console.log(err);
                })
              }}
            >
              <Form className='update-promotion-amplifiers__container'>
                <PromotionsAmplifiers 
                  promotion={promotion}
                  activeAmplifier={activeAmplifier}
                  setActiveAmplifier={setActiveAmplifier}
                  announcementStatus={announcementStatus}
                  productStatus={productStatus}
                  collectionStatus={collectionStatus}
                  cartStatus={cartStatus}
                  submitting={submitting}
                />
                <PromotionsPreview 
                  previewValues={previewValues}
                  activeAmplifier={activeAmplifier} 
                />
                <HandleChange />
              </Form>
            </Formik>
          }
        </section>
      }
      {location.pathname.includes('review') && 
        <section className="update-promotion-review centralize block-section">
          {promotion && 
            <div className="update-promotion-review__container">
              <div className="update-promotion-review__title">Review to Schedule</div>
              <div className="update-promotion-review__items">
                <div className="update-promotion-review__item">
                  <div className="update-promotion-review__item-icon-wrapper">
                    <div className="update-promotion-review__item-icon">i</div>
                  </div>
                  <div className="update-promotion-review__item-content">
                    <div className="update-promotion-review__item-text">
                      <div className="update-promotion-review__item-title">Schedule</div>
                      {/* Show error if end time isn't in the future */}
                      {/* Show warning that start times in the past will start right now */}
                      <div className="update-promotion-review__item-description">
                        Runs from {days[start.current.getDay()]}, {`${start.current.getMonth()+1}/${start.current.getDate()}/${start.current.getFullYear().toString().slice(2,)} `} 
                        at {`${start.current.getHours() > 12 ? start.current.getHours() % 12 : start.current.getHours() === 0 ? 12 : start.current.getHours()}:${start.current.getMinutes() < 10 ? `0${start.current.getMinutes()}` : start.current.getMinutes()}${start.current.getHours() > 11 ? 'pm' : 'am'} `}
                        until {days[end.current.getDay()]}, {`${end.current.getMonth()+1}/${end.current.getDate()}/${end.current.getFullYear().toString().slice(2,)} `} 
                        at {`${end.current.getHours() > 12 ? end.current.getHours() % 12 : end.current.getHours() === 0 ? 12 : end.current.getHours()}:${end.current.getMinutes() < 10 ? `0${end.current.getMinutes()}` : end.current.getMinutes()}${end.current.getHours() > 11 ? 'pm' : 'am'}`}.
                      </div>
                      {submissionError && submissionError.section === 'schedule' &&
                        <SubmissionError error={submissionError} />
                      }
                    </div>
                    <a className="update-promotion-review__submit-button btn" href={`/app/promotions/${id}`}>Edit</a>
                  </div>
                </div>
                <div className="update-promotion-review__item">
                  <div className="update-promotion-review__item-icon-wrapper">
                    <div className="update-promotion-review__item-icon">i</div>
                  </div>
                  <div className="update-promotion-review__item-content">
                    <div className="update-promotion-review__item-text">
                      <div className="update-promotion-review__item-title">Eligibility</div>
                      <div className="update-promotion-review__item-description">
                        Multiplies points achieved by {promotion.multiplier}x from purchasing 
                        {promotion.restriction_type === 'all_orders' && ` all products`}
                        {promotion.restriction_type === 'collections' && ` ${promotion.ids.length} collections`}
                        {promotion.restriction_type === 'products' && ` ${promotion.ids.length} products`}
                        .
                      </div>
                    </div>
                    <a className="update-promotion-review__submit-button btn" href={`/app/promotions/${id}`}>Edit</a>
                  </div>
                </div>
                <div className="update-promotion-review__item">
                  <div className="update-promotion-review__item-icon-wrapper">
                    <div className="update-promotion-review__item-icon">i</div>
                  </div>
                  <div className="update-promotion-review__item-content">
                    <div className="update-promotion-review__item-text">
                      <div className="update-promotion-review__item-title">Amplifiers</div>
                      <div className="update-promotion-review__item-description">
                        <div className="update-promotion-review__item-amplifier">
                          <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-bullhorn update-promotion-review__amplifier-icon`}></i> Announcement Bar</div>
                          <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.announcement.status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.announcement.status ? 'ON' : 'OFF'}</div>
                        </div>
                        {/* <div className="update-promotion-review__item-amplifier">
                          <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-bars update-promotion-review__amplifier-icon`}></i> Collections</div>
                          <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.collection.status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.collection.status ? 'ON' : 'OFF'}</div>
                        </div> */}
                        <div className="update-promotion-review__item-amplifier">
                          <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-shirt update-promotion-review__amplifier-icon`}></i> Products</div>
                          <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.product.status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.product.status ? 'ON' : 'OFF'}</div>
                        </div>
                        <div className="update-promotion-review__item-amplifier">
                          <div className="update-promotion-review__item-amplifier-title"><i className={`fa-solid fa-cart-shopping update-promotion-review__amplifier-icon`}></i> Cart</div>
                          <div className={`update-promotion-review__item-amplifier-status${promotion.amplifiers.cart.points_display.point_status || promotion.amplifiers.cart.redemptions.redemption_status ? ' update-promotion-review__item-amplifier-status--active' : ''}`}>{promotion.amplifiers.cart.points_display.point_status || promotion.amplifiers.cart.redemptions.redemption_status ? 'ON' : 'OFF'}</div>
                        </div>
                      </div>
                    </div>
                    <a className="update-promotion-review__submit-button btn" href={`/app/promotions/${id}/amplifiers`}>Edit</a>
                  </div>
                </div>
              </div>
              <div className="update-promotion-review__schedule-button-wrapper">
                <SubmissionError error={{message: 'Once the Promotion is Active it cannot be edited again.'}} />
                <button className="update-promotion-review__schedule-button gradient" onClick={() => submitPromotion()}>Schedule</button>
              </div>
            </div>
          }
        </section>
      }
    </div>
  )
}