import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import '../../assets/css/components/program.css'
import { GiveawaysCouponForm } from '../../components/GiveawaysComponents';
import { Scorecard, LineGraph } from '../../components/utility/Charts';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { titleCaseText } from '../../components/utility/Utility';

export default function Giveaways() {
  const {logout} = useContext(AuthContext);
  const [giveaway, setGiveaway] = useState(false);
  const [details, setDetails] = useState('segment');
  const [lineGraph, setLineGraph] = useState([]);
  const [viewCoupon, setViewCoupon] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();

  const convertDate = (date) => {
    let d = new Date(date);
    let hours = d.getHours();
    let daytime = hours > 11 ? 'PM' : 'AM';
    if (hours === 0) hours = 12;
    if (hours > 12) hours-=12;
    let minutes = d.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().slice(2)} at ${hours}:${minutes} ${daytime}`
  }

  const filterType = (type) => {
    switch (type) {
      case "spend":
        return 'spent';
      case "orders":
        return 'ordered';
      case "account":
        return 'created account'
      default:
        return ""
    }
  }

  const valueType = (type) => {
    switch (type) {
      case "$gt":
        return 'more than';
      case "$lt":
        return "less than";
      case "=":
        return 'exactly'
      default:
        return '';
    }
  }

  const daysType = (type) => {
    switch (type) {
      case "$gt":
        return 'in the last';
      case "$lt":
        return 'at least';
      case "=":
        return 'exactly';
      case "any":
        return 'of all time';
      default:
        return '';
    }
  }

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatDate = (date) => {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    // console.log(new Date(date - tzoffset));
    return (new Date(date)).toISOString();
  }
  const fillDates = async (data, start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let dateMap = []
    for await (let item of data) {
      let formatDate = new Date(item.Date);
      dateMap.push(formatDate.getTime());
    };
    const newData = [...data];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      if (!dateMap.includes(d.getTime())) {
        newData.push({ 
          Date: d,
          Orders: 0,
          Sales: 0,
          Action: null
        });
      } 
    }
    newData.sort((a, b) => new Date(a.Date) - new Date(b.Date))
    return newData;
  }
  const formatLineGraph = async (events, start, end) => {
    const data = await fillDates(events, start, end)
    let dates = [];
    let prevI = -1;
    let lineData = [];
    for await (let v of data) {
      if (dates.includes(v.Date)) {
        lineData[prevI].Orders += v.Orders;
        lineData[prevI].Sales += v.Sales;
      } else {
        lineData.push({
          Date: v.Date,
          Orders: v.Orders,
          Sales: v.Sales
        });
        dates.push(v.Date);
        prevI++;
      }
    };
    setLineGraph(lineData);
  }

  const duplicatePromotion = async () => {
    const button = document.querySelector('.giveaway-reporting-header__button');
    button.disabled = true;
    axios.post(
      'https://api.amplifyloyalty.com/app/stores/giveaways/duplicate',
      {
        _id: giveaway._id,
        name: `${giveaway.name} - Copy`
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else if (res.data._id) {
        console.log(res);
        setTimeout(() => {
          navigate(`/app/giveaways/${res.data._id}`);
        }, 1000);
      }
    }).catch(err => {
      console.log(err);
      button.disabled = false;
      // responseHandler(err);
    })
  }

  const pauseAndEdit = () => {
    // disable buttons
    const buttons = document.querySelectorAll('button');
    buttons.forEach(btn => btn.disabled = true);
    axios.post('https://api.amplifyloyalty.com/app/stores/giveaways/pause',
      {
        _id: giveaway._id
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      } 
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        navigate(`/app/giveaways/${giveaway._id}`)
      }
    }).catch(err => {
      console.log(err);
      buttons.forEach(btn => btn.disabled = false);
    })
  }
  
  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/giveaways',
    {
      params: {
        jwt: localStorage.getItem('_id'),
        id
      }
    }).then(async res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res.data);
        setGiveaway(res.data);
        if (res.data.events.length) {
          formatLineGraph(res.data.events, res.data.execution_time, res.data.events.slice(-1)[0].Date);
        } else {
          let emptyData = [];
          let newDate = new Date(res.data.execution_time);
          for (let i = 0; i < 7; i++) {
            emptyData.push({
              Date: new Date(newDate),
              Action: "Order Placed",
              Sales: 0,
              Orders: 0
            });
            newDate.setDate(newDate.getDate() + 1)
          }
          formatLineGraph(emptyData, res.data.execution_time, emptyData.slice(-1)[0].Date);
        }
      }
    }).catch(err => {
      console.log(err);
    })
  }, [])

  return (
    <div className="main">
      {viewCoupon && 
        <GiveawaysCouponForm
          coupon={viewCoupon}
          setCreatingCoupon={setViewCoupon}
          disabled={true}
        />
      }
      <section className="giveaway-reporting-header block-section centralize">
        {/* name,schedule */}
        <div className="giveaway-reporting-header__info">
          {giveaway && 
            <>
              <div className="giveaway-reporting-header__name">{giveaway.name}</div>
              <div className="giveaway-reporting-header__schedule">{titleCaseText(giveaway.status)}: {convertDate(giveaway.execution_time)}</div>
            </>
          }
        </div>
        <div className="giveaway-reporting-header__buttons">
          {giveaway.status === 'scheduled' && <button className="giveaway-reporting-header__button btn btn--gradient-hover" onClick={pauseAndEdit}>Pause & Edit</button>}
          <button className="giveaway-reporting-header__button btn gradient" onClick={duplicatePromotion}>Duplicate</button>
        </div>
        {/* Pause/Edit buttons */}
      </section>
      <section className="giveaway-reporting-data block-section centralize">
        {giveaway && 
          <>
            <Scorecard data={Intl.NumberFormat('en-US').format(giveaway.recipients || 0)} className="giveaway-reporting-data__scorecard" title="Recipients" />
              {giveaway.rewards.reward_type === 'points' && 
                <Scorecard data={Intl.NumberFormat('en-US').format(giveaway.total_points || 0)} className="giveaway-reporting-data__scorecard" title="Points Issued" />
              }
            <Scorecard data={USDollar.format(giveaway.sales || 0)} className="giveaway-reporting-data__scorecard" title="Revenue" />
            <Scorecard data={Intl.NumberFormat('en-US').format(giveaway.orders || 0)} className="giveaway-reporting-data__scorecard" title="Orders" />
            <Scorecard data={`${giveaway.orders > 0 ? (giveaway.orders / giveaway.recipients * 100).toFixed(2) : 0}%`} className="giveaway-reporting-data__scorecard" title="Order Rate" />
            <Scorecard data={USDollar.format(giveaway.sales > 0 ? giveaway.sales / giveaway.orders : 0)} className="giveaway-reporting-data__scorecard" title="AOV" />
            <Scorecard data="0" className="giveaway-reporting-data__scorecard" title="Redemptions" />
            <Scorecard data="0" className="giveaway-reporting-data__scorecard" title="$ Discounted" />
          </>
        }
      </section>
      <section className="giveaway-reporting-chart block-section centralize">
        <div className="giveaway-reporting-chart__header">
          <div className="giveaway-reporting-chart__name">Sales over Time</div>
        </div>
        <LineGraph 
          width="100%"
          height={250}
          data={lineGraph}
          className="giveaway-reporting-chart__chart"
          keys={['Orders', 'Sales']}
          tickFormatter={(value) => {
            const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            let newValue = new Date(value);
            // console.log(tzoffset)
            const newDate = (new Date(new Date(value) + tzoffset))
            return `${newDate.getMonth()+1}/${newDate.getDate()}`
        }}
        />
      </section>
      <section className="giveaway-reporting-info block-section centralize">
        <div className="giveaway-reporting-info__options">
          <div className={`giveaway-reporting-info__option${details === 'segment' ? ' giveaway-reporting-info__option--active' : ''}`} onClick={() => setDetails('segment')}>Segment</div>
          <div className={`giveaway-reporting-info__option${details === 'giveaway' ? ' giveaway-reporting-info__option--active' : ''}`} onClick={() => setDetails('giveaway')}>Rewards</div>
        </div>
        <div className="giveaway-reporting-info__details">
          {details === "segment" && 
            <div className="giveaway-reporting-info__segment">
              {giveaway && giveaway.filters.length > 0 &&
                <div className="update-giveaway__filters">
                  <div className="update-giveaway__filter-options">
                    {giveaway.filters.map((filterSet, i) => {
                      return (
                        <div key={i} className="update-giveaway__and-filter-container">
                          <div className="giveaway-reporting-info__filter-container">
                            {filterSet.map((filter, cI) => {
                              return (
                                <div key={cI} className="update-giveaway__filter">
                                  <div className="update-giveaway__filter-row">
                                    <div className="update-giveaway__filter-type">
                                      <span className="update-giveaway__filter-intro">Customer has</span>
                                      <div className="giveaway-reporting-info__filter-option">{filterType(filter.filter_type)}</div>
                                    </div>
                                  </div>
                                  {giveaway.filters[i][cI].filter_type === 'spend' && 
                                    <div className="update-giveaway__dynamic-filter-options">
                                      <div className="giveaway-reporting-info__filter-option">{valueType(filter.value_operator)}</div>
                                      <div className="giveaway-reporting-info__filter-option">${filter.value}</div>
                                      <div className="giveaway-reporting-info__filter-option">{daysType(filter.days_operator)}</div>
                                      {giveaway.filters[i][cI].days_operator !== 'any' &&
                                        <div className="giveaway-reporting-info__conditional-fields">
                                          <div className="giveaway-reporting-info__filter-option">{filter.days}</div>
                                          days{(giveaway.filters[i][cI].days_operator && giveaway.filters[i][cI].days_operator !== '$gt') ? ' ago' : ''}
                                        </div>
                                      }
                                    </div>
                                  }
                                  {giveaway.filters[i][cI].filter_type === 'orders' && 
                                    <div className="update-giveaway__dynamic-filter-options">
                                      <div className="giveaway-reporting-info__filter-option">{valueType(filter.value_operator)}</div>
                                      {giveaway.filters[i][cI].value_operator !== 'any' &&
                                      <div className="update-giveaway__conditional-fields">
                                        <div className="giveaway-reporting-info__filter-option">{filter.value}</div>
                                        <span className="update-giveaway__floating-text">times</span>
                                        <div className="giveaway-reporting-info__filter-option">{daysType(filter.days_operator)}</div>
                                        {giveaway.filters[i][cI].days_operator !== 'any' &&
                                          <div className="giveaway-reporting-info__conditional-fields">
                                            <div className="giveaway-reporting-info__filter-option">{filter.days}</div>
                                            days{(giveaway.filters[i][cI].days_operator !== '$gt') ? ' ago' : ''}
                                          </div>
                                        }
                                      </div>
                                      }
                                    </div>
                                  }
                                  {giveaway.filters[i][cI].filter_type === 'account' && 
                                    <div className="update-giveaway__dynamic-filter-options update-giveaway__filter-row update-giveaway__filter-row--regular">
                                      <div className="giveaway-reporting-info__filter-option">{filter.value === 1 ? 'is true' : 'is false'}</div>
                                      {/* {filters[i][cI].value === 'true' &&
                                        <div className="update-giveaway__filter-row update-giveaway__filter-row--regular">
                                          <select name="days_operator" defaultValue=">" className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'days_operator', cI, i)}>
                                            
                                            <option value=">">at least</option>
                                            <option value="<">in the last</option>
                                            <option value="=">exactly</option>
                                          </select>
                                          <input type="number" name="days" className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'days', cI, i)}/>
                                        days ago
                                        </div>
                                      } */}
                                    </div>
                                  }
                                  {filterSet.length > cI+1 &&
                                    <div className="update-giveaway__or-divider">
                                      <div className="update-giveaway__or-divider-line"></div>
                                      <div className="update-giveaway__or-badge"><span className="update-giveaway__or-badge-text">OR</span></div>
                                      <div className="update-giveaway__or-divider-line"></div>
                                    </div>
                                  }
                                </div>
                              )
                            })}
                          </div>
                          {giveaway.filters.length > i+1 &&
                            <div className="update-giveaway__and-badge"><span className="update-giveaway__and-badge-text">AND</span></div>
                          }
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              {giveaway && giveaway.filters.length === 0 && 
                <div className="giveaway-reporting-info__filter">Segment applies to all customers.</div>
              }
            </div>
          }
          {details === "giveaway" && 
            <div className="giveaway-reporting-info__giveaway">
              <div className="update-giveaway-points__header-row update-giveaway-points__row">
                <div className="giveaway-reporting-info__type">
                  <span className="update-giveaway__floating-text">Select giveaway winners and reward them with</span>
                  <div className="giveaway-reporting-info__filter-option">{giveaway.rewards.reward_type}</div>
                </div>
              </div>
              {giveaway.rewards.breakdown.map((reward, i) => { 
                return (
                  <div key={i} className="update-giveaway-points__points-row">
                    <div className="giveaway-reporting-info__options-wrapper">
                      <span className="update-giveaway__floating-text">Give</span>
                      {giveaway.rewards.reward_type === 'points' && 
                        <div className="giveaway-reporting-info__filter-option">{reward.value}</div>
                      }
                      {giveaway.rewards.reward_type === 'coupon' && 
                        <div 
                          className="giveaway-reporting-info__filter-option giveaway-reporting-info__filter-option--coupon"
                          onClick={() => setViewCoupon(reward.coupon)}
                        >
                          {reward.coupon.name}
                        </div>
                      }
                      <span className="update-giveaway__floating-text">{giveaway.rewards.reward_type === 'points' ? 'points' : 'coupon'} to</span>
                      <div className="giveaway-reporting-info__filter-option">{reward.segment}</div>
                      <span className="update-giveaway__floating-text">recipients.</span>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
      </section>
    </div>
  )
}