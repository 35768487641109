import { Buffer } from 'buffer';
import process from 'process';

import React from 'react';
import ReactDOM from 'react-dom/client';  // Import from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/JosefinSans-Bold.ttf';
import './assets/fonts/JosefinSans-Regular.ttf';

// Set global variables
window.Buffer = Buffer;
window.process = process;

// Use createRoot instead of render
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <Router>
    <App />
  </Router>
);

reportWebVitals();
