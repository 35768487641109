import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import loading from '../assets/images/loading-buffering.gif';
import '../assets/css/components/program.css';
import ProgramNavigation from '../components/ProgramNavigation';


export default function Tracking() {
  return (
    <div className="main">
      <ProgramNavigation page="Earnings" />
      <section>
        Tracking
      </section>
    </div>
  )
}