import { Hero, Features, FeatureHighlight, Callout, Integrations, IntegrationIcon} from "../../components/WebsiteComponents";
// import '../../assets/css/web.css';

export default function Home() {
  return (
    <>
      <Hero 
        className="home-hero"
        header="AMPLIFY YOUR MARKETING"
        body="Stop seeing your loyalty program as an add-on and start seeing it as something you can build your brand around."
        img="https://amplify-loyalty-website.s3.amazonaws.com/1-Top-MacBook+(1)+(1).png"
      />
      <Features className="features" header="FEATURES">
          <FeatureHighlight
            className="features" 
            icon="https://amplify-loyalty-website.s3.amazonaws.com/2-Set+(1).png" 
            header="EARN"
            body="Set rules for customers to earn points for your store."
            img="https://amplify-loyalty-website.s3.amazonaws.com/2-Earn-Cards+(1).png"
            cta="Learn more"
            link="/features/earn"
          />
          <FeatureHighlight
            className="features" 
            icon="https://amplify-loyalty-website.s3.amazonaws.com/2-Set-+(1).png" 
            header="REDEEM"
            body="Create coupons for customers to redeem in their account."
            img="https://amplify-loyalty-website.s3.amazonaws.com/2-Reddem-Chips+(1).png"
            cta="Learn more"
            link="/features/redeem"
            reverse={true}
          />
          <FeatureHighlight
            className="features" 
            icon="https://amplify-loyalty-website.s3.amazonaws.com/2-Set+(1).png" 
            header="VIP PROGRAM"
            body="Create up to 5 tiers based on customer spend ot issue rewards points on future purchases and create bonus rewards like % or % coupons with special conditions."
            img="https://amplify-loyalty-website.s3.amazonaws.com/2-Vip-tiers+(1).png"
            cta="Learn more"
            link="/features/vip"
          />
          <FeatureHighlight
            className="features" 
            icon="https://amplify-loyalty-website.s3.amazonaws.com/2-Set-+(1).png" 
            header="GIVEAWAYS"
            body="Define a group of customers that you want to issue points to based on past interactions with your store."
            img="https://amplify-loyalty-website.s3.amazonaws.com/2-Give-Aways+(1).png"
            cta="Learn more"
            link="/features/redeem"
            reverse={true}
          />
      </Features>
      <Callout 
        className="callout"
        img="https://amplify-loyalty-website.s3.amazonaws.com/3-Macbook-statistics-background+(1).png"
        header="MEASURE ACT OPTIMIZE"
        body="Get a wholistic view of your program with an integrated analytics dashboard."
      />
      <Integrations 
        className="app-integrations" 
        icon="https://amplify-loyalty-website.s3.amazonaws.com/4-set-gradient-a+(1).png"
        background="https://amplify-loyalty-website.s3.amazonaws.com/4-background+(1).png"
      >
        <IntegrationIcon 
          className="app-integrations"
          img="https://amplify-loyalty-website.s3.amazonaws.com/Shopify-Logo.png"
          name="Shopify"
        />
        <IntegrationIcon 
          className="app-integrations"
          img="https://amplify-loyalty-website.s3.amazonaws.com/klaviyo-logo.png"
          name="Klaviyo"
        />
        <IntegrationIcon 
          className="app-integrations"
          img="https://amplify-loyalty-website.s3.amazonaws.com/judge-me-logo.png"
          name="JudgeMe"
        />
      </Integrations>
    </>
  )
}