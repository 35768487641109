import { RedeemCustomization, RedeemHero, RedemptionCTA, RedemptionExchange } from "../../components/WebsiteComponents";
// import '../../assets/css/web.css';

export default function Redeem() {
  return (
    <>
      <RedeemHero 
        header="REDEEM"
        body="Create coupons for customers to redeem in their accounts."
        main_img="https://amplify-loyalty-website.s3.amazonaws.com/1-I-Mac+(2)+(1).png"
        sub_img="https://amplify-loyalty-website.s3.amazonaws.com/1-Top-tools+(1).png"
      />
      <RedeemCustomization 
        header="CUSTOMIZE"
        body="Make a wide variety of coupons that are customized to make the most sense for your store."
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Redeem-Chips+(1).png"
        sub_img="https://amplify-loyalty-website.s3.amazonaws.com/2-Hand-Click+(1).png"
        background="https://amplify-loyalty-website.s3.amazonaws.com/4-background+(1).png"
      />
      <RedemptionExchange 
        header="EXCHANGE"
        body="Easily and automatically exchange points for rewards. Customers can redeem their points right on your store with no additional steps."
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Pathway+(1).png"
      />
      <RedemptionCTA 
        header="BUILD YOUR CUSTOM LOYALTY PROGRAM"
        body="Sign up for a free account and easily set up your loyalty program to start increasing conversions and returning customer rate."
        lImg="https://amplify-loyalty-website.s3.amazonaws.com/3-iphone+(1).png"
        rImg="https://amplify-loyalty-website.s3.amazonaws.com/3-chips+(1).png"
      />
    </>
  )
}