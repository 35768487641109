import { Loading } from "../components/utility/Utility"
import axios from 'axios';
import {useEffect} from 'react';
import { useSearchParams } from "react-router-dom";

export default function OAuth() {
  useEffect(() => {
    
  }, [])
  return (
    <div className="main">
      <Loading />
    </div>
  )
}