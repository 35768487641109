import { useEffect, useState, useRef, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import PointsTable from '../components/PointsTable.jsx';
import {
  Loading,
  TablePagination,
  Caret
} from '../components/utility/Utility';
import '../assets/css/components/program.css';

export default function Customers() {
  const {logout} = useContext(AuthContext);
  const [customers, setCustomers] = useState(false);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState({points: -1});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(false);
  const lastQuery = useRef('');

  const paginate = () => {
    setCustomers(false);
    axios.get('https://api.amplifyloyalty.com/app/customers',
      {
        params: {
          jwt: localStorage.getItem('_id'),
          page,
          sort: JSON.stringify(sort),
          query
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        let info = {...res.data}
        delete info.docs;
        setPagination(info)
        setCustomers(res.data.docs);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const search = (value) => {
    setTimeout(() => {
        let currentValue = document.querySelector('.customers-table__search').value;
        if (currentValue === value && value !== lastQuery.current) {
          setCustomers(false);
          lastQuery.current = value;
          setQuery(value);
        }
    }, 1500)
  }

  useEffect(() => {
    paginate();
  }, [query, sort, page])

  return (
    <div className="customers">
      <section className="customers-table__container">
        <div className="customers-table">
          <input type="text" className="customers-table__search" placeholder="Search customers" onChange={(e) => search(e.target.value)}/>
          <div className="customers-table__header">
            <div className="customers-table__heading customers-table__sort-heading" onClick={() => setSort({email: sort.email ? sort.email * -1 : -1})}>
              <div className="customers-table__heading-text">Customer</div>
              {sort.email && 
                <Caret className="customers-table__heading-caret" direction={sort.email*-1}/>
              }
            </div>
            <div className="customers-table__metrics">
              <div className="customers-table__heading">
                {/* <div className="customers-table__heading-text">VIP Rank</div>
                <Caret className="customers-table__heading-caret" action={() => setSort({vip_rank: sort.vip_rank ? sort.vip_rank * -1 : -1})}/> */}
                {/* <div onClick={() => console.log(sort)}>Sort</div> */}
              </div>
              <div className="customers-table__heading">
                <div className="customers-table__sort-container" onClick={() => setSort({points: sort.points * -1})}>
                  <div className="customers-table__heading-text">Points</div>
                  {sort.points && 
                    <Caret className="customers-table__heading-caret" direction={sort.points}/>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="customers-table__body">
            {!customers && <Loading className="customers-table__loading" />}
            {customers.length === 0 && 
              <div className="customers-table__empty-warning">No Customers found.</div>
            }
            {customers.length > 0 && 
              customers.map(customer => {
                return (
                  <div key={customer.email} className="customers-table__row">
                    <a href={`/app/customers/${customer._id}`} className="customers-table__cell customers-table__cell--link">{customer.email}</a>
                    <div className="customers-table__metrics">
                      <div className="customers-table__cell">{Intl.NumberFormat('en-us').format(Math.floor(customer.points))}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {pagination && 
            <TablePagination 
              options={pagination} 
              docType="customers" 
              previous={() => setPage(pagination.prevPage)} 
              next={() => setPage(pagination.nextPage)}
            />
          }
        </div>
      </section>
    </div>
  )
}