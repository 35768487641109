import checkmark from '../../assets/images/icons/checkmark.png';
import { BlankHeader, ContactForm } from '../../components/website/Utility';

export default function Pricing() {
  return (
    <>
      <BlankHeader heading="PLANS AND PRICING" />   
      <div className="pricing__section">
        <h2 className="pricing-details__heading">FREE for a Limited Time</h2>
        <p className="pricing-details__body">No matter the size of your store, you can get started with Amplify Loyalty completely FREE for a limited time. Amplify Loyalty will be a paid application in the future but is giving free accounts to all merchants while the application is in beta.</p>
        <div className="pricing-details__features">
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Custom Earning Rules</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">VIP Program</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Unlimited Integrations</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Coupon Rewards</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Timed Promotions</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Customizable Web Page</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Reporting Dashboard</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Scheduled Giveaways</p>
          </div>
          <div className="pricing-details__feature-wrapper">
            <img src={checkmark} alt="" className="pricing-details__feature-checkmark" />
            <p className="pricing-details__feature-text">Onsite Marketing Tools</p>
          </div>
        </div>
        <p className="pricing-details__body">All tiers of the application will have all available features and pricing will scale on order volume. There will always be a free tier available.</p>
      </div>
      <ContactForm heading="HAVE ANY QUESTIONS?" />
    </>
  )
}