import React from 'react';

export default function Error() {
    return (
      <>
      <div className="error-page">
        <h1 className="error-page__heading">404 Page Not Found</h1>
        <p className="error-page__message">The page you are looking for could not be found. Please return to the <a href="/" >home</a> page.</p>
      </div>
      </>
    )
}