import { BlankHeader, ContactForm } from "../../components/website/Utility";


export default function Contact() {
  return (
    <>
      <BlankHeader heading="CONTACT" />
      <ContactForm heading="Want to get in touch?" body="Please feel free to reach out to us regarding questions about pricing, features, or technical issues with the application at trevor@amplifyloyalty.com." consume={true}/>      
    </>
  )
}