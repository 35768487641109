import axios from 'axios';
import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import { titleCaseText } from '../../components/utility/Utility';
import { Scorecard } from '../../components/utility/Charts';
import { AnnouncementBarPreview, CollectionsPreview, ProductsPreview, CartPreview } from '../../components/PromotionsComponents';
import { active } from 'd3';

export default function PromotionReporting() {
  const {logout} = useContext(AuthContext);
  const [promotion, setPromotion] = useState(false);
  const [mobile, setMobile] = useState(false);
  const amplifierRef = useRef(false);
  const [activeAmplifier, setActiveAmplifier] = useState(false);
  const [amplifierValues, setAmplifierValues] = useState(false);
  const [hasAmplifiers, setHasAmplifiers] = useState(false);
  const [endConfirmation, setEndConfirmation] = useState(false);
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // get promotion
    axios.get('https://api.amplifyloyalty.com/app/stores/promotions',
    {
      params: {
        jwt: localStorage.getItem('_id'),
        id
      }
    }).then(async res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else if (res.data[0].status === 'draft') {
        navigate(`/app/promotions/${id}`);
      } else {
        setPromotion(res.data[0]);
        for (let key of Object.keys(res.data[0].amplifiers)) {
          if (key === 'cart') {
            if (res.data[0].amplifiers.cart.points_display.point_status || res.data[0].amplifiers.cart.redemptions.redemption_status) setHasAmplifiers(true);
          } else {
            if (res.data[0].amplifiers[key].status) setHasAmplifiers(true);
          }
        }
      }
    }).catch(console.log);
  }, [])

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const convertDate = (date) => {
    let d = new Date(date);
    let hours = d.getHours();
    let daytime = hours > 11 ? 'PM' : 'AM';
    if (hours === 0) hours = 12;
    if (hours > 12) hours-=12;
    let minutes = d.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().slice(2)} ${hours}:${minutes}${daytime}`
  }

  const convertAmpKey = (key) => {
    const alts = {
      announcement: 'Announcement Bar',
      cart: 'Cart',
      collection: "Collections",
      product: "Products"
    }
    return alts[key];
  }

  const toggleMobile = (bool, t) => {
    setMobile(bool);
    if (!t.classList.contains('amplifier-preview__icon-wrapper--active')) {
      document.querySelector('.amplifier-preview__icon-wrapper--active').classList.remove('amplifier-preview__icon-wrapper--active');
      t.classList.add('amplifier-preview__icon-wrapper--active');
    }
  }

  const updateAmplifierValues = (target, amp) => {
    // update the menu active
    setActiveAmplifier(amp);
    let activePreview = document.querySelector('.promotion-reporting-amplifiers__menu-item--active');
    if (activePreview) {
      activePreview.classList.remove('promotion-reporting-amplifiers__menu-item--active');
      target.classList.add('promotion-reporting-amplifiers__menu-item--active')
    }
    let amplifier;
    switch (amp) {
      case "announcement": 
        amplifier = promotion.amplifiers.announcement;
        setAmplifierValues({
          announcement_status: true,
          announcement_text: amplifier.text, 
          announcement_background_color: amplifier.background_color, 
          announcement_font_color: amplifier.font_color, 
          announcement_font_size: amplifier.font_size
        });
        amplifierRef.current = amp;
        break;
      case "cart": 
        amplifier = promotion.amplifiers.cart;
        setAmplifierValues({
          cartpoints_point_status: amplifier.points_display.point_status || false,
          cartpoints_text: amplifier.points_display.text,
          cartpoints_font_size: amplifier.points_display.font_size,
          cartpoints_font_color: amplifier.points_display.font_color,
          cartpoints_points_color: amplifier.points_display.points_color,
          cartpoints_background_color: amplifier.points_display.background_color,
          cartpoints_border_color: amplifier.points_display.border_color,
          cartredemptions_redemption_status: amplifier.redemptions.redemption_status || false,
          cartredemptions_redemption_font_size: amplifier.redemptions.redemption_font_size,
          cartredemptions_redemption_background_color: amplifier.redemptions.redemption_background_color,
          cartredemptions_redemption_font_color: amplifier.redemptions.redemption_font_color
        });
        amplifierRef.current = amp;
        break;
      case "collection": 
        amplifier = promotion.amplifiers.collection;
        setAmplifierValues({
          collection_status: true,
          collection_text: amplifier.text,
          collection_font_color: amplifier.font_color,
          collection_points_color: amplifier.points_color,
          collection_font_size: amplifier.font_size
        });
        amplifierRef.current = amp;
        break;
      case "product":
        amplifier = promotion.amplifiers.product;
        setAmplifierValues({
          product_status: true,
          product_text: amplifier.text,
          product_font_size: amplifier.font_size,
          product_font_color: amplifier.font_color,
          product_points_color: amplifier.points_color
        });
        amplifierRef.current = amp;
        break;
      default:
        console.log('amp not found');
      }
  }

  const end = () => {
    const buttons = document.querySelectorAll('.promotion-reporting-header__buttons > button');
    buttons.forEach(btn => btn.disabled = true);
    axios.post('https://api.amplifyloyalty.com/app/stores/promotions/end', 
      {
        Promotion: id
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      console.log(res);
      navigate(0);
    }).catch(err => {
      console.log(err);
      buttons.forEach(btn => btn.disabled = false);
    })
  }

  const pauseEdit = () => {
    const buttons = document.querySelectorAll('.promotion-reporting-header__buttons > button');
    buttons.forEach(btn => btn.disabled = true);
    axios.post('https://api.amplifyloyalty.com/app/stores/promotions/pause',
      {
        _id: id
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      console.log(res);
      navigate(`/app/promotions/${id}`);
    }).catch(err => {
      console.log(err);
      buttons.forEach(btn => btn.disabled = false);
    })
  }

  const duplicatePromotion = () => {
    const button = document.querySelector('.promotion-reporting-header__button');
    button.disabled = true;
    let today = new Date();
    let arbitraryEnd = new Date();
    arbitraryEnd.setDate(arbitraryEnd.getDate() + 7);
    let todayDate = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;

    axios.post(
      'https://api.amplifyloyalty.com/app/stores/promotions/duplicate',
      {
        _id: promotion._id,
        name: `${promotion.name} - Copy`,
        start_time: new Date(`${todayDate} 00:00:00`),
        end_time: new Date(`${arbitraryEnd.getMonth()+1}/${arbitraryEnd.getDate()}/${arbitraryEnd.getFullYear()} 00:00:00`),
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res);
        setTimeout(() => {
          navigate(`/app/promotions/${res.data._id}`);
        }, 1000);
      }
    }).catch(err => {
      console.log(err);
      button.disabled = false;
      // responseHandler(err);
    })
  }
  

  return (
    <div className="main">
      {endConfirmation && 
        <div className="popup-overlay">
          <div className="promotion-reporting__end-confirmation">
            <div className="promotion-reporting__end-warning-text">Are you sure you want to cancel this promotion? Doing so will end it now and you won't be able to resume it.</div>
            <div className="promotion-reporting__end-buttons">
              <button className="promotion-reporting__end-button btn gradient" onClick={() => setEndConfirmation(false)}>Cancel</button>
              <button className="promotion-reporting__end-button btn" onClick={end}>End Promotion</button>
            </div>
          </div>
        </div>
      }
      {promotion && 
        <>
          <section className="promotion-reporting-header block-section centralize">
            {/* name,schedule */}
            <div className="promotion-reporting-header__info">
              <div className="promotion-reporting-header__name">{promotion.name}</div>
              <div className="promotion-reporting-header__schedule">Schedule: {convertDate(promotion.start_time)} - {convertDate(promotion.end_time)}</div>
            </div>
            <div className="promotion-reporting-header__buttons">
              <button className="promotion-reporting-header__button btn gradient" onClick={duplicatePromotion}>Duplicate</button>
              {promotion.status === "active" && <button className="promotion-reporting-header__button btn" onClick={() => setEndConfirmation(true)}>End Now</button>}
              {promotion.status === "scheduled" && <button className="promotion-reporting-header__button" onClick={() => pauseEdit()}>Pause & Edit</button> }
            </div>
          </section>
          <section className="promotion-reporting-row centralize">
            <div className="promotion-reporting-multiplier block-section">
              <div className="promotion-reporting-multiplier__info"><strong>Multiplier</strong>: {promotion.multiplier}x</div>
              <div className="promotion-reporting-multiplier__info"><strong>Type</strong>: {titleCaseText(promotion.restriction_type)}</div>
              {promotion.ids.length > 0 && 
                <div className="promotion-reporting-multiplier__info"><strong>Restrictions</strong>: {promotion.ids.map((d, i) => `${i > 0 ? ', ' : ''}${d.title}`)}</div>
              }
            </div>
            <div className="promotion-reporting-data block-section">
              <Scorecard data={USDollar.format(promotion.subtotal)} className="promotion-reporting-data__scorecard" title="Revenue" />
              <Scorecard data={promotion.orders.toLocaleString('en-US')} className="promotion-reporting-data__scorecard" title="Orders" />
              <Scorecard data={promotion.points.toLocaleString('en-US')} className="promotion-reporting-data__scorecard" title="Points Issued" />
              <Scorecard data={USDollar.format(promotion.subtotal ? promotion.subtotal / promotion.orders : 0)} className="promotion-reporting-data__scorecard" title="AOV" />
              <Scorecard data="0" className="promotion-reporting-data__scorecard" title="Redemptions" />
              <Scorecard data="0" className="promotion-reporting-data__scorecard" title="$ Discounted" />
            </div>
            {/* multiplier, restrictions, amplifiers */}

          </section>
          <section className="promotion-reporting-amplifiers block-section centralize">
            <div className="promotion-reporting-amplifiers__header">
              <div className="promotion-reporting-amplifiers__header-text">Amplifiers</div>
            </div>
            <div className="promotion-reporting-amplifiers__previews">
              {!hasAmplifiers && 
                <div className="promotion-reporting-amplifiers__none">
                  No amplifiers were active for this promotion.
                </div>
              }
              {hasAmplifiers && 
                <>
                  <div className="promotion-reporting-amplifiers__menu">
                    {Object.keys(promotion.amplifiers).sort().map((key, i) => {
                      if (key === 'cart') {
                        if (promotion.amplifiers.cart.points_display.point_status || promotion.amplifiers.cart.redemptions.redemption_status) {
                          if (!amplifierRef.current) {
                            amplifierRef.current = true;
                            updateAmplifierValues(null, key);
                          }
                          return <div key={i} onClick={(e) => updateAmplifierValues(e.currentTarget, key)} className={`promotion-reporting-amplifiers__menu-item${i === 0 ? ' promotion-reporting-amplifiers__menu-item--active' : ''}`}>Cart</div>;
                        } else return false;
                      }
                      if (promotion.amplifiers[key].status) {
                        if (!amplifierRef.current) {
                          amplifierRef.current = true;
                          updateAmplifierValues(null, key);
                        }
                        if (key !== 'collection') return <div key={i} onClick={(e) => updateAmplifierValues(e.currentTarget, key)} className={`promotion-reporting-amplifiers__menu-item${i === 0 ? ' promotion-reporting-amplifiers__menu-item--active' : ''}`}>{convertAmpKey(key)}</div>
                      } else return false;
                    })}
                  </div>
                  <div className="promotion-reporting-amplifiers__device-toggle">
                    {/* add onclick toggling */}
                    <div className="amplifier-preview__device-toggle">
                      <div onClick={(e) => {toggleMobile(false, e.currentTarget)}} className="amplifier-preview__icon-wrapper amplifier-preview__device--desktop amplifier-preview__icon-wrapper--active">
                        <i className="amplifier-preview__icon fa-solid fa-display"></i>
                      </div>
                      <div className="divider"></div>
                      <div onClick={(e) => {toggleMobile(true, e.currentTarget)}} className="amplifier-preview__icon-wrapper amplifier-preview__device--mobile">
                        <i className="amplifier-preview__icon fa-solid fa-mobile-screen"></i>
                      </div>
                    </div>
                  </div>
                  <div className="promotion-reporting-amplifiers__preview">
                    {!mobile && amplifierValues &&  
                      <div className="update-promotion-preview__display-desktop">
                        <div className="amplifier-preview__desktop">
                          <div className="amplifier-preview__desktop-screen">
                            {activeAmplifier === 'announcement' && amplifierRef.current === activeAmplifier &&
                              <AnnouncementBarPreview values={amplifierValues} mobile={mobile} />
                            }
                            {activeAmplifier === 'product' && amplifierRef.current === activeAmplifier && 
                              <ProductsPreview values={amplifierValues} mobile={mobile} />
                            }
                            {activeAmplifier === 'cart' && amplifierRef.current === activeAmplifier && 
                              <CartPreview values={amplifierValues} mobile={mobile} />
                            }
                          </div>
                          <div className="amplifier-preview__desktop-stand"></div>
                        </div>
                      </div>
                    }
                    {mobile && amplifierValues && 
                      <div className="update-promotion-preview__display-mobile">
                        <div className="amplifier-preview__mobile-screen">
                        {activeAmplifier === 'announcement' && amplifierRef.current === activeAmplifier &&
                          <AnnouncementBarPreview values={amplifierValues} mobile={mobile} />
                        }
                        {activeAmplifier === 'product' && amplifierRef.current === activeAmplifier && 
                          <ProductsPreview values={amplifierValues} mobile={mobile} />
                        }
                        {activeAmplifier === 'cart' && amplifierRef.current === activeAmplifier && 
                          <CartPreview values={amplifierValues} mobile={mobile} />
                        }
                        </div>
                      </div>
                    }
                  </div>
                </>
              }
            </div>
          </section>
        </>
      }
    </div>
  )
}