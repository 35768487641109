import { 
  responseHandler, 
  titleCaseText, 
  Loading, 
  CustomTable, 
  TablePagination, 
  EditMenu, 
  DropDownFilter,
  ToolTip 
} from "./utility/Utility";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useEffect, useState, useRef, useContext } from "react";
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import {ReactComponent as ShopSVG} from '../assets/images/shop.svg';
import {ReactComponent as ProfileSVG} from '../assets/images/profile.svg';
import {ReactComponent as GiftSVG} from '../assets/images/gift.svg';
import {ReactComponent as FBSVG} from '../assets/images/facebook-like.svg';
import {ReactComponent as IGSVG} from '../assets/images/intagram-follow.svg';
import {ReactComponent as MailSVG} from '../assets/images/mail.svg';
import {ReactComponent as SMSSVG} from '../assets/images/message.svg';
import {ReactComponent as ReferSVG} from '../assets/images/refer.svg';

const findTitle = (key) => {
  switch(key) {
    case "facebook":
      return 'Like Facebook Page';
    case "instagram":
      return 'Follow Instagram';
    case "birthday":
      return 'Birthday';
    case "email":
      return 'Sign up for Emails'
    case "texts":
      return 'Sign up for Texts'
    case "referral":
      return "Referrals"
    case "account_creation":
      return "Create an Account"
    case "purchase":
      return "Make a Purchase"
    default:
      return '!N/A'
  }
}
const iconClasses = {
  'purchase': <ShopSVG className="earnings-option__title-icon"/>,
  'account_creation': <ProfileSVG className="earnings-option__title-icon"/>,
  'birthday': <GiftSVG className="earnings-option__title-icon"/>,
  'facebook': <FBSVG className="earnings-option__title-icon"/>,
  'instagram': <IGSVG className="earnings-option__title-icon"/>,
  'email': <MailSVG className="earnings-option__title-icon"/>,
  'texts': <SMSSVG className="earnings-option__title-icon"/>,
  'referral': <ReferSVG className="earnings-option__title-icon"/>
}

export const EarningsTable = (props) => {
  const {logout} = useContext(AuthContext);
  const {program, setProgram, pageInfo, setPageInfo, option, setOption} = props;
  const navigate = useNavigate();
  const moneyFormat = Intl.NumberFormat('en-US', {style: "currency", currency: 'USD', maximumFractionDigits: 0});
  const numberFormat = Intl.NumberFormat('en-US');

  const deletePromotion = (promotion) => {
    if (window.confirm('Are you sure you want to delete this Promotion? It cannot be undone.')) {
      axios.post(
        'https://api.amplifyloyalty.com/app/stores/promotions/delete',
        promotion,
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          console.log(res);
          // setpromotions without the promotion
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const editPromotion = (id) => {
    axios.post('https://api.amplifyloyalty.com/app/stores/promotions/pause',
      {
        _id: id
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <section className='promotions-container centralize'>
      <div className="promotions-table">
        <TableFilters program={program} setProgram={setProgram} pageInfo={pageInfo} setPageInfo={setPageInfo}>
          <div className="promotions-table__header">
            <div className="promotions-table__heading">Promotion</div>
            <div className="promotions-table__metrics">
              <div className="promotions-table__heading">Points Issued</div>
              <div className="promotions-table__heading">Orders</div>
              <div className="promotions-table__heading">Sales</div>
              <div className="promotions-table__heading"></div>
            </div>
          </div>
          <div className="promotions-table__body">
            {!program && <Loading className="promotions-table__loading" />}  
            {program.length === 0 && 
              <div className="promotions-table__empty-warning">No Promotions found.</div>
            }
            {program.length > 0 &&
              program.map((promo, dataKey) => {
                let start = new Date(promo.start_time);
                let end = new Date(promo.end_time);
                return (
                  <div key={dataKey} className="promotions-table__row">
                    <div className="promotions-table__cell promotions-table__info">
                      <div className="promotions-table__title">
                        {promo.status !== 'ended' && 
                          <a className="promotions-table__name" href={`/promotions/${promo._id}`}>{promo.name}</a>
                        }
                        {promo.status === 'ended' && 
                          <div className="promotions-table__name">{promo.name}</div>
                        }
                        {promo.amplifiers ? Object.keys(promo.amplifiers).map(key => {
                          let icons = {
                            'product': 'shirt',
                            'announcement': 'bullhorn',
                            'collection': 'bars',
                            'cart': 'cart-shopping'
                          }
                          if (promo.amplifiers[key].status) {
                            return (
                              <i key={key} className={`fa-solid fa-${icons[key]} promotions-table__amplifier-icon`}></i>
                            )
                          }
                        }) : null}
                      </div>
                      <div className="promotions-table__execution-info">
                        {/* put the status here (scheduled, executed) */}
                        <span className="promotions-table__status">{titleCaseText(promo.status)}:</span> {`${start.getMonth()+1}/${start.getDate()}/${start.getFullYear().toString().slice(2,)} `} 
                        at {`${start.getHours() > 12 ? start.getHours() % 12 : start.getHours() === 0 ? 12 : start.getHours()}:${start.getMinutes() < 10 ? `0${start.getMinutes()}` : start.getMinutes()}${start.getHours() > 11 ? 'PM' : 'AM'} `}
                        until {`${end.getMonth()+1}/${end.getDate()}/${end.getFullYear().toString().slice(2,)} `} 
                        at {`${end.getHours() > 12 ? end.getHours() % 12 : end.getHours() === 0 ? 12 : end.getHours()}:${end.getMinutes() < 10 ? `0${end.getMinutes()}` : end.getMinutes()}${end.getHours() > 11 ? 'PM' : 'AM'}`}
                      </div>
                    </div>
                    <div className="promotions-table__metrics">
                      <div className="promotions-table__cell">{numberFormat.format(promo.points)}</div>
                      <div className="promotions-table__cell">{numberFormat.format(promo.orders)}</div>
                      <div className="promotions-table__cell">{moneyFormat.format(promo.subtotal)}</div>
                      <div className="promotions-table__cell">
                        <EditMenu className="promotions-table__edit" options={[
                          {
                            text: promo.status === 'ended' ? 'Analytics' : 'Edit',
                            action: promo.status === 'ended' ? () => navigate('#') : () => editPromotion(promo._id)
                          },
                          {
                            text: 'Duplicate',
                            action: () => console.log('duplicate')
                          },
                          {
                            text: 'Delete',
                            action: () => deletePromotion(promo)
                          }
                        ]}/>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </TableFilters>
      </div>
    </section>
  )
}

const TableFilters = (props) => {
  const {logout} = useContext(AuthContext);
  const {promotions, setPromotions, pageInfo, setPageInfo} = props;
  const [activeDate, setActiveDate] = useState('All Time');
  const [activeSort, setActiveSort] = useState('End Time');
  const [activeDirection, setActiveDirection] = useState(-1);
  const [activeStatus, setActiveStatus] = useState('Any Status');
  const prevSearch = useRef('');
  const prevPromotions = useRef(promotions);

  const getData = (range, start, end, sort, direction, page, status, search) => {
    setPromotions(false);
    let newSort;
    if (sort.includes(' ')) {
      newSort = sort.toLowerCase().split(' ').join('_'); 
    }
    axios.get('https://api.amplifyloyalty.com/app/stores/promotions',
      {
        params: {
          jwt: localStorage.getItem('_id'),
          range: range,
          start_date: start,
          end_date: end,
          sort: newSort ? newSort : sort,
          direction: direction,
          page: page ? page : 1,
          status: status === 'Any Status' ? null : status,
          search: search ? search : document.querySelector('.promotions-table-filters__input').value
        }
      }).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          let info = {...res.data}
          delete info.data;
          setPageInfo(info)
          setPromotions(res.data.data ? res.data.data : []);
        }
      }).catch(err => {
        console.log(err);
      });
  }

  const search = (value) => {
    prevPromotions.current = promotions;
    setTimeout(() => {
      let currentValue = document.querySelector('.promotions-table-filters__input').value;
      if (currentValue === value && value !== prevSearch.current) {
        let [start, end] = checkForRange();
        getData(activeDate, start, end, activeSort, activeDirection, null, activeStatus, value);
        prevSearch.current = value;
      } else {
        setPromotions(prevPromotions.current);
      }
    }, 1000)
  }

  const checkForRange = () => {
    let dates = document.querySelectorAll('.date-range-selector__input-container');
    if (dates.length === 2) {
      dates = dates.map(input => input.value);
    }
    return dates;
  }

  return (
    <>
      <div className="promotions-table-filters">
        <div className="promotions-table-filters__filter-wrapper">
          <input type="text" className="promotions-table-filters__input" placeholder="Search promotions" onChange={(e) => search(e.target.value)}/>
          <DropDownFilter 
              className="promotions-table-filters__status-filter"
              active={activeStatus}
              setActive={setActiveStatus}
              options={[
                {
                  text: 'Any Status',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, 'Any Status');
                  }
                },
                {
                  text: 'Ended',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, 'ended');
                  }
                },
                {
                  text: 'Active',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, 'active');
                  }
                },
                {
                  text: 'Scheduled',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, 'scheduled');
                  }
                },
                {
                  text: 'Draft',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, 'draft');
                  }
                }
              ]}
            />
        </div>
        <div className="promotions-table-filters__modifiers">
          <div className="promotions-table-filters__filter-wrapper">
            <label className="promotions-table-filters__sort-label" htmlFor="sort">Sort by</label>
            <DropDownFilter 
              className="promotions-table-filters__date-filter"
              active={activeSort}
              setActive={setActiveSort}
              options={[
                {
                  text: 'End Time',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'end_time', activeDirection, null, activeStatus);
                  }
                },
                {
                  text: 'Start Time',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'start_time', activeDirection, null, activeStatus);
                  }
                }
              ]}
            />
            <DropDownFilter 
              className="promotions-table-filters__sort-direction"
              active={activeDirection}
              setActive={setActiveDirection}
              options={[
                {
                  text: -1,
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, activeSort, -1, null, activeStatus);
                  }
                },
                {
                  text: 1,
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, activeSort, 1, null, activeStatus);
                  }
                }
              ]}
            />
          </div>
          <DropDownFilter 
            className="promotions-table-filters__filter-wrapper"
            active={activeDate}
            setActive={setActiveDate}
            options={[
              {
                text: 'All Time',
                action: () => getData('All Time', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last 90 Days',
                action: () => getData('Last 90 Days', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last 180 Days',
                action: () => getData('Last 180 Days', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'This Year',
                action: () => getData('This Year', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last Year',
                action: () => getData('Last Year', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Custom Range',
                action: (start, end) => getData('Custom Range', start, end, activeSort, activeDirection, null, activeStatus)
              }
            ]}
          />
        </div>
      </div>
      {props.children}
      {pageInfo.totalPages > 1 && 
        <TablePagination 
          options={pageInfo} 
          docType="promotions"
          next={
            () => {
              let [start, end] = checkForRange();
              getData(activeDate, start, end, activeSort, activeDirection, pageInfo.nextPage, activeStatus);
            }
          }
          previous={
            () => {
              let [start, end] = checkForRange();
              getData(activeDate, start, end, activeSort, activeDirection, pageInfo.prevPage, activeStatus);
            }
          }
        />
      }
    </>
  )
}

export const OldEarningsTable = (props) => {
  const {logout} = useContext(AuthContext);
  // Create a form for each field. Display on pop-up or on click in place for the points name
  const [program, setProgram] = props.program;
  const [option, setOption] = props.option;
  const { setIsAdding, setIsEditing } = props

  const setDescription = (key, value) => {
    switch(key) {
      case "purchase":
        return `${value} points for every $1 spent.`
      case "account_creation":
        return `${value} points for signing up.`;
      case "birthday":
        return `${value} points on customer birthday.`;
      case "facebook":
        return `${value} points for liking Facebook page.`;
      case "instagram":
        return `${value} points for following Instagram page.`;
      case "email": 
        return `${value} points for signing up for emails.`;
      case "texts":
        return `${value} points for signing up for texts.`;
      case "referral":
        return "Referrals";
      default:
        return "N/A"
    }
  }

  
  
  const toggleStatus = async (key) => {
    // If email or texts, check for integration before turning on
    let nullValues = false;
    console.log(key)
    for await (let k of Object.keys(program.earnings[key])) {
      if (program.earnings[key][k] === "") {
        nullValues = true;
      }
    }
    if (nullValues) {
      responseHandler(null, null, 'Click the edit icon to finish setup before enabling.');
    } else {
      // Disable the switch
      toggleDisableEditing(key, true);
      // Update state of switch
      let newProgram = {...program};
      newProgram.earnings[key].status = newProgram.earnings[key].status === 'active' ? 'inactive' : 'active';
      setProgram(newProgram);
      // Send axios PUT request to update status
      axios.put('https://api.amplifyloyalty.com/app/stores/earnings',
        {...newProgram.earnings[key], key},
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          // Respond with status
          responseHandler(res, "success");
        }
      }).catch(err => {
        responseHandler(err);
        // Update switch state
        newProgram.earnings[key].status = newProgram.earnings[key].status === 'active' ? 'inactive' : 'active';
      }).finally(() => {
        // Re-enable switch
        toggleDisableEditing(key, false);
      }) 
    }
  }

  const toggleDisableEditing = (key, disable) => {
    let editTools = document.querySelectorAll(`.earnings-option__editing--${key} > *`);
    editTools.forEach(tool => {
      if (tool.classList.contains('switch')) {
        tool.querySelector('.switch-input').disabled = disable ? true : false;
      } else {
        tool.disabled = disable ? true : false;
      }
    })
  }

  return (
    <section className="earnings-options centralize">
      <div className="earnings-option">
        <h4 className="earnings-option__title earnings-option__title--main">Ways to Earn</h4>
      </div>
      {!program && <Loading className="earnings-options__loading" />}
      {program && Object.keys(program.earnings).map(key => {
        if (program.earnings[key].status !== 'disabled') {
          return (
            <div key={key} className="earnings-option">
              <div className="earnings-option__heading">
                <h5 className="earnings-option__title">
                  {iconClasses[key]}
                  {findTitle(key)}</h5>
                <p className="earnings-option__description">{setDescription(key, program.earnings[key].points)}</p>
              </div>
              <div className={`earnings-option__editing earnings-option__editing--${key}`}>
                <label className="earnings-option__toggle switch">
                  <input className="switch-input" type="checkbox" name={key} value={program.earnings[key].status === 'active' ? true : false}  onChange={() => {toggleStatus(key);}} />
                  <span className="slider"></span>
                </label>
                <button className="earnings-option__button"><i className="earnings-option__edit fa-solid fa-pen-to-square" onClick={() => {
                  setOption({...program.earnings[key], key: key}); 
                  setIsEditing(true);
                  }}></i></button>
              </div>
            </div>
          )
        }
      })}
    <div className="earnings-options__button-container">
      <button className="earnings-options__add-more btn gradient" onClick={() => {setIsAdding(true)}}>Add more</button>
    </div>
    </section>
  )
}

export const EarningsForm = (props) => {
  const {logout} = useContext(AuthContext);
  const [program, setProgram] = props.program;
  const [option, setOption] = props.option;
  const [isEditing, setIsEditing] = props.editing;
  const { setIsAdding } = props;
  const initialValues = {...option};
  delete initialValues.key;

  useEffect(() => {
    console.log(option);
  })

  const updateEarnings = (values) => {
    toggleDisableEditing(true);
    values.status = values.status ? "active" : "inactive";
    let payload = {...program.earnings}
    payload[option.key] = values;
    axios.put('https://api.amplifyloyalty.com/app/stores/earnings',
      payload,
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          // use option key to update state
          responseHandler(res, "success");
          setOption(false); 
          let newProgram = {...program};
          newProgram.earnings[option.key] = res.data.program.earnings[option.key];
          setProgram(newProgram);
        }
      }).catch (err => {
        console.log(err);
        responseHandler(err);
      }).finally(() => {
        toggleDisableEditing(false);
      });
  }

  const toggleDisableEditing = (disable) => {
    let targets = [...document.querySelectorAll('.earnings-form__editing > *'), ...document.querySelectorAll('.earnings-form__button-container > *')];
    targets.forEach(target => {
      if (target.classList.contains('switch')) {
        target.querySelector('.switch-input').disabled = disable ? true: false;
      } else {
        target.disabled = disable ? true : false;
      }
    })
  }

  const deleteEarning = (key) => {
    let payload = {...program.earnings}
    payload[key].status = 'disabled';
    // Set table row to disabled state
    toggleDisableEditing(true);
    axios.put('https://api.amplifyloyalty.com/app/stores/earnings',
      payload,
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        // set state of earning to inactive & remove disabled
        let newProgram = {...program}
        newProgram.earnings[key].status = 'disabled';
        setProgram({
          ...newProgram
        })
        responseHandler(res, "success");
        setOption(false);
      }
    }).catch(err => {
      responseHandler(err);
    }).finally(() => {
      toggleDisableEditing(false);
    })
  }

  const setSchema = () => {
    // Validate for URLs
    const schema = {};
    Object.keys(initialValues).map(key => {
      switch(typeof initialValues[key]) {
        case "number":
          schema[key] = Yup.number('Must be a number.').integer('Must be a whole number.').moreThan(0, 'Must be greater than 0.').required('Field is required.');
          break;
        case "string":
          schema[key] = Yup.string().required('Field is required.');
          break;
        default:
          break;
      }
    })
    return schema;
  }
  
  const findTitleDescription = (key, option) => {
    switch(key) {
      case "points":
        if (option === "purchase") {
          return 'Customer earns per $1 spent';
        } else {
          return "Customer earns"
        }
      case "link":
        return `Link to page`;
      case "days_delayed":
        return 'Minimum days after signup'
      default:
        return "!N/A"
    }
  }
  
  return (
    <div className="popup-overlay">
      <div className="earnings-form-container">
        <Formik 
            initialValues={{
              ...initialValues,
              status: option.status === 'disabled' || option.status === 'active' ? true : false
            }}
            // enaleReinitialize={true}
            validationSchema={Yup.object(setSchema())}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateEarnings({...values, key: option.key});
            }}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
          >
            <Form className="earnings-form">
              <div className="earnings-form__heading">
                <h3 className="earnings-form__title">{findTitle(option.key)}</h3>
                <div className="earnings-form__editing">
                  <label className="earnings-form__toggle-status switch">
                    <Field className="switch-input" type="checkbox" name="status" />
                    <span className="slider"></span>
                  </label>
                  {(isEditing && option.key !== 'purchase') && <button className="earnings-form__button"><i className="fa-solid fa-trash earnings-form__delete" onClick={() => {deleteEarning(option.key)}}></i></button> }
                </div>
              </div>
              {Object.keys(initialValues).map(key => {
                if (key !== 'status' && key !== '_id') {
                  return (
                    <>
                      <div key={key} className="earnings-form__field">
                        <label htmlFor={key}>{findTitleDescription(key, option.key)}</label>
                        <div className="earnings-form__input-container">
                          <Field className={`earnings-form__input${(typeof initialValues[key]) === "number" ? " earnings-form__input--number" : ""}`} name={key} type={typeof option[key] == "string" ? "text" : "number"} />
                          {(typeof initialValues[key] === "number") &&
                            <div className="earnings-form__input-identifier">{key === "points" ? "points" : "days"}</div>
                          }
                        </div>
                      </div>
                      <div className="earnings-form__errors"><ErrorMessage name={key} /></div>
                    </>
                  )
                }
              })}
              <div className="earnings-form__field earnings-form__button-container">
                <button className="earnings-form__cancel-button btn" onClick={() => {
                  setOption(false); 
                  if (!isEditing) {
                    setIsAdding(true);
                  } else {
                    setIsEditing(false);
                  }
                }}>Cancel</button>
                <button className="earnings-form__submit-button btn gradient" type="submit">Save</button>
              </div>
            </Form>
          </Formik>
        </div>
    </div>
  )
}

export const EarningsOptions = (props) => {
  const {program, setOption, setIsAdding} = props;
  return (
    <div className="popup-overlay">
      <div className="add-earnings">
        <div className="add-earnings__section add-earnings__section--main">
          <h3 className="add-earnings__title">Ways to Earn</h3>
          <div className="add-earnings__close-button close-button" onClick={() => {setIsAdding(false)}}>x</div>
        </div>
          {Object.keys(program.earnings).map((key, i) => {
            if (program.earnings[key].status === 'disabled') {
              if (key === 'referral' | key === 'texts') {
                return (
                  <div key={i} className="add-earnings__section">
                    <div className="add-earnings__heading-container">
                      {iconClasses[key]}
                      <h4 className="add-earnings__name add-earnings__name--disabled">{findTitle(key)}</h4>
                    </div>
                    <button className="add-earnings__button btn btn--disabled gradient">Coming Soon</button>
                  </div>  
                )
              } else {
                return (
                  <div key={i} className="add-earnings__section">
                    <div className="add-earnings__heading-container">
                      {iconClasses[key]}
                      <h4 className="add-earnings__name">{findTitle(key)}</h4>
                    </div>
                    <button className="add-earnings__button btn gradient" onClick={() => {
                      // if (key === 'email' && )
                      setIsAdding(false); 
                      setOption({...program.earnings[key], key});
                    }}>Activate</button>
                  </div>
                )
              }
            }
          })}
      </div>
    </div>
  )

}

export const EarningsFormContainer = (props) => {
  const {logout} = useContext(AuthContext);
  const [program, setProgram] = props.program;
  const [option, setOption] = props.option;
  const [isEditing, setIsEditing] = props.editing;
  const [errors, setErrors] = props.errors;
  const { setIsAdding, schema } = props;
  const initialValues = {...option};
  delete initialValues.key;

  const updateEarnings = (values, del) => {
    toggleDisableEditing(true);
    if (del) values.status = 'disabled';
    else values.status = values.status ? "active" : "inactive";
    axios.put('https://api.amplifyloyalty.com/app/stores/earnings',
      values,
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          // use option key to update state
          responseHandler(res, "success");
          let newProgram = {...program};
          newProgram.earnings[option.key] = res.data.program.earnings[option.key];
          setOption(false); 
          setProgram(newProgram);
        }
      }).catch (err => {
        console.log(err);
        responseHandler(err);
        toggleDisableEditing(false);
      })

  }

  const toggleDisableEditing = async (disable) => {
    let targets = [...document.querySelectorAll('.earnings-form__input'), 
    ...document.querySelectorAll('.earnings-form__button-container > *'),
    document.querySelector('.earnings-form__toggle-status')];
    for await (let target of targets) {
      if (target) {
        try {
          if (target.classList.contains('switch')) {
            target.querySelector('.switch-input').disabled = disable ? true: false;
          } else {
            target.disabled = disable ? true : false;
          }
        } catch (err) {
          console.log(err, target);
        }
      }
    }
  }
  
  return (
    <div className="popup-overlay">
      <div className="earnings-form-container">
        <Formik
          initialValues={{
            ...initialValues,
            status: option.status === 'disabled' || option.status === 'active' ? true : false,
          }}
          validationSchema={Yup.object(schema)}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateEarnings({...values, key: option.key});
          }}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form className="earnings-form">
            <div className="earnings-form__heading">
              <div className="earnings-form__title">{findTitle(option.key)}</div>
              <div className="earnings-form__editing">
                <label className="earnings-form__toggle-status switch">
                  {/* <input className="switch-input" type="checkbox" name="status" /> */}
                  <Field className="switch-input" type="checkbox" name="status" />
                  <span className="slider"></span>
                </label>
                {(isEditing && option.key !== 'purchase') && <div className="earnings-form__button"><i className="fa-solid fa-trash earnings-form__delete" onClick={() => {updateEarnings(option, true)}}></i></div> }
              </div>
            </div>
            <div className="earnings-form__field">
              <label htmlFor="points">Customer earns {option.key === 'purchase' ? "per $1 spent" : ""}</label>
              <div className="earnings-form__input-container">
                <Field className="earnings-form__input earnings-form__input--number" name="points" type="number" />
                <div className="earnings-form__input-identifier">points</div>
              </div>
            </div>
            <div className="earnings-form__errors"><ErrorMessage name="points" /></div>

            {props.children}
            {errors && <div className="earnings-form__errors">{errors}</div>}
            <div className="earnings-form__field earnings-form__button-container">
              <button className="earnings-form__cancel-button btn" onClick={() => {
                setErrors(false);
                setOption(false); 
                if (!isEditing) {
                  setIsAdding(true);
                } else {
                  setIsEditing(false);
                }
              }}>Cancel</button>
              {!errors && 
                <button className="earnings-form__submit-button btn gradient" type="submit">Save</button>
              }
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export const EarningFormField = (props) => {
  const {type, label, name, identifier, inputClass, options, tooltip} = props;
  return (
    <>
      <div className="earnings-form__field">
        <div className="earnings-form__label-container">
          <label htmlFor={name}>{label}</label>
          {tooltip && <ToolTip message={tooltip.message} height={tooltip.height} width={tooltip.width}/>}
        </div>
          
        {type !== 'select' && 
          <div className="earnings-form__input-container">
            <Field className={`earnings-form__input ${inputClass ? inputClass : ""}`} name={name} type={type} />
            {identifier && <div className="earnings-form__input-identifier">{identifier}</div>}
          </div>
        }
        {type === "select" && 
          <div className="earnings-form__input-container">
            <Field className={`earnings-form__input ${inputClass ? inputClass : ""}`} name={name} as="select">
              <option value="" disabled>Select one</option>
              {options && options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
            </Field>
          </div>
        }
      </div>
      <div className="earnings-form__errors"><ErrorMessage name={name} /></div>
    </>
  )
}

export const OnChange = (props) => {
  const {setEarningsErrors, getEmailLists, option, setOption} = props;
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    setEarningsErrors(false);
    switch (values.integration) {
      case "klaviyo": 
        getEmailLists('klaviyo').then(res => {
          console.log(res);
          let lists = res.data.map(list => {return {value: list.id, name: list.attributes.name}});
          setOption({
            ...option,
            integration: 'klaviyo',
            lists: lists
          });
          if (!values.list_id) setFieldValue('list_id', lists[0].value);
        }).catch(err => {
          console.log(err);
          setEarningsErrors('Error retrieving lists, try again later.');
        });
        break;
      case "":
        setEarningsErrors('Set up an integration before enabling emails.');
        break;
      default: 
        setEarningsErrors('That integration is not yet available');
    }
    // if the list value is empty, set it to the first value
  }, [values.integration]);
  return null;
}