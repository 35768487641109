import { Formik, Field, Form, ErrorMessage, useFormikContext, isSubmitting } from 'formik';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import {
  DatePicker, 
  TimePicker, 
  ColorPicker, 
  CreateText, 
  ProductCard, 
  titleCaseText, 
  SearchPreview, 
  PicksDisplay, 
  WarningIcon, 
  Loading,
  EditMenu,
  DropDownFilter,
  TablePagination,
  ToolTip
} from './utility/Utility';
import { Scorecard } from './utility/Charts';
import axios from 'axios';

export const CustomerSummary = (props) => {
  const {customer, setCustomer} = props;
  const [adjusting, setAdjusting] = useState(false);
  const dollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  })
  return (
    <>
      {adjusting && <CustomerAdjustmentForm customer={customer} setAdjusting={setAdjusting} setCustomer={setCustomer} />}
      <div className="customer-summary centralize">
        {!customer && <Loading className="customer-summary__loading" />}
        {customer && 
          <>
            <div className="customer-summary__email">{customer.email}</div>
            <div className="customer-summary__stats">
              <div className="customer-summary__stats-row">
                <Scorecard className="customer-summary__stat" data={customer.points ? customer.points : "0"} title="Points" />
              </div>
              <div className="customer-summary__stats-row">
                <Scorecard className="customer-summary__stat" data={customer.orders.length} title="Orders"/>
                <Scorecard className="customer-summary__stat" data={dollar.format(customer.orders.reduce((acc, v) => acc + v.subtotal, 0))} title="Total Spent"/>
                <Scorecard className="customer-summary__stat" data={dollar.format(customer.orders.reduce((acc, v) => acc + v.subtotal, 0) / customer.orders.length)} title="AOV"/>
              </div>
            </div>
            <div className="customer-summary__button-container">
              <button onClick={() => setAdjusting(!adjusting)} className="customer-summary__make-adjustment btn gradient">Make Adjustment</button>
            </div>
          </>
        }
      </div>
    </>
  )
}

export const CustomerTable = (props) => {
  const {customer} = props;
  return (
    <div className="customer-table">
      {!customer && <Loading className="customer-summary__loading" />}
      {customer && 
        <></>
      }
    </div>
  )
}

const CustomerAdjustmentForm = (props) => {
  const {customer, setAdjusting, setCustomer} = props;
  const [values, setValues] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const updateValues = (n, v) => {
    setValues({...values, [n]: v});
  }

  const submit = () => {
    const jwt = localStorage.getItem("_id");
    if (!jwt) {
      // logout()
    } else {
      let {points, reason} = values;
      if (!points) {
        setError('Points must be a positive or negative whole number.');
        return false;
      }
      let disabled = [
        ...document.querySelectorAll('.customer-adjustment-form__input'),
        ...document.querySelectorAll('.customer-adjustment-form__buttons button')
      ]
      disabled.forEach(t => t.disabled = true);
      axios.put('https://api.amplifyloyalty.com/app/customers', {
        _id: customer._id,
        points,
        reason
      }, {
        params: {jwt}
      }).then(res => {
        navigate(0);
      }).catch(err => {
        console.log(err);
        disabled.forEach(t => t.disabled = false);
      })
    }
  }

  return (
    <div className="flex-popup-overlay">
      <div className="customer-adjustment-form">
        <div className="customer-adjustment-form__header">Adjust by</div>
        <div className="customer-adjustment-form__input-container">
          <label htmlFor="points">Points</label>
          <input className="customer-adjustment-form__input" type="number" name="points" onChange={(e) => updateValues('points', e.target.value)}/>
        </div>
        <div className="customer-adjustment-form__input-container">
          <label htmlFor="reason">Reason</label>
          <ToolTip message="This is customer facing and will be seen in the customer's profile." width="150px" />
          <input className="customer-adjustment-form__input" type="text" name="reason" onChange={(e) => updateValues('reason', e.target.value)}/>
        </div>
        {error && <div className="customer-adjustment-form__errors">{error}</div>}
        <div className="customer-adjustment-form__point-total">
          New total: <span className="customer-adjustment-form__points gradient-text">{customer.points + (values.points ? parseInt(values.points) : 0)}</span>
        </div>
        <div className="customer-adjustment-form__buttons">
          <button onClick={() => setAdjusting(false)} className="customer-adjustment-form__cancel btn">Cancel</button>
          <button className="btn gradient" onClick={submit}>Submit</button>
        </div>
      </div>
    </div>
  )
}