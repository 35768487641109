import React from "react";
import { useLocation } from "react-router-dom";

export default function Footer() {
    const location = useLocation();
    return (
        <>
            {!location.pathname.includes('/app') && 
                <footer className="footer">
                    <div className="footer__incorporation-notice">2023 Amplify Loyalty</div>
                </footer>
            }
        </>
    );
};
