import { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import klaviyo from '../assets/images/integrations/klaviyo-logo.png';
import judgeMe from '../assets/images/integrations/judge-me-logo.png';
import shopify from '../assets/images/integrations/Shopify-Logo.png';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {Integration} from '../components/IntegrationsComponents';

export default function Integrations() {
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const [integrations, setIntegrations] = useState(false);
  
  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/integrations',
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        setIntegrations(res.data)
      }
    }).catch(err => {
      console.log(err);
    })
  }, [])

  return (
    <div className="main">
      <section className="integrations-wrapper centralize">
        <div className="integrations__heading">E-Commerce</div>
        <div className="integrations">
          <a href="/app/integrations/shopify" className={`integration`}>
            <img className="integration__image" src={shopify} alt="Shopify Logo" />
          </a>
        </div>
      </section>
      <section className="integrations-wrapper centralize">
        <div className="integrations__heading">Email/SMS</div>
        <div className="integrations">
          <a href="/app/integrations/klaviyo" className="integration">
            <img className="integration__image" src={klaviyo} alt="Klaviyo Logo" />
          </a>
        </div>
      </section>
      <section className="integrations-wrapper centralize">
        <div className="integrations__heading">Reviews</div>
        <div className="integrations">
          <a href="/app/integrations/judge-me" className="integration">
            <img className="integration__image" src={judgeMe} alt="JudgeMe Logo" />
          </a>
        </div>
      </section>
    </div>
  ) 
}