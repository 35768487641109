import axios from 'axios';
import '../../assets/css/components/program.css';
import { PromotionsTable } from '../../components/PromotionsComponents';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

export default function Promotions() {
  const {logout} = useContext(AuthContext);
  const [promotions, setPromotions] = useState(false);
  const [pageInfo, setPageInfo] = useState(false);
  
  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/promotions',
    {
      params: {
        jwt: localStorage.getItem('_id')
      }
    }).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        let info = {...res.data}
        delete info.data;
        setPageInfo(info || {
          hasNextPage: false,
          hasPrevPage: false,
          limit: 10,
          nextPage: null,
          page: 1,
          pagingCounter: 1,
          prevPage: null,
          totalDocs: 0,
          totalPages: 1
        });
        setPromotions(res.data.data || []);
      }
    }).catch(err => {
      console.log(err.response);
    })
  },[])

  return (
    <div className="main">
      <PromotionsTable promotions={promotions} setPromotions={setPromotions} pageInfo={pageInfo} setPageInfo={setPageInfo}/>
    </div>
  )
}