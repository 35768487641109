import {useState, useEffect, useContext} from 'react';
import { AuthContext } from '../contexts/AuthContext';
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { Check, ToolTip, Loading, ConfirmationPopup} from './utility/Utility';

export const Shopify = (props) => {
  const {integration, setIntegration, ret} = props;
  const [integrating, setIntegrating] = useState(false);

  useEffect(() => {
    if (ret) setIntegrating(true);
    console.log(ret);
  }, [ret]);

  const integrate = async () => {
    const buttons = [...document.querySelectorAll('.confirmation-popup__buttons button')];
    buttons.forEach(btn => btn.disabled = true);
    try {
      const res = await axios.post('https://api.amplifyloyalty.com/app/stores/integrations/shopify',
        {_id: ret.ret},
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      );
      console.log(res);
      setIntegration(res.data);
      setIntegrating(false);
    } catch (err) {
      console.log(err);
      buttons.forEach(btn => btn.disabled = false);
    }
  }

  return (
    <div className="edit-integration centralize">
      {integrating && 
        <ConfirmationPopup 
          headline="Connect Store"
          description={`Are you sure you want to connect your store ${ret.store} to this account? Only 1 Shopify integration can be active at a time.`}
          action={integrate}
          setActive={setIntegrating}
        />
      }
      <div className="edit-integration__form">
        <div className="edit-integration__form-row edit-integration__form-heading">
          <h2 className='edit-integration__heading'>Shopify</h2>
          {integration.status === 'active' && integration.link === 'active' &&
            <div className="edit-integration__status-row">
              <ToolTip bottom={true} message="To disable integration, uninstall the app from your Shopify store." height="40px" width="145px" />
              <div className="edit-integration__status edit-integration__status--active">Integration is active and working.</div>
            </div>
          }
          {integration.status === 'active' && integration.link !== 'active' &&
            <div className="edit-integration__status-row">
              <ToolTip bottom={true} message="To disable integration, uninstall the app from your Shopify store." height="40px" width="145px" />
              <div className="edit-integration__status edit-integration__status--broken">Integration is broken.</div>
            </div>
          }
          {/* // Do another one for in case the status is active but integration is broken */}
          {integration.status !== 'active' &&
            <a href="https://www.shopify.com/amplify-loyalty" target="_blank" className="btn gradient">Integrate</a>
          }

        </div>
        <div className="edit-integration__form-row">
          <label className="edit-integration__label" htmlFor="store">Store URL</label>
          {integration && 
            <input className="edit-integration__input input--regular" name="store" type="text" value={integration.store} disabled />
          }
          {!integration && <Loading style={{height: "20px", width: "20px"}}/>}
        </div>
        {integration.status !== 'active' && 
          <div className="edit-integration__integration-description">Press the Integrate button to download our app from the Shopify app store to get started.</div>
        }
      </div>
    </div>
  )
}

export const Klaviyo = (props) => {
  const {logout} = useContext(AuthContext);
  const {integration, setIntegration} = props;
  const [showKey, setShowKey] = useState(false);
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({
    status: "",
    name: "Klaviyo",
    privacy_url: "",
    public_key: "",
    sms_number: "",
    status: false,
    subscribe_message: "",
    tags: [],
    terms_url: "",
    send_reward_redeemed: false,
    send_reward_used: false,
    send_giveaway_received: false,
    send_vip_tier_achieved: false
  });
  // needs a section for setting up webhooks
  // can show webhook history over last 7 days like klaviyo for shopify
  const checkChanges = async () => {
    const button = document.querySelector('.edit-integration__submit');
    let different = false;
    for await (let key of Object.keys(values)) {
      if (key === 'status') {
        let status = integration.status === 'active' ? true : false;
        if (status != values.status) {
          different = true;
          break;
        }
      } else if (integration[key] != values[key]) {
        different = true;
        break;
      }
    }
    if (!different) button.disabled = true;
    else button.disabled = false;
  }

  useEffect(() => {
    console.log(integration);
    setValues({...integration, status: integration.status === 'active' ? true : false});
  }, [integration])

  useEffect(() => {
    checkChanges();
  }, [values])

  const updateValue = (key, value) => {
    setValues({...values, [key]: value});
  }
  
  const submit = () => {
    console.log({values})
    // maybe give a warning if there's an active reward for it and status is being turned off
    // make a test call when submitted to make sure the integration works
    setSubmitting(true);
    let inputs = [...document.querySelectorAll('.edit-integration__form input'), document.querySelector('.edit-integration__submit')];
    inputs.forEach(input => input.disabled = true);
    const tags = [];
    if (values.status === true) tags.push('email');
    if (values.sms_number && values.subscribe_message && values.terms_url && values.privacy_url) tags.push('texts');
    let payload = {
      ...values,
      status: values.status === true ? 'active' : "inactive",
      tags
    };
    axios.put('https://api.amplifyloyalty.com/app/stores/integrations',
      payload,
      {
        params: {
          integration: 'klaviyo',
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res.data.status === 'active');
        setIntegration({...res.data});
        console.log(res);
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      inputs.forEach(input => input.disabled = false);
      setSubmitting(false);
    })
  }
  return (
    <section className="edit-integration centralize">
        <div className='edit-integration__form'>
          <div className="edit-integration__form-row">
            <h2 className='edit-integration__heading'>Klaviyo</h2>
            <label className="edit-integration__toggle switch">
              {integration && <input className="switch-input" type="checkbox" name='status' value={values.status} onChange={() => setValues({...values, status: !values.status})} />}
              <span className="slider"></span>
            </label>
          </div>
          {integration && 
            <>
              <div className="edit-integration__form-row">
                <label className="edit-integration__label" htmlFor="public_key">Public Key</label>
                <input className="edit-integration__input input--regular" type="text" name="public_key" value={values.public_key} onChange={(e) => updateValue("public_key", e.target.value)}/>
              </div>

              <div className="edit-integration__form-row" style={{marginBottom: 0}}>
                <label className="edit-integration__label" htmlFor="private_key">Private Key</label>
                <input className="edit-integration__input input--regular" type={showKey ? "text" : "password"} name="private_key" value={values.private_key} onChange={(e) => updateValue("private_key", e.target.value)}/>
              </div>
              <div className="edit-integration__show-key-wrapper">
                <div className="edit-integration__show-key" style={{margin: "5px 0 15px"}} onClick={() => setShowKey(!showKey)}>Show Key</div>
              </div>
              {submitting && <Loading />}
              {!submitting && 
                <>
                  {values.status && integration.link === 'active' &&
                    <div className="edit-integration__status edit-integration__status--active edit-integration__status--row">
                      <div className="edit-integration__status-text">The Klaviyo integration is sending and receiving data.</div>
                    </div>
                  }
                  {values.status && integration.link && integration.link !== 'active' &&
                    <div className="edit-integration__status edit-integration__status--broken edit-integration__status--row">
                      <div className="edit-integration__status-text">The Klaviyo integration is broken. Check status of Private Key.</div>
                    </div>
                  }
                </>
              }
              <div className="edit-integration__divider">
                <div className="edit-integration__divider-title">Events</div>
                <ToolTip 
                  message="Send events to Klaviyo from interactions with your Program so you can use them as Triggers for Flows." 
                  width="200px"
                />
              </div>
              <div className="edit-integration__actions">
                <div className="edit-integration__form-row">
                  <label className="edit-integration__label" htmlFor="send_reward_redeemed">Reward Redeemed</label>
                  <div className="edit-integration__checkbox-wrapper">
                    <label className="edit-integration__toggle switch">
                      <input className="switch-input" type="checkbox" name='send_reward_redeemed' value={values.send_reward_redeemed} onChange={() => setValues({...values, send_reward_redeemed: !values.send_reward_redeemed})} />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="edit-integration__form-row">
                  <label className="edit-integration__label" htmlFor="send_reward_used">Used Reward</label>
                  <div className="edit-integration__checkbox-wrapper">
                    <label className="edit-integration__toggle switch">
                      <input className="switch-input" type="checkbox" name='send_reward_used' value={values.send_reward_used} onChange={() => setValues({...values, send_reward_used: !values.send_reward_used})} />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="edit-integration__form-row">
                  <label className="edit-integration__label" htmlFor="send_giveaway_received">Giveaway Received</label>
                  <div className="edit-integration__checkbox-wrapper">
                    <label className="edit-integration__toggle switch">
                      <input className="switch-input" type="checkbox" name='send_giveaway_received' value={values.send_giveaway_received} onChange={() => setValues({...values, send_giveaway_received: !values.send_giveaway_received})} />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="edit-integration__form-row">
                  <label className="edit-integration__label" htmlFor="send_vip_tier_achieved">VIP Tier Achieved</label>
                  <div className="edit-integration__checkbox-wrapper">
                    <label className="edit-integration__toggle switch">
                      <input className="switch-input" type="checkbox" name='send_vip_tier_achieved' value={values.send_vip_tier_achieved} onChange={() => setValues({...values, send_vip_tier_achieved: !values.send_vip_tier_achieved})} />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                
              </div>
              <div className="edit-integration__divider">
                <div className="edit-integration__divider-title">SMS Settings</div>
                <ToolTip 
                  message="SMS settings are optional, but all fields must be completed for Klaviyo to be an option for a SMS integration in your Program." 
                  width="200px"
                />
              </div>
              <div className="edit-integration__form-row">
                <label className="edit-integration__label" htmlFor="sms_number">SMS Number</label>
                <input className="edit-integration__input input--regular" name="sms_number" type="tel" placeholder="123-456-7891" value={values.sms_number} onChange={(e) => updateValue("sms_number", e.target.value)}/>
              </div>
              <div className="edit-integration__form-row">
                <div className="edit-integration__label-container">
                  <label className="edit-integration__label" htmlFor="subscribe_message">SMS Subscribe Message</label>
                  <ToolTip
                    message="The message that a subscriber will send to your number to subscribe to marketing texts. This message must include keywords that are set to opt-in a user in Klaviyo SMS settings."
                    width="200px"
                  />
                </div>
                <input className="edit-integration__input input--regular" name="subscribe_message" type="text_area" placeholder="" value={values.subscribe_message} onChange={(e) => updateValue("subscribe_message", e.target.value)}/>
              </div>
              <div className="edit-integration__form-row">
              <div className="edit-integration__label-container">
                <label className="edit-integration__label" htmlFor="terms_url">Terms & Conditions URL</label>
                <ToolTip
                  message={`Include protocol ("http://" or "https://") in URL or add path only ("/policies/terms") if page is hosted on your main website.`}
                  width="200px"  
                />
              </div>
                <input className="edit-integration__input input--regular" name="terms_url" type="text" placeholder="https://mysite.com/terms-and-conditions" value={values.terms_url} onChange={(e) => updateValue("terms_url", e.target.value)}/>
              </div>
              <div className="edit-integration__form-row">
                <div className="edit-integration__label-container">
                  <label className="edit-integration__label" htmlFor="privacy_url">Privacy Policy URL</label>
                  <ToolTip
                    message={`Include protocol ("http://" or "https://") in URL or add path only ("/policies/privacy") if page is hosted on your main website.`}
                    width="200px"  
                  />
                </div>
                <input className="edit-integration__input input--regular" name="privacy_url" type="text" placeholder="https://mysite.com/privacy-policy" value={values.privacy_url} onChange={(e) => updateValue("privacy_url", e.target.value)}/>
              </div>
            </>
          }
          {!integration && <Loading />}
          <div className="edit-integration__form-buttons">
            <button className="edit-integration__submit btn gradient" onClick={submit}>Save</button>
          </div>
        </div>
    </section>
  )
}
