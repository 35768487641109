import {useNavigate, useLocation, useParams} from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import axios from 'axios';
import {useState, useEffect, useRef, useContext} from 'react';
import { AuthContext } from '../contexts/AuthContext';
import {
  DatePicker, 
  TimePicker, 
  CloseButton, 
  titleCaseText, 
  EditMenu, 
  Loading, 
  SimplePicksDisplay, 
  SimpleSearchPreview,
  ConfirmationPopup
} from './utility/Utility';
import _ from 'lodash';

// export const GiveawaysTable = (props) => {
//   const {logout} = useContext(AuthContext);
//   const { giveaways } = props;
//   const navigate = useNavigate();
  
//   const createGiveaway = () => {
//     let today = new Date();
//     let arbitraryEnd = new Date();
//     arbitraryEnd.setDate(arbitraryEnd.getDate() + 7);
//     let todayDate = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;
//     let todayTime = `${today.getHours() > 12 ? (today.getHours() - 12 < 10 ? `0${today.getHours() - 12}` : today.getHours() - 12) : today.getHours() === 0 ? '12' : today.getHours()}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}:${today.getSeconds() > 9 ? today.getSeconds() : `0${today.getSeconds()}`}`

//     axios.post(
//       'https://api.amplifyloyalty.com/app/stores/giveaways',
//       {
//         name: `New Giveaway - ${todayDate} ${todayTime}`,
//       },
//       {
//         params: {
//           jwt: localStorage.getItem('_id')
//         }
//       }
//     ).then(res => {
//       if (res.data.name === 'BadRequestError') {
//         logout();
//       } else {
//         setTimeout(() => {
//           navigate(`/app/giveaways/${res.data._id}`);
//         }, 2500);
//       }
//     }).catch(err => {
//       console.log(err)
//       // responseHandler(err);
//     })
//   }

//   const duplicateGiveaway = giveaway => {
//     const button = document.querySelector('.giveaways-header__create-new');
//     button.disabled = true;
//     axios.post(
//       'https://api.amplifyloyalty.com/app/stores/giveaways/duplicate',
//       {
//         _id: giveaway._id,
//         name: `${giveaway.name} - Copy`
//       },
//       {
//         params: {
//           jwt: localStorage.getItem('_id')
//         }
//       }
//     ).then(res => {
//       if (res.data.name === 'BadRequestError') {
//         logout();
//       } else if (res.data._id) {
//         setTimeout(() => {
//           navigate(`/app/giveaways/${res.data._id}`);
//         }, 1000);
//       }
//     }).catch(err => {
//       console.log(err);
//       button.disabled = false;
//       // responseHandler(err);
//     })
//   }

//   const deleteGiveaway = giveaway => console.log(giveaway);

//   return (
//     <section className='giveaways-container centralize'>
//       <div className="giveaways-header">
//         <p>Create and schedule point giveaways to send to your customers.</p>
//         <button className="giveaways-header__create-new btn gradient" onClick={() => createGiveaway()}>NEW GIVEAWAY</button>
//       </div>
//       <div className="giveaways-table">
//         <div className="giveaways-table__header">
//           <div className="giveaways-table__heading">Giveaway</div>
//           <div className="giveaways-table__metrics">
//             <div className="giveaways-table__heading">Points Issued</div>
//             <div className="giveaways-table__heading">Recipients</div>
//             <div className="giveaways-table__heading">Orders</div>
//             <div className="giveaways-table__heading">Sales</div>
//             <div className="giveaways-table__heading"></div>
//           </div>
//         </div>
//         <div className="giveaways-table__body">
//           {giveaways.length > 0 &&
//             giveaways.map((giveaway, dataKey) => {
//               let execution_time = new Date(giveaway.execution_time);
//               let executionDate = giveaway.execution_time ? `${execution_time.getMonth()+1}/${execution_time.getDate()}/${execution_time.getFullYear()}` : "";
//               let executionTime = giveaway.execution_time ? `${execution_time.getHours() > 12 ? execution_time.getHours() % 12 : execution_time.getHours() === 0 ? 12 : execution_time.getHours()}:${execution_time.getMinutes() > 9 ? "" : "0"}${execution_time.getMinutes()} ${execution_time.getHours() < 12 ? 'AM' : 'PM'}` : "";
//               return (
//                 <div key={dataKey} className="giveaways-table__row">
//                   <div className="giveaways-table__cell giveaways-table__info">
//                     <div className="giveaways-table__title">
//                       <h3 className="giveaways-table__name"><a className="giveaways-table__name-link" href={`/app/giveaways/${giveaway._id}${giveaway.status === 'executed' ? '/reporting' : ''}`}>{giveaway.name}</a></h3>
//                     </div>
//                     <div className="giveaways-table__execution-info">{titleCaseText(giveaway.status)}{executionDate ? ` on ${executionDate} at ${executionTime}` : ''}</div>
//                   </div>
//                   <div className="giveaways-table__metric-data">
//                     <div className="giveaways-table__metric">
//                       {Intl.NumberFormat('en-US').format(giveaway.total_points)}
//                     </div>
//                     <div className="giveaways-table__metric">
//                       {Intl.NumberFormat('en-US').format(giveaway.recipients)}
//                     </div>
//                     <div className="giveaways-table__metric">
//                       {Intl.NumberFormat('en-US').format(giveaway.orders ? giveaway.orders : 0)}
//                     </div>
//                     <div className="giveaways-table__metric">
//                       {Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(giveaway.sales ? giveaway.sales : 0)}
//                     </div>
//                     <div className="giveaways-table__metric">
//                       <EditMenu className="promotions-table__edit" options={[
//                         {
//                           text: giveaway.status === 'executed' ? 'Analytics' : 'Edit',
//                           action: giveaway.status === 'executed' ? () => navigate('#') : () => navigate(`/app/giveaways/${giveaway._id}`)
//                         },
//                         {
//                           text: 'Duplicate',
//                           action: () => duplicateGiveaway(giveaway)
//                         },
//                         {
//                           text: 'Delete',
//                           action: () => deleteGiveaway(giveaway)
//                         }
//                       ]}/>
//                     </div>
//                   </div>
//                 </div>
//               )
//             })
//           }
//           {!giveaways && 
//             <Loading className="giveaways-table__loading" />
//           }
//           {giveaways.length === 0 && 
//             <div className="giveaways-table__empty">
//               <div className="giveaways-table__text-wrapper">No giveaways to show at this time.</div>
//             </div>
//           }
//         </div>
//       </div>
//     </section>
//   )
// }

export const GiveawaysHeader = (props) => {
  const {dynamicName} = props;
  const location = useLocation();
  const {id} = useParams();
  const navigate = useNavigate();

  const goBack = (e, link) => {
    if(e.currentTarget.classList.contains('update-promotion__breadcrumb--past')) {
      navigate(link);
    }
  }

  return (
    <section className="update-giveaway__header">
      <h1 className="update-promotion__heading">{`Giveaways > ${dynamicName}`}</h1>
      <div className="update-promotion__breadcrumbs">
        <div onClick={(e) => goBack(e, `/app/giveaways/${id}`)} className={`update-promotion__breadcrumb${!location.pathname.includes('rewards') && !location.pathname.includes('review') ? ' update-promotion__breadcrumb--active' : ' update-promotion__breadcrumb--past'}`}>
          <div className={`update-promotion__breadcrumb-number ${!location.pathname.includes('rewards') && !location.pathname.includes('review') ? ' update-promotion__breadcrumb-number--active' : ''}`}>
            1
          </div>
          <div className="update-promotion__breadcrumb-title">
            SEGMENT
          </div>
        </div>
        <div className="update-promotion__breadcrumb-trail"></div>
        <div onClick={(e) => goBack(e, `/app/giveaways/${id}/rewards`)} className={`update-promotion__breadcrumb${location.pathname.includes('rewards') ? ' update-promotion__breadcrumb--active' : location.pathname.includes('review') ? ' update-promotion__breadcrumb--past' : ''}`}>
          <div className={`update-promotion__breadcrumb-number ${location.pathname.includes('rewards') ? ' update-promotion__breadcrumb-number--active' : ''}`}>
            2
          </div>
          <div className="update-promotion__breadcrumb-title">
            REWARDS
          </div>
        </div>
        <div className="update-promotion__breadcrumb-trail"></div>
        <div className={`update-promotion__breadcrumb${location.pathname.includes('review') ? ' update-promotion__breadcrumb--active' : ''}`}>
        <div className={`update-promotion__breadcrumb-number ${location.pathname.includes('review') ? ' update-promotion__breadcrumb-number--active' : ''}`}>
            3
          </div>
          <div className="update-promotion__breadcrumb-title">
            REVIEW
          </div>
        </div>
      </div>
    </section>
  )
}

export const GiveawaysInfo = (props) => {
  const {logout} = useContext(AuthContext);
  const {giveaway, setGiveaway, dynamicName, setDynamicName} = props;
  const {id} = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState(giveaway.filters);
  const [errors, setErrors] = useState(false);
  const [segment, setSegment] = useState(0);
  const [prevFilters, setPrevFilters] = useState([]);
  const [fetchingSeg, setFetchingSeg] = useState(false);

  const getSegment = async () => {
    // something needs to stop this from firing when a new OR or AND is blank
    let emptyFilters;
    for await (let and of filters) {
      for await (let or of and) {
        if (or.filter_type === '') {
          emptyFilters = true; 
          break;
        }
      }
    }
    if (!emptyFilters)
      axios.post('https://api.amplifyloyalty.com/app/customers/segment', 
        {
          filters,
          store: giveaway.store
        },
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          setSegment(res.data);
          setPrevFilters(JSON.parse(JSON.stringify(filters)));
          setFetchingSeg(false);
        }
      }).catch(err => {
        console.log(err);
      })
    else setFetchingSeg(false);
  }

  useEffect(() => {
    const snapshot = JSON.parse(JSON.stringify(filters));
    setFetchingSeg(true);
    setTimeout(async () => {
      if (_.isEqual(snapshot, filters)) {
        if (!_.isEqual(filters,prevFilters) && !errors) {
          getSegment();
        } else setFetchingSeg(false)
      } else setFetchingSeg(false)
    }, 2000)
  }, [filters])

  const validateErrors = async () => {
    let isError = false;
    for await (let and of filters) {
      for await (let or of and) {
        if (or.errors) {
          isError = true;
          break;
        }
      }
    }
    if (isError) {
      setErrors(true);
      return true;
    }
    else {
      setErrors(false);
      return false;
    }
  }

  const save = async () => {
    const buttons = document.querySelectorAll(".update-giveaway__buttons-wrapper > *");
    // validate
    let newFilters = [...filters];
    for (let aI = 0; aI < newFilters.length; aI++) {
      newFilters[aI] = newFilters[aI].filter(or => or.filter_type !== '')
      if (newFilters[aI].length === 0) newFilters.splice(aI, 1);
    }
    setFilters(newFilters);
        
    let isError = await validateErrors();
    if (!isError) {
      buttons.forEach(btn => btn.disabled = true);
      axios.put('https://api.amplifyloyalty.com/app/stores/giveaways',
        {
          _id: id,
          name: dynamicName,
          filters: newFilters
        },
        {
          params: {
            jwt: localStorage.getItem('_id'),
            section: 'info'
          }
        }
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          setGiveaway(res.data);
          navigate(`/app/giveaways/${id}/rewards`);
          // responsehandler
        }
      }).catch(err => {
        console.log(err);
        // responsehandler
      }).finally(() => {
        buttons.forEach(btn => btn.disabled = false);
      })

    }
  }
  
  const addFilter = () => {
    setFilters([...filters, [{filter_type: ''}]]);
  }

  const addOrFilter = (i) => {
    const newFilters = [...filters];
    newFilters[i].push({filter_type: ''});
    setFilters(newFilters);
  }

  const setFilterType = (value, key, objI, filI) => {
    const newFilters = [...filters]
    let validatedValue = value;
    if (key === 'days'){
      if (validatedValue) {
        if (validatedValue && /[0-9]+/g.exec(validatedValue)) validatedValue = parseInt(/[0-9]+/g.exec(validatedValue)[0]);
        else validatedValue = 7;
      }
    }
    newFilters[filI][objI][key] = validatedValue;
    // remove errors
    if (newFilters[filI][objI].errors) {
      let daysError = newFilters[filI][objI].errors.filter(err => err.field === 'days');
      if (key === 'days_operator' && daysError.length > 0) {
        if (newFilters[filI][objI].errors.length > 1) {
          newFilters[filI][objI].errors = newFilters[filI][objI].errors.filter(err => err.field !== 'days');
        } else delete newFilters[filI][objI].errors;
      }
    }
    // validate
    if (key === 'filter_type' && value !== 'account') {
      newFilters[filI][objI].value_operator = '$gt'
      newFilters[filI][objI].value = 0;
      newFilters[filI][objI].days_operator = 'any'
    } else if (value === 'account') {
      newFilters[filI][objI].value = '1'
    } else if (key === 'days_operator' && value !== 'any') {
      newFilters[filI][objI].days = 7;
    } 
    setFilters(newFilters);
  }

  const removeFilter = (i, cI) => {
    const newFilters = [...filters];
    newFilters[i].splice(cI, 1);
    if (newFilters[i].length === 0) {
      newFilters.splice(i, 1);
    }
    setFilters(newFilters);
  }

  const setError = (message, field, i, cI) => {
    setErrors(true);
    const newFilters = [...filters];
    if (newFilters[i][cI].errors) {
      if (newFilters[i][cI].errors.filter(err => err.field === field).length === 0)
        newFilters[i][cI].errors.push({field, message});
    } else newFilters[i][cI].errors = [{field, message}];
    setFilters(newFilters);
  }
  const removeError = (i, cI, field) => {
    const newFilters = [...filters];
    let newErrors = newFilters[i][cI].errors.filter(err => err.field !== field);
    if (newErrors.length === 0) delete newFilters[i][cI].errors
    else newFilters[i][cI].errors = newErrors;
    setFilters(newFilters);
    validateErrors();

  }

  return (
    <section className="update-giveaway centralize">
      <div className="update-giveaway__options block-section">
        <div className="update-giveaway__info">
          <div className="update-giveaway__row">
            <div className="update-giveaway__label">Name</div>
            <input className="update-giveaway__field" defaultValue={dynamicName} name="name" onChange={(e) => setDynamicName(e.target.value)} />
          </div>
        </div>
        <div className="update-giveaway__row">
          {filters.length === 0 && 
            <div className="update-giveaway__add-filter" onClick={() => addFilter()}>+ Add filter</div>
          }
          {filters.length > 0 && 
            <div className="update-giveaway__filters">
              <div className="update-giveaway__filter-header">
                <div className="update-giveaway__label">Filters</div>
                <div className="update-giveaway__segment-container">
                  <div className="update-giveaway__label">Segment Size:</div>
                  {fetchingSeg && 
                    <Loading className="update-giveaway__segment-loading" />
                  }
                  {!fetchingSeg && 
                    <div className="update-giveaway__segment-size">{Intl.NumberFormat('en-US').format(segment)}</div>
                  }
                </div>
              </div>
              <div className="update-giveaway__filter-options">
                {filters.map((filterSet, i) => {
                  return (
                    <div key={i} className="update-giveaway__and-filter-container">
                      <div className="update-giveaway__filter-container">
                        {filterSet.map((filter, cI) => {
                          return (
                            <div key={cI} className="update-giveaway__filter">
                              <div className="update-giveaway__filter-row">
                                <div className="update-giveaway__filter-type">
                                  <span className="update-giveaway__filter-intro">Customer has</span>
                                  <select name="filter_type" className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'filter_type', cI, i)} value={filter.filter_type}>
                                    <option value="" disabled></option>
                                    <option value="spend">spent</option>
                                    <option value="orders">ordered</option>
                                    <option value="account">created account</option>
                                  </select>
                                </div>
                                <i className="fa-solid fa-trash update-giveaway__delete-filter" onClick={() => removeFilter(i, cI)}></i>
                              </div>
                              {filter.filter_type === 'spend' && 
                                <div className="update-giveaway__dynamic-filter-options">
                                  <select name="value_operator" className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'value_operator', cI, i)} value={filter.value_operator}>
                                    <option value="$gt">more than</option>
                                    <option value="$lt">less than</option>
                                    <option value="$eq">exactly</option>
                                    {/* add a between dates option */}
                                  </select>
                                  <div className="update-giveaway__input-with-prefix">
                                    {filter.errors && filter.errors.filter(err => err.field === 'value').length > 0 &&
                                      <div className="update-giveaway__error">{filter.errors.filter(err => err.field === 'value')[0].message}</div>
                                    }
                                    <div className="update-giveaway__input-prefix-wrapper">
                                      <span className="update-giveaway__input-prefix">$</span>
                                    </div>
                                    <input type="number" name="value" value={filter.value} 
                                      onKeyUp={() => {
                                        if (filter.value !== 0 && !filter.value) setError("Can't be null", 'value', i, cI);
                                        else if (filter.errors) removeError(i, cI, 'value');
                                      }} 
                                      className='update-giveaway__filter-option update-giveaway__filter-option--input update-giveaway__filter-option--prefixed' 
                                      onChange={(e) => setFilterType(parseFloat(e.target.value), 'value', cI, i)}
                                    />
                                  </div>
                                  <select name="days_operator" value={filter.days_operator} className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'days_operator', cI, i)}>
                                    <option value="$gt">in the last</option>
                                    <option value="$lt">at least</option>
                                    <option value="$eq">exactly</option>
                                    <option value="any">of all time</option>
                                  </select>
                                  {filter.days_operator !== 'any' &&
                                    <div className="update-giveaway__conditional-fields">
                                      {filter.errors && filter.errors.filter(err => err.field === 'days').length > 0 &&
                                        <div className="update-giveaway__error">{filter.errors.filter(err => err.field === 'days')[0].message}</div>
                                      }
                                      <input type="number" name="days" value={filter.days} 
                                        onKeyUp={() => {
                                          if (!filter.days) setError("Must be a positive number", 'days', i, cI);
                                          else if (filter.errors) removeError(i, cI, 'days');
                                        }} 
                                        className='update-giveaway__filter-option update-giveaway__filter-option--input' 
                                        onChange={(e) => setFilterType(parseInt(e.target.value), 'days', cI, i)}
                                      />
                                      days{(filter.days_operator && filter.days_operator !== '$gt') ? ' ago' : ''}
                                    </div>
                                  }
                                </div>
                              }
                              {filter.filter_type === 'orders' && 
                                <div className="update-giveaway__dynamic-filter-options">
                                  <select name="value_operator" value={filter.value_operator} className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'value_operator', cI, i)}>
                                    <option value="$gt">more than</option>
                                    <option value="$lt">less than</option>
                                    <option value="$eq">exactly</option>
                                    {/* add a between dates option */}
                                  </select>
                                  {filter.value_operator !== 'any' &&
                                  <div className="update-giveaway__conditional-fields">
                                    {filter.errors && filter.errors.filter(err => err.field === 'value').length > 0 &&
                                      <div className="update-giveaway__error">{filter.errors.filter(err => err.field === 'value')[0].message}</div>
                                    }
                                    <input type="number" name="value" value={filter.value} 
                                      onKeyUp={() => {
                                        if (filter.value !== 0 && !filter.value) setError("Can't be null", 'value', i, cI);
                                        else if (filter.errors) removeError(i, cI, 'value');
                                      }} 
                                      className='update-giveaway__filter-option update-giveaway__filter-option--input' 
                                      onChange={(e) => setFilterType(e.target.value, 'value', cI, i)}
                                    />
                                    <span className="update-giveaway__floating-text">times</span>
                                    <select name="days_operator" value={filter.days_operator} className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'days_operator', cI, i)}>
                                      <option value="$gt">in the last</option>
                                      <option value="$lt">at least</option>
                                      <option value="$eq">exactly</option>
                                      <option value="any">of all time</option>
                                    </select>
                                    {filter.days_operator !== 'any' &&
                                      <div className="update-giveaway__conditional-fields">
                                        {filter.errors && filter.errors.filter(err => err.field === 'days').length > 0 &&
                                          <div className="update-giveaway__error">{filter.errors.filter(err => err.field === 'days')[0].message}</div>
                                        }
                                        <input type="number" name="days" value={filter.days} 
                                          onKeyUp={() => {
                                            if (!filter.days) setError("Must be a positive number", 'days', i, cI);
                                            else if (filter.errors) removeError(i, cI, 'days');
                                          }} 
                                          className='update-giveaway__filter-option update-giveaway__filter-option--input' 
                                          onChange={(e) => setFilterType(e.target.value, 'days', cI, i)}
                                        />
                                        days{(filter.days_operator !== '$gt') ? ' ago' : ''}
                                      </div>
                                    }
                                  </div>
                                  }
                                </div>
                              }
                              {filters[i][cI].filter_type === 'account' && 
                                <div className="update-giveaway__dynamic-filter-options update-giveaway__filter-row update-giveaway__filter-row--regular">
                                  <select name="value" className="update-giveaway__filter-option" onChange={(e) => setFilterType(e.target.value, 'value', cI, i)}>
                                    <option value="1">is true</option>
                                    <option value="0">is false</option>
                                  </select>
                                  {/* {filters[i][cI].value === 'true' &&
                                    <div className="update-giveaway__filter-row update-giveaway__filter-row--regular">
                                      <select name="days_operator" defaultValue=">" className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'days_operator', cI, i)}>
                                        
                                        <option value=">">at least</option>
                                        <option value="<">in the last</option>
                                        <option value="=">exactly</option>
                                      </select>
                                      <input type="number" name="days" className='update-giveaway__filter-option' onChange={(e) => setFilterType(e.target.value, 'days', cI, i)}/>
                                    days ago
                                    </div>
                                  } */}
                                </div>
                              }
                              {filterSet.length > cI+1 &&
                                <div className="update-giveaway__or-divider">
                                  <div className="update-giveaway__or-divider-line"></div>
                                  <div className="update-giveaway__or-badge"><span className="update-giveaway__or-badge-text">OR</span></div>
                                  <div className="update-giveaway__or-divider-line"></div>
                                </div>
                              }
                            </div>
                          )
                        })}
                        <div className="update-giveaway__add-or-filter" onClick={() => addOrFilter(i)}><span className="update-giveaway__or-plus-icon">+</span> OR</div>
                      </div>
                      {filters.length > i+1 &&
                        <div className="update-giveaway__and-badge"><span className="update-giveaway__and-badge-text">AND</span></div>
                      }
                    </div>
                  )
                })}
                <div className="update-giveaway__add-and-filter" onClick={() => addFilter()}><span className="update-giveaway__and-plus-icon">+</span> AND</div>
              </div>
            </div>
          }
          <div className="update-giveaway__buttons-wrapper">
            {errors && 
              <div className="update-giveaway__is-error">Fix all errors before saving.</div>
            }
            <button className="update-giveaway__continue-button btn gradient" onClick={save}>Continue</button>
          </div>
        </div>
      </div>
    </section>
  )
}

const GiveawaysRewardsVariableStats = (props) => {
  const {rewardType, giveaway} = props;
  const [stats, setStats] = useState({});
  
  const keyMap = {
    'currency_off': '$ in Discounts',
    'percent_off': 'Avg % Discount',
    'free_shipping': 'Free Shipping',
    'points': 'Points Given'
  }
  
  useEffect(() => {
    const giveawayBreakdown = giveaway.rewards.breakdown;
    if (rewardType === 'points') {
      setStats({points: giveawayBreakdown.reduce((acc, obj) => {
        return acc + ((!obj.value || !obj.segment) ? 0 : obj.value * obj.segment);
      }, 0).toLocaleString("en-US")})
    } else {
      let newStats = {};
      for (let breakdown of giveawayBreakdown) {
        switch (breakdown.coupon.reward_type) {
          case "percent_off":
            if (newStats.percent_off) {
              if (newStats.percent_off[`${breakdown.coupon.value}`]) {
                newStats.percent_off[`${breakdown.coupon.value}`] += breakdown.segment;
              } else {
                newStats.percent_off[`${breakdown.coupon.value}`] = breakdown.segment;
              }
            } else {
              newStats.percent_off = {}
              newStats.percent_off[`${breakdown.coupon.value}`] = breakdown.segment;
            }
            break;
          case "currency_off":
            newStats.currency_off = newStats.currency_off ? 
              newStats.currency_off + (breakdown.coupon.value * breakdown.segment) :
              breakdown.coupon.value * breakdown.segment
            break;
          case "free_shipping":
            break;
          default:
            console.log(`Could not find ${breakdown.reward_type} type.`);
        }
      } 
      setStats(newStats);
    }
  }, [giveaway]) 

  const renderAvgPct = () => {
    const { totalContributions, totalOccurrences } = Object.entries(stats.percent_off).reduce((acc, [key, value]) => {
      const percentage = parseFloat(key);
      const count = value;
      acc.totalContributions += percentage * count;
      acc.totalOccurrences += count;
      return acc;
    }, { totalContributions: 0, totalOccurrences: 0 });
    let total = totalContributions / totalOccurrences;
    return `${total ? (total).toFixed(2) : 0}%`;
  }

  return (
    <>
      {!giveaway.rewards.breakdown.length && 
        <div className="giveaway-segment-header__stat-card">
          <div className="giveaway-segment-header__stat">0</div>
          <div className="giveaway-segment-header__stat-title">{giveaway.rewards.reward_type === 'coupon' ? "$ in Discounts" : "Points Given"}</div>          
        </div>
      }
      {giveaway.rewards.breakdown.length > 0 && 
        Object.keys(stats).map(key => {
          // return false if free_shipping
          if (key === "free_shipping") return false;
          return (
            <div key={key} className="giveaway-segment-header__stat-card">
              <div className="giveaway-segment-header__stat">
                {
                  key === "points" ? 
                    stats[key]
                  : key === 'currency_off' ? 
                    Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(stats[key]) 
                  : renderAvgPct()
                }
              </div>
              <div className="giveaway-segment-header__stat-title">{keyMap[key]}</div> 
            </div>
          )
        })
      }
    </>
  )
}

const GiveawaysRewardsHeader = (props) => {
  const {segment, rewardType, giveaway} = props;
  return (
    <div className="giveaway-segment-header block-section">
      {segment !== false && 
        <div className="giveaway-segment-header__stats">
          <div className="giveaway-segment-header__stat-card">
            <div className="giveaway-segment-header__stat">{segment.toLocaleString("en-US")}</div>
            <div className="giveaway-segment-header__stat-title">Segment Total</div>
          </div>
          <div className="giveaway-segment-header__stat-card">
            <div className="giveaway-segment-header__stat">
              {giveaway.rewards.breakdown.reduce((acc, obj) => {
                  return acc + (!obj.segment ? 0 : obj.segment);
                }, 0).toLocaleString('en-US')}
              </div>
            <div className="giveaway-segment-header__stat-title">Recipients</div>
          </div>
         <GiveawaysRewardsVariableStats giveaway={giveaway} rewardType={rewardType} />
        </div>
      }
    </div>
  )
}

export const GiveawaysRewards = (props) => {
  const {logout} = useContext(AuthContext);
  const { giveaway, setGiveaway, store } = props;
  const {id} = useParams();
  const navigate = useNavigate();
  const [segment, setSegment] = useState(false);
  const [error, setError] = useState(false);
  const [coupIndex, setCoupIndex] = useState(false);
  const [creatingCoupon, setCreatingCoupon] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [switchSelection, setSwitchSelection] = useState(false);

  useEffect(() => {
    let giveawayWithKeys = giveaway;
    giveawayWithKeys.rewards.breakdown = giveawayWithKeys.rewards.breakdown.map(rew => {
      return {...rew, key: Math.random()*1000000};
    })
    setGiveaway(giveawayWithKeys);
    axios.post('https://api.amplifyloyalty.com/app/customers/segment',
      {
        _id: giveaway._id,
        store: giveaway.store,
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          setSegment(res.data);
        }
      }).catch(err => {
        console.log(err);
    });
    
  }, []);

  const addGiveaway = (i) => {
    if (giveaway.rewards.reward_type === 'points') setGiveaway({...giveaway, rewards: {...giveaway.rewards, breakdown: [...giveaway.rewards.breakdown, {key: Math.random()*1000000, points: '', segment: ''}]}});
    if (giveaway.rewards.reward_type === 'coupon') {
      setCoupIndex(i);
      setCreatingCoupon(true);
      if (Object.keys(giveaway.rewards.breakdown.slice(-1)).length > 1) setGiveaway({...giveaway, rewards: {...giveaway.rewards, breakdown: [...giveaway.rewards.breakdown, {key: Math.random()*1000000, segment: ''}]}});
    }
  }

  // const changeType = (v) => {
  //   setGiveaway({...giveaway, rewards: {...giveaway.rewards, breakdown_type: v}});
  //   maxRecipientsValidator();
  //   // Update giveaway points based on % * segment size
  // }

  const changeReward = (v) => {
    setGiveaway({...giveaway, rewards: {...giveaway.rewards, reward_type: v, breakdown: []}});
    setSwitchSelection(false);
  }

  const updateGiveaway = (v, i, f) => {
    // validate
    const newGiveawayPoints = [...giveaway.rewards.breakdown];
    const validatedValue = /[0-9]+/g.exec(v);
    if (validatedValue) {
      newGiveawayPoints[i][f] = parseInt(/[0-9]+/g.exec(v)[0]);
    } else {
      newGiveawayPoints[i][f] = '';
    }
    setGiveaway({...giveaway, rewards: {...giveaway.rewards, breakdown: newGiveawayPoints}});
  }

  const deleteGiveaway = (i) => {
    // check for consent first
    let newGiveawayPoints = [...giveaway.rewards.breakdown];
    newGiveawayPoints.splice(i, 1);
    setGiveaway({...giveaway, rewards: {...giveaway.rewards, breakdown: newGiveawayPoints}});
    setDeleteConfirmation(false);
  }
  
  const maxRecipientsValidator = () => {
    let buttons = document.querySelectorAll('.update-giveaway__buttons-wrapper > button');;
    let recipients = giveaway.rewards.breakdown.reduce((acc, obj) => {
      return acc + (!obj.segment ? 0 : obj.segment)
    }, 0);
    if (recipients > segment) {
      setError('Recipients larger than segment size.');
      buttons.forEach(btn => btn.disabled = true);
      return true;
    } else {
      buttons.forEach(btn => btn.disabled = false);
      setError(false);
      return false;
    }
  }

  const validate = () => {
    // check rewards.length > 0
    if (giveaway.rewards.breakdown.length === 0) {
      setError('Must have at least one reward.');
      return true;
    } else if (giveaway.rewards.reward_type === 'coupon') {
      for (let reward of giveaway.rewards.breakdown) {
        if (!parseInt(reward.segment) || reward.segment < 1) {
          setError('Recipients must be at least 1 for each reward.');
          return true;
        }
      }
    } else if (giveaway.rewards.reward_type === 'points') {
      for (let reward of giveaway.rewards.breakdown) {
        if (!parseInt(reward.segment) || reward.segment < 1 || !parseInt(reward.value) || reward.value < 1) {
          setError('Points and recipients must be at least 1 for each reward.');
          return true;
        }
      }
    }
    const recipients = maxRecipientsValidator();
    if (recipients) return true;
    setError(false);
    return false;
    // if points, check points && segment > 0
  }

  const save = async () => {
    const newError = await validate();
    if (!error && !newError) {
      let buttons = document.querySelectorAll('.update-giveaway__buttons-wrapper > *');
      buttons.forEach(button => button.disabled = true);
      try {
        const res = await axios.put('https://api.amplifyloyalty.com/app/stores/giveaways',
          {
            _id: id,
            rewards: giveaway.rewards
          },
          {
            params: {
              jwt: localStorage.getItem('_id'),
              section: 'points'
            }
          }
        );
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          setGiveaway(res.data);
          setError(false);
          navigate(`/app/giveaways/${id}/review`);
        }
      } catch (err) {
        setError('Error occurred, please try again later.');
        console.log(err);
      } finally {
        buttons.forEach(button => button.disabled = false);
      }
      if (!error) {
        return false;
      } else return true;
    } else return true;
  }


  return (
    <section className="update-giveaway-points centralize">
      {creatingCoupon && 
        <GiveawaysCouponForm 
          coupon={creatingCoupon} 
          setCreatingCoupon={setCreatingCoupon} 
          giveaway={giveaway} 
          setGiveaway={setGiveaway} 
          i={coupIndex}
        />
      }
      {deleteConfirmation !== false && 
        <ConfirmationPopup 
          headline='Delete Coupon Confirmation'
          description="Once you delete this coupon it will be unrecoverable."
          action={() => deleteGiveaway(deleteConfirmation)}
          setActive={setDeleteConfirmation}
        />
      }
      {switchSelection !== false && 
        <ConfirmationPopup 
          headline='Switch Giveaway Type'
          description="If you switch now, you will lose all of the rewards you have created so far."
          action={() => changeReward(switchSelection)}
          setActive={setSwitchSelection}
        />
      }
      <GiveawaysRewardsHeader segment={segment} giveaway={giveaway} rewardType={giveaway.rewards.reward_type} />
      <div className="update-giveaway-points__wrapper block-section">
        <div className="update-giveaway-points__header-row update-giveaway-points__row">
          <div className="update-giveaway-points__type">
            <span className="update-giveaway__floating-text">Select giveaway winners and reward them with</span>
            <select className="update-giveaway__filter-option" 
              name="reward" 
              defaultValue={giveaway.rewards.reward_type} 
              onChange={(e) => {
                if (giveaway.rewards.breakdown.length > 0) setSwitchSelection(e.target.value)
                else changeReward(e.target.value)
              }}
            >
              <option value="points">points</option>
              <option value="coupon">coupon</option>
            </select>
          </div>
        </div>
        {giveaway.rewards.reward_type === 'points' && 
          <>
            {giveaway.rewards.breakdown.map((reward, i) => { 
              return (
                <div key={reward.key} className="update-giveaway-points__points-row">
                  <div className="update-giveaway-points__options-wrapper">
                    <span className="update-giveaway__floating-text">Give</span>
                    <input className="update-giveaway__filter-option update-giveaway__filter-option--input" type="number" name="value" value={reward.value} onChange={(e) => updateGiveaway(parseFloat(e.target.value), i, 'value')}/>
                    <span className="update-giveaway__floating-text">points to</span>
                    <input className="update-giveaway__filter-option update-giveaway__filter-option--input" type="number" name="segment" value={reward.segment} 
                      onChange={(e) => {
                        updateGiveaway(parseFloat(e.target.value), i, 'segment');
                        maxRecipientsValidator();
                      }}/>
                    <span className="update-giveaway__floating-text">{giveaway.rewards.breakdown_type === '%' ? "% of " : ""}recipients.</span>
                  </div>
                  <i className="fa-solid fa-trash update-giveaway__delete-filter" onClick={() => deleteGiveaway(i)}></i>
                </div>
              )
            })}
            <div className="update-giveaway-points__add-points" onClick={addGiveaway}>+ More points</div>
          </>
        }
        {giveaway.rewards.reward_type === 'coupon' && 
          <>
            {giveaway.rewards.breakdown.map((reward, i) => { 
              if (reward.coupon) {
                return (
                  <div key={reward.key} className="update-giveaway-points__points-row">
                    <div className="update-giveaway-points__options-wrapper">
                      <span className="update-giveaway__floating-text">Give</span>
                      <div className="update-giveaway__filter-option update-giveaway__filter-option--coupon" onClick={() => {
                        setCoupIndex(i);
                        setCreatingCoupon({...reward.coupon});
                      }}>
                        <div className="update-giveaway__filter-option--text">{reward.coupon.name}</div>
                      </div>
                      <span className="update-giveaway__floating-text">coupon to</span>
                      <input className="update-giveaway__filter-option update-giveaway__filter-option--input" type="number" name="segment" value={reward.segment} 
                        onChange={(e) => {
                          updateGiveaway(parseFloat(e.target.value), i, 'segment');
                          maxRecipientsValidator();
                        }}/>
                      <span className="update-giveaway__floating-text">recipients.</span>
                    </div>
                    <i className="fa-solid fa-trash update-giveaway__delete-filter" onClick={() => setDeleteConfirmation(i)}></i>
                  </div>
                )
              } else return false;
            })}
            <div className="update-giveaway-points__add-points" onClick={() => addGiveaway(giveaway.rewards.breakdown.length)}>+ Add coupon</div>
          </>
        }
        <div className="update-giveaway__buttons-wrapper">
          {error && 
            <div className="update-giveaway-points__error">{error}</div>
          }
          <button className="update-giveaway__save-button btn" onClick={() => navigate(`/app/giveaways/${id}`)}>Back</button>
          <button className="update-giveaway__continue-button btn gradient" onClick={save}>Continue</button>
        </div>
      </div>
    </section>
  )
}

export const GiveawaysCouponForm = (props) => {
  const {coupon, setCreatingCoupon, giveaway, setGiveaway, i, disabled} = props;
  const [valueSign, setValueSign] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formValues, setFormValues] = useState(typeof coupon === 'object' ? coupon : {
    name: '',
    reward_type: 'currency_off',
    description: '',
    expires: false,
    days_available: 7,
    minimum: false,
    minimum_requirement: 0,
    value: 0,
    usage_limit: true,
    usage_limit_value: 1,
    shipping_threshold: false,
    shipping_threshold_value: 0,
    items: {
      restriction_type: 'all_orders',
      ids: []
    }
  });

  useEffect(() => {
    // Show extra fields unless Free Shipping type
    switch (formValues.reward_type) {
      case "currency_off":
        setValueSign("$");
        break;
      case "percent_off":
        setValueSign("%");
        break;
      case "free_shipping":
        setValueSign(false);
        break;
      default: 
        setValueSign("$");
    }

  }, [formValues.reward_type]);

  const validate = async () => {
    let errs = [];
    let nameRe = /[\w\d]+/g;
    if (!nameRe.exec(formValues.name)) {
      errs.push({field: 'name', message: 'Name must contain numbers or letters.'});
    }
    if (formValues.reward_type !== 'free_shipping' && formValues.value <= 0) {
      errs.push({field: 'value', message: 'Value must be greater than 0.'});
    }
    if (formValues.items.restriction_type !== 'all_orders' && formValues.items.ids.length === 0) {
      errs.push({
        field: 'restriction_type', 
        message: `You must select at least one ${formValues.items.restriction_type === 'collections' ? 'collection' : 'product'}.`
      });
    }
    if (formValues.usage_limit && (formValues.usage_limit_value <= 0 || !parseInt(formValues.usage_limit_value))) {
      errs.push({field: 'usage_limit_value', message: 'Must be a positive whole number.'});
    }
    if (formValues.expires && (formValues.days_available <= 0 || !parseInt(formValues.days_available))) {
      errs.push({field: 'expires', message: 'Must be a positive whole number.'});
    }
    if (formValues.minimum && (formValues.minimum_requirement <= 0 || !parseFloat(formValues.minimum_requirement))) {
      errs.push({field: 'minimum', message: 'Must be a positive number.'});
    }
    if (formValues.shipping_threshold && (formValues.shipping_threshold_value <= 0 || !parseFloat(formValues.shipping_threshold_value))) {
      errs.push({field: 'shipping_threshold', message: 'Must be a positive number.'});
    }
    return errs;
  }

  const submit = async () => {
    // validate formValues
    let errs = await validate();
    if (errs.length === 0) {
      let newGiveaway = {...giveaway};
      if (typeof coupon !== 'object') newGiveaway.rewards.breakdown.push({segment: '', coupon: formValues});
      else newGiveaway.rewards.breakdown[i].coupon = formValues;
      setGiveaway(newGiveaway);
      setCreatingCoupon(false);
    } else {
      console.log({errs});
      setErrors(errs);
    }
  }

  return (
    <div className="flex-popup-overlay">
      {searchPreview && 
        <SimpleSearchPreview 
          close={setSearchPreview}
          heading={formValues.items.restriction_type === 'collections' ? 'Add collections' : 'Add products'}
          formValues={[formValues,setFormValues]}
          endpoint={formValues.items.restriction_type === 'collections' ? 'collections' : 'products'}
        />
      }
      {!searchPreview && 
        <section className="redemption-form-container">
          <div className="redemption-form">
            <div className="redemption-form__heading">
              <h3 className='redemption-form__title'>Coupon Reward</h3>
              <div className="redemption-form__editing">
                {/* {isEditing && <div className="redemption-form__button"><i className="fa-solid fa-trash redemption-form__delete" onClick={() => deleteRedemption()}></i></div>} */}
              </div>
            </div>
            <div className="redemption-form__fields">
              <div className="redemption-form__field">
                <label htmlFor="name">Name</label>
                <div className="redemption-form__input-container">
                  <input className="redemption-form__input" disabled={disabled} name="name" value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value })}/>
                </div>
                {errors && errors.filter(e => e.field === 'name').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'name')[0].message}</div>
                }
              </div>
              <div className="redemption-form__field">
                <label htmlFor="type">Type</label>
                <div className="redemption-form__input-container">
                  <select name="reward_type" disabled={disabled} className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.reward_type} onChange={(e) => setFormValues({...formValues, reward_type: e.target.value, items: { restriction_type: "all_orders", ids: []}})}>
                    <option value="currency_off">Amount off</option>
                    <option value="percent_off">Percent off</option>
                    <option value="free_shipping">Free Shipping</option>
                  </select>
                </div>
              </div>
              {valueSign && 
                <div className="redemption-form__extra-fields">
                  <div className="redemption-form__field">
                    <label htmlFor="value">Value</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">{valueSign}</div>
                      <input className="redemption-form__input redemption-form__input--value" disabled={disabled} name="value" type="number" value={formValues.value} onChange={(e) => setFormValues({...formValues, value: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'value').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'value')[0].message}</div>
                    }
                  </div>
                  {/* <FormError className="vip-form__error-message" inputName="value" /> */}
                </div>
              }
              {formValues.reward_type !== 'free_shipping' &&
                <div className="redemption-form__field">
                  <label htmlFor="restriction_type">Applies to</label>
                  <div className="redemption-form__input-container">
                    <select name="restriction_type" disabled={disabled} className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.items.restriction_type} onChange={(e) => setFormValues({...formValues, items: { restriction_type: e.target.value, ids: []}})}>
                      <option value="all_orders">All orders</option>
                      <option value="collections">Specific collections</option>
                      <option value="products">Specific products</option>
                    </select>
                  </div>
                  {errors && errors.filter(e => e.field === 'restriction_type').length > 0 && 
                    <div className="redemption-form__error">{errors.filter(e => e.field === 'restriction_type')[0].message}</div>
                  }
                </div>
              }
              {formValues.items.restriction_type !== 'all_orders' && 
                <div className="extra-fields">
                  {!disabled && <div className="vip-form__add-bonus" onClick={() => setSearchPreview(true)}>+ {formValues.items.restriction_type === 'collections' ? "Collection" : "Products"}</div>}
                  <SimplePicksDisplay formValues={formValues} setFormValues={setFormValues} disabled={disabled} />
                </div>
              }
              {formValues.reward_type === 'free_shipping' &&
                <>
                  <div className="redemption-form__field redemption-form__field--left">
                    <input className="redemption-form__input redemption-form__input--small" disabled={disabled} type="checkbox" name="shipping_threshold" value={formValues.shipping_threshold} checked={formValues.shipping_threshold} onChange={(e) => setFormValues({...formValues, shipping_threshold: e.target.checked})}/>
                    <label className="redemption-form__label--small" htmlFor="shipping_threshold">Max shipping value</label>
                  </div>
                  {formValues.shipping_threshold && 
                    <div className="extra-fields">
                      <div className="redemption-form__field">
                        <label className='redemption-form__sub-label' htmlFor="shipping_threshold_value">Up to</label>
                        <div className="redemption-form__input-container">
                          <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                          <input disabled={disabled} className="redemption-form__input redemption-form__input--value" name="shipping_threshold_value" type="number" value={formValues.shipping_threshold_value} onChange={(e) => setFormValues({...formValues, shipping_threshold_value: parseFloat(e.target.value)})}/>
                        </div>
                        {errors && errors.filter(e => e.field === 'shipping_threshold').length > 0 && 
                          <div className="redemption-form__error">{errors.filter(e => e.field === 'shipping_threshold')[0].message}</div>
                        }
                      </div>
                      <div className="error-message">
                      </div>
                    </div>
                  }
                </>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input disabled={disabled} className="redemption-form__input redemption-form__input--small" type="checkbox" name="usage_limit" value={formValues.usage_limit} checked={formValues.usage_limit} onChange={(e) => setFormValues({...formValues, usage_limit: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="usage_limit">Usage limit</label>
              </div>
              {formValues.usage_limit && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="usage_limit_value">Can be used</label>
                    <div className="redemption-form__input-container">
                      <input disabled={disabled} className="redemption-form__input redemption-form__input--points" name="usage_limit_value" type="number" value={formValues.usage_limit_value} onChange={(e) => setFormValues({...formValues, usage_limit_value: parseInt(e.target.value)})} />
                      <div className="redemption-form__input-identifier">times</div>
                    </div>
                    {errors && errors.filter(e => e.field === 'usage_limit_value').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'usage_limit_value')[0].message}</div>
                    }
                  </div>
                </div>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input disabled={disabled} className="redemption-form__input redemption-form__input--small" type="checkbox" name="expires" value={formValues.expires} checked={formValues.expires} onChange={(e) => setFormValues({...formValues, expires: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="expires">Set expiration</label>
              </div>
              {formValues.expires && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="days_available">Expires after</label>
                    <div className="redemption-form__input-container">
                      <input disabled={disabled} className="redemption-form__input redemption-form__input--points" name="days_available" type="number" value={formValues.days_available} onChange={(e) => setFormValues({...formValues, days_available: parseInt(e.target.value)})}/>
                      <div className="redemption-form__input-identifier">days</div>
                    </div>
                    {errors && errors.filter(e => e.field === 'expires').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'expires')[0].message}</div>
                    }
                  </div>
                </div>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input disabled={disabled} className="redemption-form__input redemption-form__input--small" type="checkbox" name="minimum" value={formValues.minimum} checked={formValues.minimum} onChange={(e) => setFormValues({...formValues, minimum: e.target.checked })}/>
                <label className="redemption-form__label--small" htmlFor="minimum">Minimum order value</label>
              </div>
              {formValues.minimum &&
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="minimum_value">Subtotal at least</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                      <input disabled={disabled} className="redemption-form__input redemption-form__input--value" name="minimum_requirement" type="number" value={formValues.minimum_requirement} onChange={(e) => setFormValues({...formValues, minimum_requirement: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'minimum').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'minimum')[0].message}</div>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="redemption-form__field redemption-form__button-container">
              <button className="redemption-form__cancel-button btn" type="button" onClick={() => {setCreatingCoupon(false)}}>Cancel</button>
              {!disabled && 
                <button className="redemption-form__submit-button btn gradient" type="submit" onClick={submit}>Save</button>
              }
            </div>
          </div>
        </section>
      }
    </div>
  )
}

export const FormikGiveawaysReview = (props) => {
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const {giveaway} = props;
  const {id} = useParams();
  const [sendType, setSendType] = useState('schedule');
  const [submitting, setSubmitting] = useState(false);
  const [date, setDate] = useState(new Date());
  const [segment, setSegment] = useState(false);

  useEffect(() => {
    axios.post('https://api.amplifyloyalty.com/app/customers/segment',
      {
        _id: giveaway._id,
        store: giveaway.store,
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          setSegment(res.data);
        }
      }).catch(err => {
        console.log(err);
    });
  }, []);

  const launch = () => {
    axios.post(
      'https://api.amplifyloyalty.com/app/stores/giveaways/schedule',
      {...giveaway, type: 'execute'},
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        navigate('/app/giveaways');
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const keyConverter = {
    $gt: 'more than',
    $lt: 'less than',
    spend: 'spent',
    account: 'created account',
    orders: 'ordered',
    any: 'of all time'
  }

  return (
    <section className="update-giveaway-review centralize">
      <div className="update-giveaway-review__breakdowns block-section">
        <div className="update-promotion-review__title">Review to Schedule</div>
        <div className="update-giveaway-review__point-breakdown">
          <div className="update-giveaway-review__breakdown-header">
            <div className="update-promotion-review__item-icon-wrapper">
              <div className="update-promotion-review__item-icon">i</div>
            </div>
            <div className="update-giveaway-review__header-right">
              <div className="update-giveaway-review__section-info">
                <h3 className="update-giveaway-review__sub-header">Filters</h3>
                {giveaway.filters.length === 0 && 
                  <div>Entire customer base selected as segment.</div>
                }
                {giveaway.filters.length > 0 && giveaway.filters.map((orArr, i) => { 
                  
                  return (
                    <div className="update-giveaway-review__and-filters">
                      <div className="update-giveaway-review__or-filters">
                      {orArr.map((filter, oI) => 
                        <span className="update-giveaway-review__or-filter">
                          Customer has {keyConverter[filter.filter_type]}
                          {` ${keyConverter[filter.value_operator]}`}
                          {filter.filter_type !== 'account' && ` ${filter.filter_type === 'spend' ? '$' : ''}${filter.value}${filter.filter_type === 'orders' ? ' times' : ''}`}
                          {oI+1 < orArr.length && <span className="update-giveaway-review__or-separator"> OR</span>}<br />
                        </span>
                      )}
                      </div>
                      {i+1 < giveaway.filters.length &&
                        <div className="update-giveaway-review__and-separator">
                          AND
                        </div>
                      }
                    </div>
                  
                  )
                })}
              </div>
              <a href={`/app/giveaways/${id}`}><button className="btn">Edit</button></a>
            </div>
          </div>
        </div>
        <div className="update-giveaway-review__point-breakdown">
          <div className="update-giveaway-review__breakdown-header">
            <div className="update-promotion-review__item-icon-wrapper">
              <div className="update-promotion-review__item-icon">i</div>
            </div>
            <div className="update-giveaway-review__header-right">
              <div className="update-giveaway-review__section-info">
                <h3 className="update-giveaway-review__sub-header">Points</h3>
                {giveaway.rewards.breakdown.map(reward => {
                  return (
                    <div className="update-giveaway-review__point">
                      <strong>{reward.segment}</strong> customers get <strong>{giveaway.rewards.breakdown_type === 'points' ? reward.value : reward.coupon.name}</strong> {giveaway.rewards.breakdown_type === 'points' ? 'points' : 'coupon'}
                    </div>
                  )
                })}
              </div>
              <a href={`/app/giveaways/${id}/rewards`}><button className="btn">Edit</button></a>
            </div>
          </div>
        </div>
        {/* <div className="update-giveaway-review__point-breakdown">
          <div className="update-giveaway-review__breakdown-header">
            <h3 className="update-giveaway-review__sub-header">Totals</h3>
          </div>
          <div>
            {segment && 
              giveaway.points.breakdown_type === '#' ? 
              giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.segment ? 0 : obj.segment);
              }, 0) : 
              Math.ceil(giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.segment ? 0 : (obj.segment / 100 * segment))
              }, 0))
            } recipients out of {segment} customers.
          </div>
          <div>
            {segment && 
              giveaway.points.breakdown_type === '#' ? 
              giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.value ? 0 : obj.value);
              }, 0) : 
              Math.ceil(giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.value ? 0 : (obj.value / 100 * segment))
              }, 0))
            } in total points given.
          </div>
          <div>
            x in total dollar value of points.
          </div>
        </div> */}
        
        <div className="giveaways-schedule-popup__cards">
          <div className={`giveaways-schedule-popup__card ${sendType === 'send' ? 'giveaways-schedule-popup__card--inactive' : ''}`} onClick={() => setSendType('schedule')}>
            <h3 className="giveaways-schedule-popup__heading"><i className={`fa-solid fa-clock giveaways-schedule-popup__icon${sendType === 'schedule' ? ' giveaways-schedule-popup__icon--active' : ''}`}></i>Schedule</h3>
            <p className="giveaways-schedule-popup__description">Schedule the giveaway to send at a later time.</p>
          </div>
          <div className={`giveaways-schedule-popup__card ${sendType === 'schedule' ? 'giveaways-schedule-popup__card--inactive' : ''}`} onClick={() => setSendType('send')}>
            <h3 className="giveaways-schedule-popup__heading"><i className={`fa-solid fa-rocket giveaways-schedule-popup__icon${sendType === 'send' ? ' giveaways-schedule-popup__icon--active' : ''}`}></i>Launch</h3>
            <p className="giveaways-schedule-popup__description">Send points to the chosen segment right now.</p>
          </div>
        </div>
        {sendType === 'schedule' &&
          <div className="giveaways-schedule-popup__scheduling-wrapper">
            <div className="giveaways-schedule-popup__scheduling-header">
              Launch time
            </div>
            <Formik
              initialValues={{
                date: `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`,
                hour: `${date.getHours() > 12 ? date.getHours() - 12 : date.getHours() === 0 ? '12' : date.getHours()}`,
                min: `${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`,
                daytime: `${date.getHours() > 11 ? 'pm' : 'am'}`
              }}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                // construct full date from 'values'
                let dateArr = values.date.split('/');
                let hour = values.hour;
                if (values.daytime === 'am' && values.hour === '12') {
                  hour = '0';
                }
                if (values.daytime === 'pm' && values.hour !== '12') {
                  hour = hour+12;
                }
                let execution_time = new Date(dateArr[2], `${dateArr[0]-1}`, dateArr[1], hour, values.min);
                axios.post(`https://api.amplifyloyalty.com/app/stores/giveaways/schedule`,
                  {
                    ...giveaway, 
                    execution_time
                  },
                  {
                    params: {
                      jwt: localStorage.getItem('_id')
                    }
                  }
                ).then(res => {
                  if (res.data.name === 'BadRequestError') {
                    logout();
                  } else {
                    navigate('/app/giveaways');
                  }
                }).catch(console.log)
              }}
            >
              <Form className='giveaways-schedule-popup__scheduling'>
                <DatePicker 
                  containerClass="giveaways-schedule-popup__field-container" 
                  inputClass="giveaways-schedule-popup__field--special"
                  dateName="date"
                  submitting={submitting}
                  spaceElement=".update-giveaway-review__breakdowns"
                />
                <TimePicker 
                  className="giveaways-schedule-popup__field--special" 
                  hourName="hour"
                  minName="min"
                  daytimeName="daytime"
                  submitting={submitting}
                  // values={}
                />
                <div className="giveaways-schedule-popup__column giveaways-schedule-popup__column--submit">
                  <button type="submit" className="btn gradient">Schedule</button>
                </div>
              </Form>
            </Formik>
          </div>
        }
        {sendType === 'send' && 
          <div className="giveaways-schedule-popup__send-container">
            <button className="giveaways-schedule-popup__send-button btn gradient" onClick={launch}>Launch Now</button>
          </div>
        }
      </div>
    </section>
  )
}


export const GiveawaysReview = (props) => {
  const {logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const {giveaway} = props;
  const {id} = useParams();
  const [sendType, setSendType] = useState('schedule');
  const [submitting, setSubmitting] = useState(false);
  const onLoadDate = new Date();
  onLoadDate.setMinutes(onLoadDate.getMinutes() + 10);
  const [date, setDate] = useState(onLoadDate);
  const [segment, setSegment] = useState(false);
  const [formValues, setFormValues] = useState({
    date: `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`,
    hour: `${date.getHours() > 12 ? date.getHours() - 12 : date.getHours() === 0 ? '12' : date.getHours()}`,
    min: `${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`,
    daytime: `${date.getHours() > 11 ? 'pm' : 'am'}`
  })
  const [error, setError] = useState(false);

  const launch = () => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach(btn => btn.disabled = true);
    axios.post(
      'https://api.amplifyloyalty.com/app/stores/giveaways/schedule',
      {...giveaway, type: 'execute'},
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        navigate('/app/giveaways');
      }
    }).catch(err => {
      console.log(err);
      buttons.forEach(btn => btn.disabled = false);
    })
  }

  const keyConverter = {
    $gt: 'more than',
    $lt: 'less than',
    spend: 'spent',
    account: 'created account',
    orders: 'ordered',
    any: 'of all time'
  }

  const updateValue = (name, value) => {
    setFormValues({...formValues, [name]: value});
  }

  const schedule = async () => {
    // validate that the date is not less than 5 minutes into the future
    const buttons = document.querySelectorAll('button');
    buttons.forEach(btn => btn.disabled = true);
    let dateArr = formValues.date.split('/');
    let hour = parseInt(formValues.hour);
    if (formValues.daytime === 'am' && formValues.hour === 12) {
      hour = 0;
    }
    if (formValues.daytime === 'pm' && formValues.hour !== 12) {
      hour = hour+12;
    }
    let execution_time = new Date(dateArr[2], `${dateArr[0]-1}`, dateArr[1], `${hour}`, formValues.min);
    let now = new Date();
    if (execution_time - now < 600000) {
      setError('Scheduled execution must be at least 10 minutes in the future.');
      buttons.forEach(btn => btn.disabled = false);
      return;
    } else setError(false);
    axios.post(`https://api.amplifyloyalty.com/app/stores/giveaways/schedule`,
      {
        ...giveaway, 
        execution_time
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        navigate('/app/giveaways');
      }
    }).catch(err => {
      buttons.forEach(btn => btn.disabled = false);
      console.log(err);
    })
  }

  return (
    <section className="update-giveaway-review centralize">
      <div className="update-giveaway-review__breakdowns block-section">
        <div className="update-promotion-review__title">Review to Schedule</div>
        <div className="update-giveaway-review__point-breakdown">
          <div className="update-giveaway-review__breakdown-header">
            <div className="update-promotion-review__item-icon-wrapper">
              <div className="update-promotion-review__item-icon">i</div>
            </div>
            <div className="update-giveaway-review__header-right">
              <div className="update-giveaway-review__section-info">
                <h3 className="update-giveaway-review__sub-header">Filters</h3>
                {giveaway.filters.length === 0 && 
                  <div>Entire customer base selected as segment.</div>
                }
                {giveaway.filters.length > 0 && giveaway.filters.map((orArr, i) => { 
                  return (
                    <div key={i} className="update-giveaway-review__and-filters">
                      <div className="update-giveaway-review__or-filters">
                      {orArr.map((filter, oI) => 
                        <span key={oI} className="update-giveaway-review__or-filter">
                          Customer has {keyConverter[filter.filter_type]}
                          {` ${keyConverter[filter.value_operator]}`}
                          {filter.filter_type !== 'account' && ` ${filter.filter_type === 'spend' ? '$' : ''}${filter.value}${filter.filter_type === 'orders' ? ' times' : ''}`}
                          {oI+1 < orArr.length && <span className="update-giveaway-review__or-separator"> OR</span>}<br />
                        </span>
                      )}
                      </div>
                      {i+1 < giveaway.filters.length &&
                        <div className="update-giveaway-review__and-separator">
                          AND
                        </div>
                      }
                    </div>
                  
                  )
                })}
              </div>
              <a href={`/app/giveaways/${id}`}><button className="btn">Edit</button></a>
            </div>
          </div>
        </div>
        <div className="update-giveaway-review__point-breakdown">
          <div className="update-giveaway-review__breakdown-header">
            <div className="update-promotion-review__item-icon-wrapper">
              <div className="update-promotion-review__item-icon">i</div>
            </div>
            <div className="update-giveaway-review__header-right">
              <div className="update-giveaway-review__section-info">
                <h3 className="update-giveaway-review__sub-header">Rewards</h3>
                {giveaway.rewards.breakdown.map((reward, i) => {
                  return (
                    <div key={i} className="update-giveaway-review__point">
                      <strong>{reward.segment}</strong> customers get <strong>{giveaway.rewards.breakdown_type === 'points' ? reward.value : reward.coupon.name}</strong> {giveaway.rewards.breakdown_type === 'points' ? 'points' : 'coupon'}
                    </div>
                  )
                })}
              </div>
              <a href={`/app/giveaways/${id}/rewards`}><button className="btn">Edit</button></a>
            </div>
          </div>
        </div>
        {/* <div className="update-giveaway-review__point-breakdown">
          <div className="update-giveaway-review__breakdown-header">
            <h3 className="update-giveaway-review__sub-header">Totals</h3>
          </div>
          <div>
            {segment && 
              giveaway.points.breakdown_type === '#' ? 
              giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.segment ? 0 : obj.segment);
              }, 0) : 
              Math.ceil(giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.segment ? 0 : (obj.segment / 100 * segment))
              }, 0))
            } recipients out of {segment} customers.
          </div>
          <div>
            {segment && 
              giveaway.points.breakdown_type === '#' ? 
              giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.value ? 0 : obj.value);
              }, 0) : 
              Math.ceil(giveaway.points.breakdown.reduce((acc, obj) => {
                return acc + (!obj.value ? 0 : (obj.value / 100 * segment))
              }, 0))
            } in total points given.
          </div>
          <div>
            x in total dollar value of points.
          </div>
        </div> */}
        
        <div className="giveaways-schedule-popup__cards">
          <div className={`giveaways-schedule-popup__card ${sendType === 'send' ? 'giveaways-schedule-popup__card--inactive' : ''}`} onClick={() => setSendType('schedule')}>
            <h3 className="giveaways-schedule-popup__heading"><i className={`fa-solid fa-clock giveaways-schedule-popup__icon${sendType === 'schedule' ? ' giveaways-schedule-popup__icon--active' : ''}`}></i>Schedule</h3>
            <p className="giveaways-schedule-popup__description">Schedule the giveaway to send at a later time.</p>
          </div>
          <div className={`giveaways-schedule-popup__card ${sendType === 'schedule' ? 'giveaways-schedule-popup__card--inactive' : ''}`} onClick={() => setSendType('send')}>
            <h3 className="giveaways-schedule-popup__heading"><i className={`fa-solid fa-rocket giveaways-schedule-popup__icon${sendType === 'send' ? ' giveaways-schedule-popup__icon--active' : ''}`}></i>Launch</h3>
            <p className="giveaways-schedule-popup__description">Send points to the chosen segment right now.</p>
          </div>
        </div>
        {sendType === 'schedule' &&
          <div className="giveaways-schedule-popup__scheduling-wrapper">
            <div className="giveaways-schedule-popup__scheduling-header">
              Launch time
            </div>
            <div className='giveaways-schedule-popup__scheduling'>
              <DatePicker 
                value={formValues.date}
                onChange={updateValue}
                inputClass="giveaways-schedule-popup__field--special"
                containerClass="giveaways-schedule-popup__field-container" 
                dateName="date"
                submitting={submitting}
                showCalendar={true}
                spaceElement=".update-giveaway-review__breakdowns"
              />
              <TimePicker 
                hourName="hour"
                minName="min"
                daytimeName="daytime"
                className="giveaways-schedule-popup__field--special" 
                submitting={submitting}
                onChange={updateValue}
                values={formValues}
              />
              <div className="giveaways-schedule-popup__column giveaways-schedule-popup__column--submit">
                <button onClick={schedule} className="giveaways-schedule-popup__send-button btn gradient">Schedule</button>
              </div>
            </div>
          </div>
        }
        {sendType === 'send' && 
          <div className="giveaways-schedule-popup__send-container">
            <button className="giveaways-schedule-popup__send-button btn gradient" onClick={launch}>Launch Now</button>
          </div>
        }
        {error && <div className="giveaways-schedule__error">{error}</div>}

      </div>
    </section>
  )
}