import React from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export default function Settings() {
    const {logout} = useContext(AuthContext);
    const resetDatabase = () => {
        // Make this it's own page with a form with options
        let store = localStorage.getItem('activeStore');
        let options = {
            headers: {
                'store': store
            }
        };
        if (window.confirm("Are you sure you want to reset the database? Doing so will delete all customers records and replace them with new ones based on their order history.")) {
            // subscribe to webhooks here instead of on auth
            axios.post(
                'https://bpi3g0bif6.execute-api.us-east-2.amazonaws.com/dev/customers/all',
                options
            ).then(res => {
                if (res.data.name === 'BadRequestError') {
                    logout();
                } else {
                    console.log(res)
                }
            }).catch(err => {
                console.log(err);
            });
            alert("Fetching customers, come back in a few minutes.")
        };
    }
    return(
        <div id="main-content">
            <h1>Settings</h1>
            <div className="settings">
                <div className="option">
                    <p>Include tax in order totals.</p>
                    <button className="gradient">Toggle</button>
                </div>
                <div className="option">
                    <p>Automatically subtract points on refund.</p>
                    <button className="gradient">Toggle</button>
                </div>
                <div className="option">
                    <p>Reset the database</p>
                    <button className="gradient" onClick={resetDatabase} disabled>Run</button>
                </div>
            </div>
        </div>
    )
}