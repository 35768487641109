import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import '../../assets/css/components/program.css'
import { GiveawaysTable } from '../../components/GiveawaysComponents';
import { useEffect, useState, useContext } from 'react';
import { CustomTable } from '../../components/utility/Utility';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import { create } from 'd3';

export default function Giveaways() {
  const {logout} = useContext(AuthContext);
  const [giveaways, setGiveaways] = useState(false);
  const [pageInfo, setPageInfo] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/giveaways',
    {
      params: {
        jwt: localStorage.getItem('_id')
      }
    }).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res);
        let info = {...res.data}
        delete info.data;
        setPageInfo(info);
        setGiveaways(res.data.docs);
      }
    }).catch(err => {
      console.log(err);
    })
  }, [])

  const createGiveaway = () => {
    const button = document.querySelector('.giveaways-header__create-new');
    button.disabled = true;
    let today = new Date();
    let arbitraryEnd = new Date();
    arbitraryEnd.setDate(arbitraryEnd.getDate() + 7);
    let todayDate = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;
    let todayTime = `${today.getHours() > 12 ? (today.getHours() - 12 < 10 ? `0${today.getHours() - 12}` : today.getHours() - 12) : today.getHours() === 0 ? '12' : today.getHours()}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}:${today.getSeconds() > 9 ? today.getSeconds() : `0${today.getSeconds()}`}`

    axios.post(
      'https://api.amplifyloyalty.com/app/stores/giveaways',
      {
        name: `New Giveaway - ${todayDate} ${todayTime}`,
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log(res);
        setTimeout(() => {
          navigate(`/app/giveaways/${res.data._id}`);
        }, 2500);
      }
    }).catch(err => {
      console.log(err)
      button.disabled = false;
      // responseHandler(err);
    })
  }

  const duplicateGiveaway = giveaway => {
    const button = document.querySelector('.giveaways-header__create-new');
    button.disabled = true;
    axios.post(
      'https://api.amplifyloyalty.com/app/stores/giveaways/duplicate',
      {
        _id: giveaway._id,
        name: `${giveaway.name} - Copy`
      },
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else if (res.data._id) {
        console.log(res);
        setTimeout(() => {
          navigate(`/app/giveaways/${res.data._id}`);
        }, 1000);
      }
    }).catch(err => {
      console.log(err);
      button.disabled = false;
      // responseHandler(err);
    })
  }
  
  const deleteGiveaway = (doc) => {
    // give user notice
    if (window.confirm('Are you sure you want to delete this Promotion? It cannot be undone.')) {
      axios.delete(
        'https://api.amplifyloyalty.com/app/stores/giveaways',
        {
          data: {
            _id: doc._id
          },
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      ).then(res => {
        setGiveaways(giveaways.filter(giv => giv._id !== doc._id));
      }).catch(err => {
        console.log(err);
      })
    }
  }

  return (
    <div className="main">
      {/* <GiveawaysTable giveaways={giveaways} /> */}
      <CustomTable 
        data={giveaways} 
        setData={setGiveaways} 
        metrics={{
          recipients: {
            name: "Recipients"
          },
          orders: {
            name: "Orders"
          },
          sales: {
            name: "Sales",
            format: "money"
          }
        }}
        description="Create and schedule giveaways to incentivize your customers."
        objectName="Giveaway"
        endpoint="giveaways"
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        createObject={createGiveaway}
        deleteObject={deleteGiveaway}
        editObject={navigate}
        duplicateObject={duplicateGiveaway}
      />
    </div>
  )
}