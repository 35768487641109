import { VIPTable, PutVIPForm } from "../components/VIPComponents"
import { useState, useEffect, useContext } from "react"
import { AuthContext } from '../contexts/AuthContext';
import axios from "axios";
import { responseHandler } from "../components/utility/Utility";

export default function VIP() {
  const {logout} = useContext(AuthContext);
  const [vip, setVip] = useState(false);
  const [formValues, setFormValues] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [integrated, setIntegrated] = useState(true);


  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/vip',
    {
      params: {
        jwt: localStorage.getItem('_id')
      }
    }).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        const isIntegrated = res.headers['x-integrated'];
        if (isIntegrated == 'false') setIntegrated(false);
        setVip(res.data);
      }
    }).catch(err => {
      console.log(err);
      responseHandler(err);
    })
  }, []);

  return (
    <div className="main two-column">
      <div className="vip-row">
        <VIPTable vip={[vip, setVip]} formValues={[formValues, setFormValues]} />
        {formValues && 
          <PutVIPForm 
            vip={[vip, setVip]} 
            formValues={[formValues, setFormValues]} 
            isEditing={[isEditing, setIsEditing]} 
            notIntegrated={!integrated}
          /> 
        }
      </div>
    </div>
  )
}
