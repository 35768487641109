import { useState, useEffect, useRef } from "react";
import axios from "axios";

export const ContactForm = (props) => {
  const {heading, body, consume} = props;
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [submitted, setSubmitted] = useState(false);

  const updateValue = (field, value) => {
    setValues({...values, [field]: value});
  }

  const submit = () => {
    const toDisable = [
      ...document.querySelectorAll('.contact-form__input'), 
      document.querySelector('.contact-form__textarea'),
      document.querySelector('.contact-form__button')
    ];
    toDisable.forEach(inp => inp.disabled = true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LcZ-UAqAAAAAMao0jmw3OyJhmXggpiMO2fydLl3', { action: 'submit' }).then(async (token) => {
        setRecaptchaToken(token);
        const formData = {
          ...values,
          recaptchaToken: token,
        };
        console.log('Form submitted:', formData);
        setSubmitted(true);
        // Submit the form data to your server (not shown)
        try {
          const res = await axios.post('https://server.amplifyloyalty.com/web/contact',
            formData,
            {withCredentials: false}
          );
          console.log(res);
        } catch (err) {
          toDisable.forEach(inp => inp.disabled = false);
          console.log({err})
        }
      });
    });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=6LcZ-UAqAAAAAMao0jmw3OyJhmXggpiMO2fydLl3`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className={`contact-form${consume ? " contact-form--consume" : ""}`}>
      {heading && <h2 className="contact-form__heading">{heading}</h2>}
      {body && <p className="contact-form__body">{body}</p>}
      {!submitted && <div className="contact-form__form">
        <div className="contact-form__row">
          <input className="contact-form__input" type="text" name="name" placeholder="Name" value={values.name} onChange={(e) => updateValue("name", e.target.value)} />
          <input className="contact-form__input" type="email" name="email" placeholder="Email" value={values.email} onChange={(e) => updateValue("email", e.target.value)}/>
        </div>
        <textarea name="message" className="contact-form__textarea" placeholder="What would you like to know more about?" value={values.message} onChange={(e) => updateValue("message", e.target.value)}></textarea>
        <div className="contact-form__buttons">
          <button className="btn gradient contact-form__button" onClick={submit}>Submit</button>
        </div>
      </div>}
      {submitted && <div className="contact-form__success">
        <div className="contact-form__success-message">Thanks for contacting us! We'll be reaching out shortly.</div>
        <div className="contact-form__success-cta">
          <div className="contact-form__success-cta-text">In the meantime, sign up for your free account now.</div>
          <a href="/sign-up" className="contact-form__success-cta-btn btn gradient">Sign Up</a>
        </div>
      </div>}
    </div>
  )

}

export const BlankHeader = (props) => {
  return (
    <div className="page__hero">
      <h1 className="page__hero-heading">{props.heading}</h1>
    </div> 
  )
}