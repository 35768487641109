import { useState, useEffect, useContext} from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { LineGraph, Scorecard, PieGraph, BarGraph } from '../components/utility/Charts';
import { DropDownFilter, fillDates } from '../components/utility/Utility';
import { useClientTimezone } from '../hooks/hooks';
import '../assets/css/charts.css';


import axios from 'axios';
axios.defaults.withCredentials = true;

export default function Dashboard() {
    const {logout} = useContext(AuthContext);
    const [events, setEvents] = useState(false);
    const [lineGraph, setLineGraph] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [activeRange, setActiveRange] = useState('Last 7 Days');
    const timezone = useClientTimezone();
    const now = new Date();
    now.setDate(now.getDate() - 7);
    now.setHours(0,0,0,0);
    const numFormat = new Intl.NumberFormat('en-US');
    const dollarFormat = new Intl.NumberFormat('en-US', {style: "currency", currency: "USD", maximumFractionDigits: 2});
    const end = new Date();
    end.setDate(end.getDate() - 1);
    end.setHours(23,59,59,999);
    const [match, setMatch] = useState({start: now, end: end});

    const formatLineGraph = async (data) => {
        let dates = [];
        let prevI = -1;
        let lineData = [];
        for await (let v of data) {
            if (dates.includes(v.Date)) {
                if (v.Action === 'Reward Redeemed') lineData[prevI].Redeemed += -v.Points;
                else lineData[prevI].Earned += v.Points;
            } else {
                lineData.push({
                    Date: v.Date,
                    Redeemed: v.Action === 'Reward Redeemed' ? -v.Points : 0,
                    Earned: v.Action !== 'Reward Redeemed' ? v.Points : 0
                });
                dates.push(v.Date);
                prevI++;
            }
        };
        setLineGraph(lineData);
    }

    const formatSalesData = async (data, rewards) => {
        let formatted = [];
        for await (let rew of Object.keys(rewards)) { // if more than 5, start grouping into Others
            let formattedReward = {};
            let singleReward = data.filter(event => event.Reward === rew);
            if (singleReward.length > 0) {
                for (let event of singleReward) {
                    formattedReward.name = rewards[event.Reward];
                    if (event.Action === 'Reward Redeemed') formattedReward.Redemptions = event.Count;
                    if (event.Action === 'Order Placed') {
                        formattedReward.Uses = event.Count;
                        formattedReward.Subtotal = event.Subtotal;
                    }
                }
                if (!formattedReward.Uses) formattedReward.Uses = 0;
                if (!formattedReward.Subtotal) formattedReward.Subtotal = 0;
                if (!formattedReward.Redemptions) formattedReward.Redemptions = 0;
            }
            if (Object.keys(formattedReward).length > 0) formatted.push(formattedReward);
        };
        setSalesData(formatted.sort((a, b) => b.Redemptions - a.Redemptions));
    }
    
    // Set the useEffect to run with updated queries as state changes on filters
    useEffect(() => {
        axios.get(
            'https://api.amplifyloyalty.com/app/dashboard',
                {
                    params: {
                        jwt: localStorage.getItem('_id'),
                        start: match.start,
                        end: match.end,
                        timezone
                    }
                }
            )
            .then(async res => {
                if (res.data.name === 'BadRequestError') {
                    logout();
                } else {
                    setEvents(res.data.points);
                    let lineData = await fillDates(
                        res.data.points, 
                        match.start, 
                        match.end, 
                        {
                            Points: 0,
                            Subtotal: 0,
                            Count: 0,
                            Action: null
                        }
                    )
                    formatLineGraph(lineData);
                    formatSalesData(res.data.rewards, res.data.all_rewards);
                }
            }).catch(err => {
                console.log(err);
            })
    }, [match])

    // date filtering next
    return (
        <div className="dashboard">
            <div className="dashboard__filters float-element">
                {/* Load state maybe similar to data studio */}
                <div className="dashboard__title">Program Overview</div>
                <DropDownFilter 
                    classname="promotions-table-filters__filter-wrapper"
                    active={activeRange}
                    setActive={setActiveRange}
                    options={[
                    {
                        text: 'Last 7 Days',
                        action: () => {
                            const time = new Date();
                            time.setDate(time.getDate() - 7);
                            time.setHours(0,0,0,0);
                            let endTime = new Date();
                            endTime.setDate(endTime.getDate() - 1);
                            endTime.setHours(23,59,59,59);
                            setMatch({start: time, end: endTime});
                        }
                    },
                    {
                        text: 'Last 14 Days',
                        action: () => {
                            const time = new Date();
                            time.setDate(time.getDate() - 14);
                            time.setHours(0,0,0,0);
                            let endTime = new Date();
                            endTime.setDate(endTime.getDate() - 1);
                            endTime.setHours(23,59,59,59);
                            setMatch({start: time, end: endTime});
                        }
                    },
                    {
                        text: 'Last 30 Days',
                        action: () => {
                            const time = new Date();
                            time.setDate(time.getDate() - 30);
                            time.setHours(0,0,0,0);
                            let endTime = new Date();
                            endTime.setDate(endTime.getDate() - 1);
                            endTime.setHours(23,59,59,59);
                            setMatch({start: time, end: endTime});
                        }
                    },
                    {
                        text: 'This Month',
                        action: () => {
                            const time = new Date();
                            let endTime = new Date();
                            endTime.setDate(endTime.getDate() - 1);
                            endTime.setHours(23,59,59,59);
                            setMatch({start: new Date(time.getFullYear(), time.getMonth(), 1, 0), end: endTime});
                        }
                    },
                    {
                        text: 'Last Month',
                        action: () => {
                            const time = new Date();
                            time.setMonth(time.getMonth() -1);
                            setMatch({
                                start: new Date(time.getFullYear(), time.getMonth(), 1, 0), 
                                end: new Date(time.getFullYear(), time.getMonth()+1, 0,23,59,59,99)
                            });
                        }
                    },
                    {
                        text: 'This Year',
                        action: () => {
                            const time = new Date();
                            time.setHours(0,0,0,0);
                            let endTime = new Date();
                            endTime.setDate(endTime.getDate() - 1);
                            endTime.setHours(23,59,59,59);
                            setMatch({start: new Date(`1/1/${time.getFullYear()}`), end: endTime});
                        }
                    },
                    {
                        text: 'Last Year',
                        action: () => {
                            const time = new Date();
                            time.setHours(0,0,0,0);
                            setMatch({start: new Date(`1/1/${time.getFullYear()-1}`), end: new Date(time.getFullYear(), 0, 0, 23, 59, 59, 99)});
                        }
                    },
                    {
                        text: 'Custom Range',
                        action: (start, end) => setMatch({start, end})
                    }
                    ]}
                    dates={true}
                />
            </div>
            <div className="dashboard__row">
                <div className="dashboard__scorecards float-element">
                    {events && 
                        <>
                            <Scorecard className="dashboard__scorecard" title="Points Earned" 
                                tooltip={{
                                    message: "Test",
                                    height: '300px',
                                    width: '500px'
                                }}
                                data={numFormat.format(events.reduce((acc, v) => {
                                    if (v.Action !== "Reward Redeemed") return acc + v.Points;
                                    else return acc + 0;
                                }, 0))}
                            />
                            <Scorecard className="dashboard__scorecard" title="Points Redeemed" data={numFormat.format(events.reduce((acc, v) => {
                                if (v.Action === "Reward Redeemed") return acc - v.Points;
                                else return acc + 0;
                            }, 0))}/>
                            <Scorecard className="dashboard__scorecard" title="Redemptions" data={Math.round(numFormat.format(events.reduce((acc, v) => {
                                if (v.Action === "Reward Redeemed") return acc + v.Count;
                                else return acc + 0;
                            }, 0)))}/>
                            <Scorecard className="dashboard__scorecard" title="$ Discounted" data={dollarFormat.format(events.reduce((acc,v) => acc+v.Discounted, 0))}/>
                            <Scorecard className="dashboard__scorecard" title="$ from Redemptions" data={dollarFormat.format(salesData.reduce((acc, v) => acc+v.Subtotal, 0))}/>
                            <Scorecard className="dashboard__scorecard" title="AOV w/ Redemption" data={dollarFormat.format(salesData.length > 0 ? 
                                salesData.reduce((acc, v) => acc+v.Subtotal, 0) / 
                                salesData.reduce((acc, v) => acc+v.Uses, 0)
                                : 0)
                            }/>
                        </>
                    }
                </div>
                <LineGraph 
                    width="100%"
                    height={250} 
                    data={lineGraph}
                    heading="Points Earned x Points Redeemed"
                    className="float-element dashboard-line-graph"
                    keys={["Earned", "Redeemed"]}
                    formatter={numFormat}
                    tickFormatter={(value) => {
                        const splitValue = value.split('-')
                        return `${parseInt(splitValue[1])}/${parseInt(splitValue[2])}`
                    }}
                    labelFormatter={(value) => {
                        const splitValue = value.split('-')
                        return `${parseInt(splitValue[1])}/${parseInt(splitValue[2])}/${splitValue[0]}`;
                    }}
                />
            </div>
            <div className="dashboard__row">
                <BarGraph 
                    className="dashboard-bar-graph float-element"
                    heading="Times Redeemed / Times Used by Redemption"
                    data={salesData}
                    width="100%"
                    height={250}
                    name="name"
                    fill={['#103E61', '#F51504']}
                    firstKey="Redemptions"
                    secondKey="Uses"
                />
                <PieGraph 
                    className="dashboard-pie-graph float-element"
                    heading="Redemptions by $ in Sales"
                    data={[...salesData].filter(data => data.Subtotal > 0).sort((a,b) => b.Subtotal - a.Subtotal)} 
                    // group others > 5 into 1
                    width="100%" 
                    height={250}
                    value="Subtotal"
                    name="name" 
                    fill={['#F8A305','#F51504', '#103E61']}
                    formatter={dollarFormat}
                />
            </div>
        </div>
    );
}