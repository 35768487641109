import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { CustomerSummary } from '../components/CustomersComponents';
import { BasicTable } from '../components/utility/Utility';
import '../assets/css/components/program.css';

export default function Customer() {
    const {logout} = useContext(AuthContext);
    const [customer, setCustomer] = useState(false);
    
    let { id } = useParams();
    
    useEffect(() => {
        axios.get(
            'https://api.amplifyloyalty.com/app/customers',
            {
                params: {
                    jwt: localStorage.getItem('_id'),
                    _id: id
                }
            }
        ).then(res => {
            if (res.data.name === 'BadRequestError') {
                logout();
            } else {
                console.log(res.data)
                let resData = {
                    ...res.data,
                    events: res.data.events.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()),
                    orders: res.data.orders.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                }
                setCustomer(resData);
            }
        }).catch(err => {
            console.log(err);
        });
    }, [id]);

    return (
        <div className="main">
            <CustomerSummary customer={customer} setCustomer={setCustomer} />
            <div className="customer-history centralize">
                {/* Add available rewards. Give ability to remove them from their account */}
                <BasicTable 
                    header="Order History"
                    className="customer-orders"
                    data={customer.orders} 
                    keys={{
                        'created_at': {
                            name: 'Time',
                            format: (v) => {
                                let date = new Date(v);
                                return (`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`)
                            }
                        }, 
                        'order_number': {
                            name: 'Order Number',
                            format: (v) => v
                        },
                        'subtotal': {
                            name: 'Subtotal',
                            format: (v) => Intl.NumberFormat('en-us', {style: 'currency', currency: 'USD'}).format(v)
                        }
                    }}
                />
                <BasicTable 
                    className="customer-events"
                    data={customer.events}
                    keys={{
                        'time': {
                            name: 'Time',
                            format: (v) => {
                                let date = new Date(v);
                                return (`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`)
                            }
                        },
                        'name': {
                            name: 'Action',
                            format: (v) => v
                        },
                        'points': {
                            name: 'Points',
                            format: (v) => Intl.NumberFormat('en-us').format(v)
                        }
                    }}
                    header="Loyalty Program Engagement"
                />
            </div>
        </div>
    )
}