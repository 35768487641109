import { useContext, useEffect, useState } from "react";
import { AuthContext } from '../contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import jwt from 'jsonwebtoken';

export default function AuthorizedRoute({children}) {
    const {authed, logout} = useContext(AuthContext);
    const storedAuth = JSON.parse(localStorage.getItem('_id'));
    const storedToken = storedAuth?.accessToken;
    if ((authed === storedToken) && (new Date(jwt.decode(storedToken).expiration) - Date.now() >= 0)) return children;
    else {
        logout();
        return <Navigate to="/login" />
    }
}