import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
// import '../../assets/css/web.css';

export default function UpdatePassword() {
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    console.log(searchParams);
  }, [])

  const updatePassword = (token) => {
    // token in email should be a JWT with the token as well as the timestamp
    // send to server
      // search for users by reset_token field
      // verify request is within timeframe
      // send back a new token allowing password reset 
      // submit password reset form and send token 
      // verify token and reset password with 5 minute allowance
  }

  return (
    <div className="update-password">
      {!submitted && 
        <Formik
          initialValues={{email: ''}}
          validationSchema={Yup.object({
            email: Yup.string().email().required('Email is required.')
          })}
          onSubmit={async (values) => {
            // Send request to send email to verify 
            try {
              // Remove form from the doc
              // setSubmitted(true);
              let res = await axios.post(`${process.env.REACT_APP_API}/auth/reset`, values);
              console.log(res);
            } catch(err) {
              console.log(err);
            }
          }}
        >
          <Form className='update-password__form'>
            <h2 className="update-password__form-title">Reset Your Password</h2>
            <p className="update-password__form-description">Enter your email below and we will send a reset password link if there is an account under your email address.</p>
            <Field className="update-password__input input-field" name="email" placeholder="customer@gmail.com" />
            <div className="update-password__form-error form-error">
              <ErrorMessage name="email" />
            </div>
            <button type="submit" className="gradient">RESET</button>
          </Form>
        </Formik>
      }
     
    </div>
  )
}