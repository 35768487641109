import { EarnHero, Earnings, MarketingStack, EarningOptions } from "../../components/WebsiteComponents";
// import '../../assets/css/web.css';

export default function Earn() {
  return (
    <>
      <EarnHero 
        header="EARN"
        body="Create a unique experience for your customers, rewarding them for specific engagements with your brand. You enable rewards and define the amount of points they will earn according to the importance of your marketing strategy."
        img="https://amplify-loyalty-website.s3.amazonaws.com/1-I-Mac+(1).png"
      />
      <Earnings 
        header="YOU SET THE RULES"
        body={[
          'Set rules for customers to earn points for your store',
          'Activate and disable any time',
          'Create your own parameters',
          'Easy and quick setup',
          'Tons of ways to reward'
        ]}
        img="https://amplify-loyalty-website.s3.amazonaws.com/2-Tools+(1).png"
      />
      <MarketingStack 
        header="INTEGRATE YOUR MARKETING STACK"
        body="Connect your favorite marketing platforms and reward customers for the actions that matter the most."
        image="https://amplify-loyalty-website.s3.amazonaws.com/2-Smartphones+(1).png"
      />
      <EarningOptions 
        header="REDEEM IN ACCOUNT"
        body="Customers can redeem these discounts within their account on your store as soon as you make them active."
        img="https://amplify-loyalty-website.s3.amazonaws.com/3-Icons+(1)+(1).png"
      />
    </>
  )
}