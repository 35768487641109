import { useEffect, useState } from 'react';
import { 
  ResponsiveContainer, 
  LineChart, 
  Line, 
  CartesianGrid, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar
} from 'recharts';

// export const BarChart = (props) => {
//   const { className, width, height } = props;
//   const margin = { top: 20, right: 20, bottom: 20, left: 200 };
//   const data = [{country: 'USA', population: 300}, {country: 'UK', population: 200}, {country: 'Canada', population: 100}];
  
//   // useEffect(() => {
//   //   const row = d => {
//   //     return d;
//   //   }
//   // }, [])
//   const innerHeight = height - margin.top - margin.bottom;
//   const innerWidth = width - margin.left - margin.right;

//   const yScale = d3.scaleBand()
//     .domain(data.map(d => d.country)) // the options for the scale
//     .range([0, innerHeight]); // the pixel range of the scale 

//   const xScale = d3.scaleLinear()
//     .domain([0, d3.max(data, d => d.population)]) 
//     .range([0, innerWidth]); 

//   console.log(yScale.domain());
//   console.log(yScale('Canada'));
//   console.log(yScale.bandwidth())
//   return (
//     <div className={`bar-chart${className ? ` ${className}` : ''}`}>
//       <svg width={width} height={height}>
//         <g transform={`translate(${margin.left}, ${margin.top})`}>
//           {xScale.ticks(7).map(tickValue => (
//             <g key={tickValue} transform={`translate(${xScale(tickValue)},0)`}>
//               <line y2={innerHeight} stroke="rgb(205, 205, 205)"/>
//               <text style={{textAnchor: 'middle'}} y={innerHeight + 8} dy=".71em">
//                 {tickValue}
//               </text>
//             </g>
//           ))}
//           {yScale.domain().map(tickValue => (
//               <text key={tickValue} dy=".32em" style={{textAnchor: 'end'}} x="-3" y={yScale(tickValue) + yScale.bandwidth() / 2}>
//                 {tickValue}
//               </text>
//           ))}
//           {data.map(d => 
//             <rect 
//               key={d.country}
//               x={0} 
//               y={yScale(d.country)} // finds the y position of the country based on the range
//               width={xScale(d.population)} 
//               height={yScale.bandwidth()} 
//             />
//           )}
//         </g>
//       </svg>
//     </div>
//   )
// }

export const LineGraph = (props) => {
  const {
    width, 
    height, 
    data, 
    heading, 
    className, 
    keys, 
    formatter, 
    tickFormatter, 
    labelFormatter
  } = props;
  const determineInterval = (dataLength) => {
    if (dataLength <= 10) return 0; // Show all labels if data points are 10 or fewer
    if (dataLength <= 20) return 1; // Show every other label if data points are 20 or fewer
    return Math.floor(dataLength / 10); // Otherwise, show one label every few points
  };
  const interval = determineInterval(data.length);
  return (
    <div className={`line-graph${className ? ` ${className}` : ''}`}>
        {heading && <div className="line-graph__heading">{heading}</div>}
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={data} className="line-graph__graph">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="Date" 
              tickFormatter={value => {
              if (tickFormatter) return tickFormatter(value);
              else return value;
              }} 
              interval={interval}
              angle={-45}
              textAnchor="end"
            />
            <YAxis yAxisId="left"/>
            {keys[1] && 
              <>
              <Line yAxisId="right" type="monotone" dataKey={keys[1]} stroke="#F51504" />
              <YAxis yAxisId="right" orientation="right" />
              </>
            }
            <Tooltip 
              formatter={(value, name, props) => {
                if (formatter) return formatter.format(value);
                else return value;  
              }}
              labelFormatter={(value) => {
                if (labelFormatter) return labelFormatter(value);
                const newDate = new Date(value);
                return `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()}`
              }}
            />
            <Legend wrapperStyle={{paddingTop: 5}}/>
            <Line yAxisId="left" type="monotone" dataKey={keys[0]} stroke="#F8A305" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  )
}

export const Scorecard = (props) => {
  const {data, className, title, tooltip} = props;
  return (
    <div className={`scorecard${className ? ` ${className}` : ''}`}>
      <div className="scorecard__title">{title}</div>
      <div className="scorecard__metric">{data}</div>
    </div>
  )
}

export const PieGraph = (props) => {
  const {className, data, width, height, value, name, fill, heading, formatter} = props;
  const [fData, setFData] = useState(data);
  useEffect(() => {
    setFData(data);
    if (data.length === 0) {
      setFData([{
       Subtotal: 1e-10,
       name: "No sales"
      }])
    }
  }, [data])
  return (
    <div className={`pie-graph${ className ? ` ${className}` : ''}`}>
      {heading && <div className="line-graph__heading">{heading}</div>}
      <ResponsiveContainer width={width} height={height} >
        <PieChart>
          <Pie data={fData} dataKey={value} nameKey={name} cx="50%" cy="50%" outerRadius={80}
          startAngle={-270} endAngle={-630}>
            {
              fData.map((entry, index) => <Cell key={index} fill={fill[index % fill.length]} />)
            }
          </Pie>
          <Tooltip formatter={(value, name, props) => {
            if (formatter) return formatter.format(value);
            else return value;
          }}/>
          <Legend 
            layout="vertical" 
            verticalAlign="middle" 
            align="right" 
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export const BarGraph = (props) => {
  const {className, data, width, height, name, fill, heading, firstKey, secondKey} = props;
  const [fData, setFData] = useState(data);
  useEffect(() => {
    setFData(data);
    if (data.length === 0) {
      setFData([{
        [firstKey]: 0,
        [secondKey]: 0
      }])
    }
  }, [data])

  return (
    <div className={`bar-graph${className ? ` ${className}` : ''}`}>
      {heading && <div className="line-graph__heading">{heading}</div>}
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={fData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={name} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={firstKey} fill={fill[0]} />
            <Bar dataKey={secondKey} fill={fill[1]} />
          </BarChart>
        </ResponsiveContainer>
      {/* {data.length === 0 && 
        <div className="graph-no-data">
          <div className="graph-no-data__message">No data to show.</div>
        </div>
      } */}
    </div>
  )
}