import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import { Loading, FormError, SearchPreview, PicksDisplay, SimpleSearchPreview, SimplePicksDisplay, CouponForm } from './utility/Utility';
import * as Yup from 'yup';

export const VIPTable = (props) => {
  const {logout} = useContext(AuthContext);
  const [vip, setVip] = props.vip;
  const [formValues, setFormValues] = props.formValues;
  const [status, setStatus] = useState(vip.status === 'active' ? true : false); // Get this from API

  useEffect(() => {
    setStatus(vip.status === 'active' ? true : false);
    if (!vip || vip.ranks.length >= 5) {
      toggleDisabled(true);
    } else {
      toggleDisabled(false);
    }
  }, [vip]);

  const toggleDisabled = (state) => {
    let toggles = [
      ...document.querySelectorAll('.vip-table__settings-input'),
      document.querySelector('.vip-table__add-tier-button'),
      document.querySelector('.vip-table__settings-submit'),
    ]
    toggles.forEach(toggle => toggle.disabled = state);
  }

  const toggleVIP = () => {
    let btn = document.querySelector('.earnings-option__toggle .switch-input');
    btn.disabled = true;
    let newVIP = {...vip, status: !status === true ? 'active' : 'inactive'}
    setStatus(!status);
    setVip(newVIP);
    // create a handler in the auth context to log out if no access token detected
    axios.put('https://api.amplifyloyalty.com/app/stores/vip', 
      newVIP,
      {
        params: {
          jwt: localStorage.getItem('_id')
        }
      }
    ).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        // response handler
      }
    }).catch(err => { 
      setStatus(!status);
      console.log(err);
      // response handler
    }).finally(() => {
      btn.disabled = false;
    })
    // handle load state
  }

  const addTier = () => {
    if (vip.ranks.length >= 5) {
      return;
    }
    setFormValues({
      rank: vip.ranks.length + 1,
      name: 'New Tier',
      multiplier: 1,
      qualifier_value: 0
    })
  }

  const editTier = (rank, index) => {
    setFormValues({...rank, index: index});
  }

  return (
    <section className="vip-table">
      <div className="vip-table__header">
        <div className="vip-table__header-left">
          <div className="vip-table__title">VIP Program</div>
          <label className="earnings-option__toggle switch">
            <input className="switch-input" type="checkbox" name="status" value={status} onChange={() => { toggleVIP();}} />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      <div className="vip-table__description">Create up to 5 VIP tiers for customers to unlock additional rewards by spending more money with your store.</div>
      <div className="vip-table__content">
        <div className="vip-table__table">
          <div className="vip-table__table-title-header">
            <div className="vip-table__table-title">Tiers</div>
            <button className="btn gradient vip-table__add-tier-button" onClick={() => { addTier(); }}>Add tier</button>
          </div>
          <div className="vip-table__metrics">
            <div className="vip-table__static-column">
              <div className="vip-table__table-header vip-table__table-header--static">
                <div className="vip-table__editing--header"></div>
              </div>
              <div className="vip-table__body">
                {vip && vip.ranks.map((rank, index) => {
                  return (
                    <div key={rank._id} className="vip-table__row">
                      <div className="vip-table__editing">
                        <i className="fa-solid fa-pen-to-square" onClick={() => {editTier(rank, index)}}></i>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="vip-table__scrollable">
              <div className="vip-table__table-header vip-table__table-header--scrollable">
                <div className="vip-table__heading">Tier Name</div>
                <div className="vip-table__heading">Qualifier</div>
                <div className="vip-table__heading">Members</div>
              </div>
              <div className="vip-table__body">
                {!vip && <Loading className="vip-table__loading" />}
                {vip && vip.ranks.map((rank, index) => {
                  return (
                    <div key={rank._id} className="vip-table__row">
                      <div className="vip-table__cell-wrapper">
                        <div className="vip-table__cell">{rank.name}</div>
                      </div>
                      <div className="vip-table__cell-wrapper">
                        {vip.qualifier_type === 'spend' && <div className="vip-table__cell">Spend ${rank.qualifier_value}</div>}
                        {vip.qualifier_type === 'points' && <div className="vip-table__cell">Earn {rank.qualifier_value} points</div>}
                      </div>
                      <div className="vip-table__cell-wrapper">
                        <div className="vip-table__cell">{vip.members && vip.members[rank._id] ? vip.members[rank._id] : 0}</div>
                      </div>
                    </div>
                  )
                })}
                {vip && vip.ranks.length === 0 && 
                  <div className="vip-table__empty-warning">No VIP tiers created yet.</div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="vip-table__settings" style={{display: 'none'}}>
          <Formik
            initialValues={{
              qualifier_type: vip.qualifier_type,
              expiry_type: vip.expiry_type
            }}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              let payload = {...vip, ...values};
              toggleDisabled(true);
              axios.put(
                'https://api.amplifyloyalty.com/app/stores/vip',
                payload,
                {
                  params: {
                    jwt: localStorage.getItem('_id')
                  }
                }
              ).then(res => {
                if (res.data.name === 'BadRequestError') {
                  logout();
                } else {
                  console.log(res);
                  setVip(res.data);
                }
              }).catch(err => {
                console.log(err);
              }).finally(() => {
                toggleDisabled(false);
              });
              console.log(payload);
            }}
            enableReinitialize={true}
          >
            <Form className="vip-table__settings-form">
              <div className="vip-table__settings-header">
                <div className="vip-table__settings-title">Settings</div>
                <button className="vip-table__settings-submit btn gradient" type="submit">Save</button>
              </div>
              <div className="vip-table__settings-content">
                <div className="vip-table__settings-row">
                  <label htmlFor="qualifier_type" className="vip-table__settings-label">Qualifier</label>
                  <Field as="select" name="qualifier_type" className="vip-table__settings-input">
                    <option value="spend" className="vip-table__settings-option">Amount spent</option>
                    <option value="points" className="vip-table__settings-option">Points earned</option>
                  </Field>
                </div>
                <div className="vip-table__settings-row">
                  <label htmlFor="expiry_type" className="vip-table__settings-label">Expires</label>
                  <Field as="select" name="expiry_type" className="vip-table__settings-input">
                    <option value="never" className="vip-table__settings-option">Never</option>
                    <option value="calendar" className="vip-table__settings-option">Calendar year</option>
                    {/* <option value="year" className="vip-table__settings-option">Year from start</option> */}
                  </Field>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </section>
  )
}

export const PutVIPForm = (props) => {
  const {logout} = useContext(AuthContext);
  const [formValues, setFormValues] = props.formValues;
  const [vip, setVip] = props.vip;
  const { notIntegrated } = props;
  // const [isEditing, setIsEditing] = props.isEditing;
  const [rewardForm, setRewardForm] = useState(false);
  const [bonuses, setBonuses] = useState(formValues.bonus || []);
  const [validation, setValidation] = useState({});

  useEffect(() => {
    // set validation schema
    console.log(formValues)
    let minVal = 0;
    let maxVal = Infinity;
    let minMult = 1;
    let maxMult = Infinity;
    if (vip.ranks.length > 0) {
      if (formValues._id !== undefined) {
        if (formValues.index !== 0) {
          minVal = vip.ranks[formValues.index - 1].qualifier_value + .01;
          minMult = vip.ranks[formValues.index - 1].multiplier;
        }
        if (formValues.index < vip.ranks.length - 1) {
          maxVal = vip.ranks[formValues.index + 1].qualifier_value - .01;
          maxMult = vip.ranks[formValues.index + 1].multiplier;
        }
      } else {
        minVal = vip.ranks[vip.ranks.length - 1].qualifier_value + 1;
        minMult = vip.ranks[vip.ranks.length - 1].multiplier;
        
      }
    }
    // make max values if ranks after this one
    if (formValues.index === undefined) {
      setFormValues({...formValues, qualifier_value: minVal, multiplier: minMult});
    }
    setValidation(Yup.object({
      qualifier_value: Yup.number()
        .integer('Must be a whole number')
        .positive('Must be a positive number')
        .min(minVal, `Must be greater than ${minVal - 1}`)
        .max(maxVal, `Must be less than ${maxVal + .01}`)
        .required('Required'),
      multiplier: Yup.number()
        .positive('Must be a positive number')
        .min(minMult, `Must be at least ${minMult}`)
        .max(maxMult, `Must be less than or equal to ${maxMult}`)
        .required('Required'),
    }))
    // if rank is > 1, then qualifier_value and multiplier must be >= current rank - 1
  }, [])

  const deleteBonus = (i) => {
    let newBonuses = [...bonuses]
    newBonuses.splice(i, 1);
    setBonuses(newBonuses);
  }

  const deleteTier = (rankId) => {
    if (window.confirm('Are you sure you want to delete this VIP Rank? This cannot be undone.')) {
      toggleEditing('off');
      axios.delete('https://api.amplifyloyalty.com/app/stores/vip',
        {
          params: {
            jwt: localStorage.getItem('_id')
          },
          data: {rankId}
        },
      ).then(res => {
        if (res.data.name === 'BadRequestError') {
          logout();
        } else {
          // response handler
          let newVip = {...vip};
          let dRId = newVip.ranks.findIndex(rnk => rnk._id == rankId);
          delete newVip.ranks[dRId];
          setVip(newVip);
          setFormValues(false);
        }
      }).catch(err => { 
        console.log(err);
        toggleEditing('on');
        // response handler
      });
    }
  }

  const toggleEditing = (direction) => {
    let inputs = document.querySelectorAll('.vip-form-container input');
    let btns = document.querySelectorAll('.vip-form-container button');
    if (direction === 'on') {
      [...inputs, ...btns].forEach(el => {
        el.disabled = false;
      });
    } else if (direction === 'off') {
      [...inputs, ...btns].forEach(el => {
        el.disabled = true;
      });
    }
  }

  const submitCoupon = async (coupon) => {
    if (coupon._id || coupon.tempId) {
      let bonusIndex;
      if (coupon.tempId) bonusIndex = bonuses.findIndex(bon => bon.tempId === coupon.tempId);
      else bonusIndex = bonuses.findIndex(bon => bon._id === coupon._id);
      let newBonuses = [...bonuses];
      newBonuses[bonusIndex] = coupon;
      setBonuses(newBonuses);
      setRewardForm(false);
    } else {
      setBonuses([...bonuses, {...coupon, tempId: Math.random() * 1000}]);
      setRewardForm(false);
    }
  }
  
  return (
    <div className="flex-popup-overlay">
      {!rewardForm && 
        <section className="vip-form-container">
          <Formik
            initialValues={formValues}
            validationSchema={validation}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              toggleEditing('off');
              // Send to server
              axios.put(
                'https://api.amplifyloyalty.com/app/stores/vip',
                {...values, bonus: bonuses},
                {
                  params: {
                    jwt: localStorage.getItem('_id')
                  }
                }
              ).then(res => {
                if (res.data.name === 'BadRequestError') {
                  logout();
                } else {
                  // push to vip state
                  // update the VIP object based on the payload instead to keep the Members aggregation
                  let vipObj = {...vip};
                  if (values._id) {
                    let rI = vipObj.ranks.findIndex(rnk => rnk._id == values._id);
                    vipObj.ranks[rI] = res.data;
                    setVip(vipObj);
                  } else {
                    vipObj.ranks.push(res.data);
                    setVip(vipObj);
                  }
                  setFormValues(false);
                }
              }).catch(err => {
                console.log(err);
                toggleEditing('on');
                
              })
            }}
            enableReinitialize={true}
          >
            <Form className="vip-form">
              <div className="vip-form__heading">
                <div className="vip-form__title">
                  <div className="vip-form__form-name">{formValues.name}</div>
                  <div className="vip-form__form-title-divider">|</div>
                  <div className="vip-form__title-rank">Rank {formValues.index !== undefined ? formValues.index + 1 : vip.ranks.length + 1}</div>
                </div>
                {formValues._id !== undefined &&
                  <i className="vip-form__delete fa-solid fa-trash" onClick={() => {deleteTier(formValues._id);}}></i>
                }
              </div>
              {notIntegrated && 
                <div className="redemption-form__integration-notice">You must integrate your Shopify account to create VIP tiers.</div>
              }
              <div className="vip-form__field">
                <label htmlFor="name">Name</label>
                <div className="vip-form__input-container">
                  <Field className="vip-form__input" name="name" />
                </div>
              </div>
              <div className="vip-form__field">
                <label htmlFor="qualifier_value">Qualifier</label>
                <div className="vip-form__input-container">
                  {vip.qualifier_type === 'spend' && 
                    <div className="vip-form__input-identifier vip-form__input-identifier--value">$</div>
                  }
                  <Field className={`vip-form__input ${vip.qualifier_type === 'spend' ? 'vip-form__input--spend' : 'vip-form__input--points'}`} name="qualifier_value" type="number" />
                  <div className="vip-form__input-identifier">
                    {vip.qualifier_type === 'spend' && 'spent'}
                    {vip.qualifier_type === 'points' && 'points'}
                  </div>
                </div>
              </div>
              <FormError className="vip-form__error-message" inputName="qualifier_value" />
              <div className="vip-form__field">
                <label htmlFor="multiplier">Purchase point multiplier</label>
                <div className="vip-form__input-container">
                  <Field className="vip-form__input vip-form__input--points" name="multiplier" type="number" />
                  <div className="vip-form__input-identifier">x</div>
                </div>
              </div>
              <FormError className="vip-form__error-message" inputName="multiplier" />
              <div className="vip-form__field">Bonus Rewards</div>
              <div className="vip-form__bonus-container">
                {bonuses.length > 0 && bonuses.map((bonus, i) => {
                  return (
                    <div key={`${i}${bonus.name}`} className="vip-form__bonus">
                      <div className="vip-form__bonus-name" onClick={() => {setRewardForm({...bonus, i: i})}}>{bonus.name}</div>
                      {/* <div className="vip-form__bonus-name" onClick={() => console.log(bonus)}>{bonus.name}</div> */}
                      <div className="vip-form__bonus-delete" onClick={() => {deleteBonus(i);}}>x</div>
                    </div>
                  )
                })}
              </div>
              <div className="vip-form__add-bonus" onClick={() => setRewardForm(true)}>+ Reward</div>
              <div className="vip-form__field vip-form__button-container">
                <button className="vip-form__cancel-button btn" type="button" onClick={() => {setFormValues(false);}}>Cancel</button>
                {!notIntegrated && <button className="vip-form__submit btn gradient" type="submit">{formValues.index !== undefined ? 'Update' : 'Create'}</button>}
              </div>
            </Form>
          </Formik>
        </section>
      }
      {rewardForm && 
        <CouponForm 
          coupon={rewardForm}
          setCreatingCoupon={setRewardForm}
          submit={submitCoupon}
        />
        // <BonusRewardForm 
        //   coupon={rewardForm}
        //   setCreatingCoupon={setRewardForm}
        //   formValues={[formValues, setFormValues]}
        //   bonuses={[bonuses,setBonuses]}
        //   setRewardForm={setRewardForm}
        // />
      }
    </div>
  )
}

const BonusRewardForm = (props) => {
  const {coupon, setCreatingCoupon, setRewardForm} = props;
  const [bonuses,setBonuses] = props.bonuses;
  const [valueSign, setValueSign] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formValues, setFormValues] = useState(typeof coupon === 'object' ? coupon : {
    name: '',
    reward_type: 'currency_off',
    description: '',
    expires: false,
    days_available: 7,
    minimum: false,
    minimum_requirement: 0,
    value: 0,
    usage_limit: true,
    usage_limit_value: 1,
    shipping_threshold: false,
    shipping_threshold_value: 0,
    items: {
      restriction_type: 'all_orders',
      ids: []
    }
  });

  useEffect(() => {
    // Show extra fields unless Free Shipping type
    switch (formValues.reward_type) {
      case "currency_off":
        setValueSign("$");
        break;
      case "percent_off":
        setValueSign("%");
        break;
      case "free_shipping":
        setValueSign(false);
        break;
      default: 
        setValueSign("$");
    }

  }, [formValues.reward_type])

  const validate = async () => {
    let errs = [];
    let nameRe = /[\w\d]+/g;
    if (!nameRe.exec(formValues.name)) {
      errs.push({field: 'name', message: 'Name must contain numbers or letters.'});
    }
    if (formValues.reward_type !== 'free_shipping' && formValues.value <= 0) {
      errs.push({field: 'value', message: 'Value must be greater than 0.'});
    }
    if (formValues.items.restriction_type !== 'all_orders' && formValues.items.ids.length === 0) {
      errs.push({
        field: 'restriction_type', 
        message: `You must select at least one ${formValues.items.restriction_type === 'collections' ? 'collection' : 'product'}.`
      });
    }
    if (formValues.usage_limit && (formValues.usage_limit_value <= 0 || !parseInt(formValues.usage_limit_value))) {
      errs.push({field: 'usage_limit_value', message: 'Must be a positive whole number.'});
    }
    if (formValues.expires && (formValues.days_available <= 0 || !parseInt(formValues.days_available))) {
      errs.push({field: 'expires', message: 'Must be a positive whole number.'});
    }
    if (formValues.minimum && (formValues.minimum_requirement <= 0 || !parseFloat(formValues.minimum_requirement))) {
      errs.push({field: 'minimum', message: 'Must be a positive number.'});
    }
    if (formValues.shipping_threshold && (formValues.shipping_threshold_value <= 0 || !parseFloat(formValues.shipping_threshold_value))) {
      errs.push({field: 'shipping_threshold', message: 'Must be a positive number.'});
    }
    // console.log(nameRe.exec(formValues.name));
    return errs;
  }

  const submit = async () => {
    // validate formValues
    let errs = await validate();
    // set all buttons to disabled
    if (errs.length === 0) {
      if (typeof coupon === 'object') {
        const bonusIndex = bonuses.findIndex(bon => bon._id === coupon._id);
        let newBonuses = [...bonuses];
        newBonuses[bonusIndex] = formValues;
        setBonuses(newBonuses);
      } else {
        setBonuses([...bonuses, formValues]);
      }
      setRewardForm(false);
    } else {
      console.log(errs);
      setErrors(errs);
    }
  }

  return (
    <section className="redemption-form-container">
      {searchPreview && <div className="redemption-form-searching-overlay"></div>}
      <div className="redemption-form">
        <div className="redemption-form__heading">
          <h3 className='redemption-form__title'>{formValues.name ? formValues.name : 'Edit Reward'}</h3>
        </div>
        <div className="redemption-form__fields">

          <div className="redemption-form__field">
            <label htmlFor="name">Name</label>
            <div className="redemption-form__input-container">
              <input className="redemption-form__input" name="name" value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value })}/>
            </div>
            {errors && errors.filter(e => e.field === 'name').length > 0 && 
              <div className="redemption-form__error">{errors.filter(e => e.field === 'name')[0].message}</div>
            }
          </div>
          <div className="redemption-form__field">
            <label htmlFor="type">Type</label>
            <div className="redemption-form__input-container">
              <select name="reward_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.reward_type} onChange={(e) => setFormValues({...formValues, reward_type: e.target.value, items: { restriction_type: "all_orders", ids: []}})}>
                <option value="currency_off">Amount off</option>
                <option value="percent_off">Percent off</option>
                <option value="free_shipping">Free Shipping</option>
              </select>
            </div>
          </div>
          {valueSign && 
            <div className="redemption-form__extra-fields">
              <div className="redemption-form__field">
                <label htmlFor="value">Value</label>
                <div className="redemption-form__input-container">
                  <div className="redemption-form__input-identifier redemption-form__input-identifier--value">{valueSign}</div>
                  <input className="redemption-form__input redemption-form__input--value" name="value" type="number" value={formValues.value} onChange={(e) => setFormValues({...formValues, value: parseFloat(e.target.value)})}/>
                </div>
                {errors && errors.filter(e => e.field === 'value').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'value')[0].message}</div>
                }
              </div>
              {/* <FormError className="vip-form__error-message" inputName="value" /> */}
            </div>
          }
          {formValues.reward_type !== 'free_shipping' &&
            <div className="redemption-form__field">
              <label htmlFor="restriction_type">Applies to</label>
              <div className="redemption-form__input-container">
                <select name="restriction_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.items.restriction_type} onChange={(e) => setFormValues({...formValues, items: { restriction_type: e.target.value, ids: []}})}>
                  <option value="all_orders">All orders</option>
                  <option value="collections">Specific collections</option>
                  <option value="products">Specific products</option>
                </select>
              </div>
              {errors && errors.filter(e => e.field === 'restriction_type').length > 0 && 
                <div className="redemption-form__error">{errors.filter(e => e.field === 'restriction_type')[0].message}</div>
              }
            </div>
          }
          {formValues.items.restriction_type !== 'all_orders' && 
            <div className="extra-fields">
              <div className="vip-form__add-bonus" onClick={() => setSearchPreview(true)}>+ {formValues.items.restriction_type === 'collections' ? "Collection" : "Products"}</div>
              <SimplePicksDisplay formValues={formValues} setFormValues={setFormValues}/>
            </div>
          }
          {formValues.reward_type === 'free_shipping' &&
            <>
              <div className="redemption-form__field redemption-form__field--left">
                <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="shipping_threshold" value={formValues.shipping_threshold} checked={formValues.shipping_threshold} onChange={(e) => setFormValues({...formValues, shipping_threshold: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="shipping_threshold">Max shipping value</label>
              </div>
              {formValues.shipping_threshold && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="shipping_threshold_value">Up to</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                      <input className="redemption-form__input redemption-form__input--value" name="shipping_threshold_value" type="number" value={formValues.shipping_threshold_value} onChange={(e) => setFormValues({...formValues, shipping_threshold_value: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'shipping_threshold').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'shipping_threshold')[0].message}</div>
                    }
                  </div>
                  <div className="error-message">
                  </div>
                </div>
              }
            </>
          }
          <div className="redemption-form__field redemption-form__field--left">
            <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="usage_limit" value={formValues.usage_limit} checked={formValues.usage_limit} onChange={(e) => setFormValues({...formValues, usage_limit: e.target.checked})}/>
            <label className="redemption-form__label--small" htmlFor="usage_limit">Usage limit</label>
          </div>
          {formValues.usage_limit && 
            <div className="extra-fields">
              <div className="redemption-form__field">
                <label className='redemption-form__sub-label' htmlFor="usage_limit_value">Can be used</label>
                <div className="redemption-form__input-container">
                  <input className="redemption-form__input redemption-form__input--points" name="usage_limit_value" type="number" value={formValues.usage_limit_value} onChange={(e) => setFormValues({...formValues, usage_limit_value: parseInt(e.target.value)})} />
                  <div className="redemption-form__input-identifier">times</div>
                </div>
                {errors && errors.filter(e => e.field === 'usage_limit_value').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'usage_limit_value')[0].message}</div>
                }
              </div>
            </div>
          }
          <div className="redemption-form__field redemption-form__field--left">
            <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="expires" value={formValues.expires} checked={formValues.expires} onChange={(e) => setFormValues({...formValues, expires: e.target.checked})}/>
            <label className="redemption-form__label--small" htmlFor="expires">Set expiration</label>
          </div>
          {formValues.expires && 
            <div className="extra-fields">
              <div className="redemption-form__field">
                <label className='redemption-form__sub-label' htmlFor="days_available">Expires after</label>
                <div className="redemption-form__input-container">
                  <input className="redemption-form__input redemption-form__input--points" name="days_available" type="number" value={formValues.days_available} onChange={(e) => setFormValues({...formValues, days_available: parseInt(e.target.value)})}/>
                  <div className="redemption-form__input-identifier">days</div>
                </div>
                {errors && errors.filter(e => e.field === 'expires').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'expires')[0].message}</div>
                }
              </div>
            </div>
          }
          <div className="redemption-form__field redemption-form__field--left">
            <input className="redemption-form__input redemption-form__input--small" type="checkbox" name="minimum" value={formValues.minimum} checked={formValues.minimum} onChange={(e) => setFormValues({...formValues, minimum: e.target.checked })}/>
            <label className="redemption-form__label--small" htmlFor="minimum">Minimum order value</label>
          </div>
          {formValues.minimum &&
            <div className="extra-fields">
              <div className="redemption-form__field">
                <label className='redemption-form__sub-label' htmlFor="minimum_value">Subtotal at least</label>
                <div className="redemption-form__input-container">
                  <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                  <input className="redemption-form__input redemption-form__input--value" name="minimum_requirement" type="number" value={formValues.minimum_requirement} onChange={(e) => setFormValues({...formValues, minimum_requirement: parseFloat(e.target.value)})}/>
                </div>
                {errors && errors.filter(e => e.field === 'minimum').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'minimum')[0].message}</div>
                }
              </div>
            </div>
          }
        </div>
        <div className="redemption-form__field redemption-form__button-container">
          <button className="redemption-form__cancel-button btn" type="button" onClick={() => {setCreatingCoupon(false)}}>Cancel</button>
          <button className="redemption-form__submit-button btn gradient" type="submit" onClick={submit}>Save</button>
        </div>
        {searchPreview && 
          <SimpleSearchPreview 
            close={setSearchPreview}
            heading={formValues.items.restriction_type === 'collections' ? 'Add collections' : 'Add products'}
            formValues={[formValues,setFormValues]}
            endpoint={formValues.items.restriction_type === 'collections' ? 'collections' : 'products'}
          />
        }
      </div>
    </section>
  )
}

const AddBonusRewardForm = (props) => {
  const {rewardForm, setRewardForm} = props;
  const [formValues, setFormValues] = props.formValues;
  const [bonuses, setBonuses] = props.bonuses;
  const [expires, setExpires] = useState(false); // Determines to show form info
  const [minimum, setMinimum] = useState(false); // Determines to show form info
  const [usageLimit, setUsageLimit] = useState(false);
  const [valueSign, setValueSign] = useState(false);
  const [restriction, setRestriction] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [collectionIds, setCollectionIds] = useState([]);
  const [validation, setValidation] = useState(Yup.object({
    value: Yup.number()
      .positive('Must be a positive number')
      .min(1, 'Must be at least 1')
      .required('Required'),
    usage_limit_value: Yup.number()
      .positive('Must be a positive number')
      .min(1, 'Must be at least 1'),
    days_available: Yup.number()
      .positive('Must be a positive number')
      .min(1, 'Must be at least 1'),
    minimum_value: Yup.number()
      .positive('Must be a positive number')
      .min(0.01, 'Must be at least 0.01'),
  }));

  const DynamicFields = (props) => {
    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      // may need to set validation schema here based on dynamic fields being checked
      // if no usage limit or expiration date, check if coupon of same value already exists in this or other tiers
      if (values.reward_type === 'free_shipping') {
        setFieldValue('value', 1);
      }
      if (values.usage_limit === false) {
        setFieldValue('usage_limit_value', 1);
      }
      if (values.expires === false) {
        setFieldValue('days_available', 7);
      }
      if (values.minimum === false) {
        setFieldValue('minimum_value', 1);
      }
      // Show extra fields unless Free Shipping type
      switch (values.reward_type) {
        case "currency_off":
          setValueSign("$");
          break;
        case "percent_off":
          setValueSign("%");
          break;
        case "free_shipping":
          setValueSign(false);
          break;
        default: 
          setValueSign("$");
      }
      switch (values.restriction_type) {
        case "all_orders":
          setRestriction(false);
          break;
        case "collections":
          setRestriction('collections');
          break;
        case "products":
          setRestriction('products');
          break;
        default: 
          setRestriction(false);
      }
      // Optional Fields
      setExpires(values.expires);
      setMinimum(values.minimum);
      setUsageLimit(values.usage_limit);
    }, [values]);
    return null;
  }

  return (
    <div className="popup-overlay popup-overlay--top">
      <section className="redemption-form-container bonus-reward-form-container">
        <Formik
          initialValues={rewardForm === true ? {
            name: 'Bonus Reward',
            type: "currency_off",
            value: 0,
            expires: false,
            days_available: 7,
            minimum: false,
            minimum_value: "",
            usage_limit: true,
            usage_limit_value: 1,
            restriction_type: 'all_orders',
            product_restriction: false,
            product_restriction_ids: [],
            collection_restriction: false,
            collection_restriction_ids: [],
            search: ""
          } : rewardForm}
          validationSchema={validation}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (bonuses.length > 0) {
              if (rewardForm.i !== undefined) {
                let newBonuses = [...bonuses];
                newBonuses[rewardForm.i] = values;
                setBonuses(newBonuses);
              } else {
                setBonuses([...bonuses, values]);
              }
            } else {
              setBonuses([values])
            }
            setRewardForm(false);
          }}
        >
          <Form className="redemption-form bonus-reward-form">
            <div className="redemption-form__heading">
              <h3 className='redemption-form__title'>Bonus Reward</h3>
              <div className="redemption-form__editing">
                {/* {isEditing && <div className="redemption-form__button"><i className="fa-solid fa-trash redemption-form__delete" onClick={() => deleteRedemption()}></i></div>} */}
              </div>
            </div>
            <div className="redemption-form__field">
              <label htmlFor="name">Name</label>
              <div className="redemption-form__input-container">
                <Field className="redemption-form__input" name="name" />
              </div>
            </div>
            <div className="redemption-form__field">
              <label htmlFor="type">Type</label>
              <div className="redemption-form__input-container">
                <Field as="select" name="reward_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type">
                  <option value="currency_off">Amount off</option>
                  <option value="percent_off">Percent off</option>
                  <option value="free_shipping">Free Shipping</option>
                </Field>
              </div>
            </div>
            {valueSign && 
              <div className="redemption-form__extra-fields">
                <div className="redemption-form__field">
                  <label htmlFor="value">Value</label>
                  <div className="redemption-form__input-container">
                    <div className="redemption-form__input-identifier redemption-form__input-identifier--value">{valueSign}</div>
                    <Field className="redemption-form__input redemption-form__input--value" name="value" type="number" />
                  </div>
                </div>
                <FormError className="vip-form__error-message" inputName="value" />
              </div>
            }
            <div className="redemption-form__field">
              <label htmlFor="restriction_type">Applies to</label>
              <div className="redemption-form__input-container">
                <Field as="select" name="restriction_type" className="redemption-form__input redemption-form__input--select redemption-form__input--type">
                  <option value="all_orders">All orders</option>
                  <option value="collections">Specific collections</option>
                  <option value="products">Specific products</option>
                </Field>
              </div>
            </div>
            {restriction && 
              <div className="extra-fields">
                <div className="vip-form__add-bonus" onClick={() => setSearchPreview(true)}>+ {restriction === 'collections' ? "Collection" : "Products"}</div>
                <PicksDisplay 
                  picks={restriction === 'collections' ? collectionIds : productIds} 
                  setPicks={restriction === 'collections' ? setCollectionIds : setProductIds}  />
              </div>
            }
            <div className="redemption-form__field redemption-form__field--left">
              <Field className="redemption-form__input redemption-form__input--small" type="checkbox" name="usage_limit" />
              <label className="redemption-form__label--small" htmlFor="usage_limit">Usage limit</label>
            </div>
            {usageLimit && 
              <div className="extra-fields">
                <div className="redemption-form__field">
                  <label className='redemption-form__sub-label' htmlFor="usage_limit_value">Can be used</label>
                  <div className="redemption-form__input-container">
                    <Field className="redemption-form__input redemption-form__input--points" name="usage_limit_value" type="number" />
                    <div className="redemption-form__input-identifier">times</div>
                  </div>
                </div>
                <FormError className="vip-form__error-message" inputName="usage_limit_value" />
              </div>
            }
            <div className="redemption-form__field redemption-form__field--left">
              <Field className="redemption-form__input redemption-form__input--small" type="checkbox" name="expires" />
              <label className="redemption-form__label--small" htmlFor="expires">Set Expiration</label>
            </div>
            {expires && 
              <div className="extra-fields">
                <div className="redemption-form__field">
                  <label className='redemption-form__sub-label' htmlFor="days_available">Expires after</label>
                  <div className="redemption-form__input-container">
                    <Field className="redemption-form__input redemption-form__input--points" name="days_available" type="number" />
                    <div className="redemption-form__input-identifier">days</div>
                  </div>
                </div>
                <FormError className="vip-form__error-message" inputName="days_available" />
              </div>
            }
            <div className="redemption-form__field redemption-form__field--left">
              <Field className="redemption-form__input redemption-form__input--small" type="checkbox" name="minimum" />
              <label className="redemption-form__label--small" htmlFor="minimum">Minimum order value</label>
            </div>
            {minimum &&
              <div className="extra-fields">
                <div className="redemption-form__field">
                  <label className='redemption-form__sub-label' htmlFor="minimum_value">Subtotal at least</label>
                  <div className="redemption-form__input-container">
                    <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                    <Field className="redemption-form__input redemption-form__input--value" name="minimum_value" type="number" />
                  </div>
                </div>
                <div className="error-message">
                  <FormError className="vip-form__error-message" inputName="minimum_value" />
                </div>
              </div>
            }
            <div className="redemption-form__field redemption-form__button-container">
              <button className="redemption-form__cancel-button btn" type="button" onClick={() => {setRewardForm(false);}}>Cancel</button>
              <button className="redemption-form__submit-button btn gradient" type="submit">Save</button>
            </div>
            <DynamicFields />
          </Form>
        </Formik>
        {searchPreview && 
          <SearchPreview 
            close={setSearchPreview}
            heading={restriction === 'collections' ? 'Add collections' : 'Add products'}
            picks={restriction === 'collections' ? [collectionIds, setCollectionIds] : [productIds, setProductIds]}
            endpoint={restriction === 'collections' ? 'collections' : 'products'}
          />
        }
      </section>
    </div>
  )
}

