import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useParams } from "react-router-dom";
import { Klaviyo, Shopify } from '../components/IntegrationsComponents';


export default function UpdateIntegration() {
  const {logout} = useContext(AuthContext);
  const {id} = useParams();
  const [integration, setIntegration] = useState(false);
  const [ret, setRet] = useState(false);
  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/integrations',
      {
        params: {
          jwt: localStorage.getItem('_id'),
          integration: id
        }
      }
    ).then(res => {
      console.log(res);
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        setIntegration(res.data);
        if (res.headers['x-amplify-ret']) setRet(JSON.parse(res.headers['x-amplify-ret']));
      }
    }).catch(err => {
      console.log(err);
    })
    

  }, [id]);

  return (
    <div className="update-integration">
      {id === 'shopify' && <Shopify integration={integration} setIntegration={setIntegration} ret={ret} />}
      {id === 'klaviyo' && <Klaviyo integration={integration} setIntegration={setIntegration} />}
    </div>
  )
}