import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import '../assets/css/components/program.css';
import { EarningsFormContainer, EarningFormField, EarningsOptions, OldEarningsTable, OnChange } from '../components/EarningsComponents';
import { RedemptionsTable, NewRedemptionForm } from '../components/RedemptionsComponents';
import * as Yup from 'yup';

export default function Program() {
  const {logout} = useContext(AuthContext);
  const [program, setProgram] = useState(false);
  const [integrated, setIntegrated] = useState(true);
  // Earnings
  const [option, setOption] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [earningsErrors, setEarningsErrors] = useState(false);
  // Redemptions
  const [redemption, setRedemption] = useState(false);
  const [paid, setPaid] = useState(true); // Determines state of table
  const [couponType, setCouponType] = useState(false); // Determines state of form
  const [formValues, setFormValues] = useState(false); // initial values of Redemption form
  const urlRegExp = /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*)$/;

  useEffect(() => { 
    // let creationButtons = [document.querySelector('.redemptions-options__create'), document.querySelector('.earnings-options__add-more')];
    // creationButtons.forEach(btn => btn.disabled = true);
    axios.get('https://api.amplifyloyalty.com/app/stores',
    {
      params: {
        jwt: localStorage.getItem('_id'),
        events: 'Reward Redeemed'
      }
    }).then(async res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        const isIntegrated = res.headers['x-integrated'];
        if (isIntegrated == 'false') setIntegrated(false);
        setProgram(res.data);
      }
    }).catch(err => {
      console.log(err.response);
    }).finally(() => {
      // creationButtons.forEach(btn => btn.disabled = false);
    })
  },[]);

  
  
  const getEmailLists = async (v) => {
    try {
      let res = await axios.get('https://api.amplifyloyalty.com/app/stores/integrations/klaviyo',
        {
          params: {
            jwt: localStorage.getItem('_id')
          }
        }
      )
      if (res.data.name === 'BadRequestError') {
        logout();
      } else {
        console.log({'RES': res});
        return res;
      }
    } catch (err) {
      throw new Error(err);
    }
        
  }
  
  const validateIntegration = (e) => {
    if (program[e.target.value] !== 'active') {
      return false;
    } else {
      return true;
    }
  }
  
  return (
    <div className="main">
      <OldEarningsTable program={[program, setProgram]} option={[option, setOption]} setIsAdding={setIsAdding} setIsEditing={setIsEditing} />
      {isAdding && 
        <EarningsOptions 
          program={program} 
          setIsAdding={setIsAdding} 
          setOption={setOption} 
        />
      }
      {option && ['purchase', 'account_creation', 'referral'].includes(option.key) &&
        <EarningsFormContainer
          program={[program, setProgram]} 
          option={[option, setOption]} 
          setIsAdding={setIsAdding} 
          editing={[isEditing, setIsEditing]}
          errors={[earningsErrors, setEarningsErrors]}
          schema={{
            points: Yup.number('Must be a number.').integer('Must be a whole number.').required('Field is required.').moreThan(0, 'Must be greater than 0.')
          }}
        />
      }
      {option && option.key === 'birthday' &&
        <EarningsFormContainer 
          program={[program, setProgram]} 
          option={[option, setOption]} 
          setIsAdding={setIsAdding} 
          editing={[isEditing, setIsEditing]}
          errors={[earningsErrors, setEarningsErrors]}
          schema={{
            points: Yup.number('Must be a number.').integer('Must be a whole number.').required('Field is required.').moreThan(0, 'Must be greater than 0.'),
            days_delayed: Yup.number('Must be a valid URL.').integer('Must be a whole number.').required('Field is required.').moreThan(-1, 'Must be greater than 0.'),
          }}
        >
          <EarningFormField 
            type="number" 
            name="days_delayed"
            label="Reward restricted"
            identifier="days"
            inputClass="earnings-form__input--number"
            tooltip={{
              message: "If 10 is entered, the customer's birthday must be at least 10 days later than when they entered it. If 0 is entered, the customer can receive their points that day.",
              width: "225px"
            }}
          />
        </EarningsFormContainer>
      }
      {option && ['facebook','instagram','twitter'].includes(option.key) &&
        <EarningsFormContainer 
          program={[program, setProgram]} 
          option={[option, setOption]} 
          setIsAdding={setIsAdding} 
          editing={[isEditing, setIsEditing]}
          errors={[earningsErrors, setEarningsErrors]}
          schema={{
            points: Yup.number('Must be a number.').integer('Must be a whole number.').required('Field is required.').moreThan(0, 'Must be greater than 0.'),
            link: Yup.string().matches(urlRegExp, 'Must be a valid URL.').required('Field is required.')
          }}
        >
          <EarningFormField 
            type="text" 
            name="link"
            label="Link to profile"
          />
        </EarningsFormContainer>
      }
      {option && ['email','texts'].includes(option.key) &&
        <EarningsFormContainer 
          program={[program, setProgram]} 
          option={[option, setOption]} 
          setIsAdding={setIsAdding} 
          editing={[isEditing, setIsEditing]}
          errors={[earningsErrors, setEarningsErrors]}
          schema={{
            points: Yup.number('Must be a number.').integer('Must be a whole number.').required('Field is required.').moreThan(0, 'Must be greater than 0.'),
            integration: Yup.string().required('Field is required.'),
            list_id: Yup.string().required('Field is required.')
          }}
          >
          <EarningFormField 
            type="select" 
            name="integration"
            label="Integration"
            options={program.integrations[option.key]}
            tooltip={{
              message: 'Before choosing an integration, be sure to enable it in the Integrations tab.',
              width: "125px"
            }}
          />
          <EarningFormField 
            type="select" 
            name="list_id"
            label="List"
            options={option.lists} 
          />
          <OnChange 
            program={program} 
            setEarningsErrors={setEarningsErrors} 
            getEmailLists={getEmailLists}
            setOption={setOption}
            option={option}
          />
        </EarningsFormContainer>
      }
      <RedemptionsTable 
        program={[program, setProgram]} 
        setRedemption={setRedemption} 
        setIsEditing={setIsEditing} 
        paid={[paid, setPaid]} 
        couponType={[couponType, setCouponType]}
        setFormValues={setFormValues}
      />
      {redemption &&
        <NewRedemptionForm 
          program={program}
          setProgram={setProgram}
          coupon={redemption}
          setCreatingCoupon={setRedemption}
          notIntegrated={!integrated}
        />
      }
    </div>
  )
}