import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import { Loading } from '../../components/utility/Utility';
import '../../assets/css/components/program.css';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { responseHandler } from '../../components/utility/Utility';
import { GiveawaysHeader, GiveawaysInfo, GiveawaysRewards, GiveawaysReview } from '../../components/GiveawaysComponents';

export default function UpdateGiveaway() {
  const {logout} = useContext(AuthContext);
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [giveaway, setGiveaway] = useState(false);
  const [dynamicName, setDynamicName] = useState('');
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    axios.get('https://api.amplifyloyalty.com/app/stores/giveaways',
    {
      params: {
        jwt: localStorage.getItem('_id'),
        id: id
      }
    }).then(res => {
      if (res.data.name === 'BadRequestError') {
        logout();
      } else if (res.data.status === 'executed') {
        navigate(`${location.pathname}/reporting`);
      } else {
        setGiveaway(res.data);
        setDynamicName(res.data.name);
      }
    }).catch(err => {
      console.log(err);
    });
  }, [])

  return (
    <div className="main">
      <GiveawaysHeader dynamicName={dynamicName} />
      {(!location.pathname.includes('/rewards') && !location.pathname.includes('/review') && giveaway) &&
        <GiveawaysInfo giveaway={giveaway} setGiveaway={setGiveaway} dynamicName={dynamicName} setDynamicName={setDynamicName} />
      }
      {(location.pathname.includes('/rewards') && giveaway) && 
        <GiveawaysRewards giveaway={giveaway} setGiveaway={setGiveaway} />
      }
      {(location.pathname.includes('/review') && giveaway) &&
        <GiveawaysReview giveaway={giveaway} />
      }
    </div>
  )
}